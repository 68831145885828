import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../context/Auth";
import "../../input.css";
import toast from "react-hot-toast";
import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ServiceForm({ serviceData, setServiceData, getServices, closeModal }) {
  const navigate=useNavigate();
  const { authTokens, logoutUser , vendorData} = useContext(AuthContext);
  const [serviceName, setServiceName] = useState(
    serviceData?.service_name || ""
  );
  const [serviceDescription, setServiceDescription] = useState(
    serviceData?.description || ""
  );
  const [serviceType, setServiceType] = useState(serviceData?.categories || "");
  const [serviceDuration, setServiceDuration] = useState(
    serviceData?.service_time || ""
  );
  const [servicePrice, setServicePrice] = useState(serviceData?.price || "");
  const [serviceDiscount, setServiceDiscount] = useState(
    serviceData?.discount || ""
  );
  const [servicesalon, setServicesalon] = useState(serviceData?.salon || "");
  const [gender,setgender]=useState(serviceData?.gender || "")

  const [validation, setValidation] = useState({
    name: false,
    description: false,
    type: false,
    duration: false,
    price: false,
    discount: false,
    gender:false
  });

  const [category, setcategory] = useState([]);

  useEffect(() => {
    fetch("https://trakky.in:8000/salonvendor/category/",{
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.access_token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setcategory(data);
        }
      });
  }, []);
 
  const handlePriceChange = (price) => {
    setServicePrice(price);
    // Clear the Discounted Price if it's greater than or equal to the Price
    if (serviceDiscount !== "" && Number(serviceDiscount) >= Number(price)) {
      setServiceDiscount("");
    }
  };

  const isPriceValid = servicePrice !== null && servicePrice !== "";

  const handleSubmit = async (event) => {
    event.preventDefault();

    const hasError = Object.values(validation).some((item) => item === true);
    if (hasError) {
      toast.error("Please fill all the required fields correctly");
      return;
    }
    let data = {
      salon: servicesalon,
      service_name: serviceName,
      description: serviceDescription,
      categories: parseInt(serviceType),
      service_time: serviceDuration,
      price: servicePrice,
      discount: serviceDiscount,
    };

    if (serviceData) {
      try {
        await fetch(
          `https://trakky.in:8000/salonvendor/service/${serviceData.id}/`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + String(authTokens.access_token),
            },
            body: JSON.stringify(data),
          }
        ).then((result) => {
          result.json().then((resp) => {
            if (
              resp.detail === "Authentication credentials were not provided."
            ) {
              toast.error("You're logged out");
              logoutUser();
            } else {
              setServiceData(resp);
              getServices();
              toast.success("Service Updated Successfully");
              closeModal();
            }
          });
        });
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else {
      try {
        await fetch(`https://trakky.in:8000/salonvendor/service/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(authTokens.access_token),
          },
          body: JSON.stringify(data),
        }).then((result) => {
          result.json().then((resp) => {
            if (
              resp.detail === "Authentication credentials were not provided."
            ) {
              toast.error("You're logged out");
              logoutUser();
            } else {
              toast.success("Service Added Successfully");
              navigate("/salon/servicemanagement/servicetable")
            }
          });
        });
      } catch (error) {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <form className="ms-register-form mt-0" onSubmit={handleSubmit}>
        
        <div className="appointment-fillup">
          <div className="appoint-input-field address-field">
            <input
              placeholder="Service Name"
              required
              style={{
                border: validation.name ? "1.5px solid red" : "",
              }}
              onBlur={() => {
                if (serviceName === "") {
                  setValidation({ ...validation, name: true });
                } else {
                  setValidation({ ...validation, name: false });
                }
              }}
              type="text"
              value={serviceName}
              onChange={(e) => {
                setServiceName(e.target.value);
              }}
            />
            {validation.name && (
              <Tooltip title="Service Name is required" placement="top">
                <ErrorIcon
                  className="error-icon absolute right-[5px] bottom-[10px] hidden"
                  color="error"
                />
              </Tooltip>
            )}
          </div>
        </div>
        <div className="appoint-input-field" style={{marginTop:"30px"}} >
              <Tooltip title="Gender" placement="top" arrow>
              <select 
                required
                value={gender}
                onBlur={() => {
                  if (gender === ""){
                    setValidation((prev)=>{
                      return {
                        ...prev,
                        gender:true
                      }
                  })
                  }else{
                    setValidation((prev)=>{
                      return {
                        ...prev,
                        gender:false
                      }
                  })
                  }
                }
                }
                onChange={(e) => {
                  setgender(e.target.value);
                }}
                style={
                  {
                    border: validation.gender ? "1.5px solid red" : "",
                    color: gender === "" ? "#9CA6BE" : "black",
                  }
                } 
              >
                <option value="" selected disabled hidden>
                  Select Gender
                </option>
               
                    <option  value={"Male"}>
                    Male
                    </option>
                    <option  value={"Female"}>
                    Female
                    </option>
               
              </select></Tooltip>
            </div>
        <div className="appoint-input-field address-field mb-[25px]" style={{ height: "200px" }}>
          <textarea
            type="text"
            required
            style={{
              width: "100%",
              height: "100%",
              padding: "10px",
              resize: "none",
              border: validation.description ? "1.5px solid red" : "",
            }}
            placeholder="Description"
            onBlur={() => {
              if (serviceDescription === "") {
                setValidation({ ...validation, description: true });
              } else {
                setValidation({ ...validation, description: false });
              }
            }}
            value={serviceDescription}
            onChange={(e) => {
              setServiceDescription(e.target.value);
            }}
          ></textarea>
          {validation.description && (
            <Tooltip title="Description is required" placement="top">
              <ErrorIcon
                className="error-icon absolute right-[5px] bottom-[10px] hidden"
                color="error"
              />
            </Tooltip>
          )}
        </div>
        <div className="appointment-fillup">
          <div className="left-col1">
            <div className="appoint-input-field">
              <select
                value={serviceType || "payment-mode"}
                style={{
                  border: validation.type ? "1.5px solid red" : "",
                }}
                onBlur={() => {
                  if (serviceType === "") {
                    setValidation({ ...validation, type: true });
                  } else {
                    setValidation({ ...validation, type: false });
                  }
                }}
                onChange={(e) => setServiceType(e.target.value)}
                required
              >
                <option value="payment-mode" disabled>
                  Service Type / Category
                </option>
                {category.map((category, index) => {
                  return (
                    <option value={category.id} key={index}>
                      {category.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="appoint-input-field">
              <input
                type="number"
                placeholder="Price"
                required
                style={{
                  border: validation.price ? "1.5px solid red" : "",
                }}
                onBlur={() => {
                  if (servicePrice === "") {
                    setValidation({ ...validation, price: true });
                  } else {
                    setValidation({ ...validation, price: false });
                  }
                }}
                value={servicePrice}
                onChange={(e) => handlePriceChange(e.target.value)}
              ></input>
              {validation.price && (
                <Tooltip title="Price is required" placement="top">
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px] hidden"
                    color="error"
                  />
                </Tooltip>
              )}
            </div>
          </div>
          <div className="right-col2">
            <div className="appoint-input-field">
              <select
                value={serviceDuration || "duration"}
                style={{
                  border: validation.duration ? "1.5px solid red" : "",
                }}
                onBlur={() => {
                  if (serviceDuration === "") {
                    setValidation({ ...validation, duration: true });
                  } else {
                    setValidation({ ...validation, duration: false });
                  }
                }}
                onChange={(e) => setServiceDuration(e.target.value)}
                required
              >
                <option value="duration" disabled>
                  Duration
                </option>
                <option value="30 min">30 min</option>
                <option value="45 min">45 min</option>
                <option value="60 min">60 min</option>
                <option value="90 min">90 min</option>
                <option value="120 min">120 min</option>
              </select>
            </div>
            <div className="appoint-input-field">
              <input
                type="number"
                placeholder="Discounted Price"
                style={{
                  border: validation.discount ? "1.5px solid red" : "",
                }}
                onBlur={() => {
                  if (serviceDiscount !== "" && Number(serviceDiscount) >= Number(servicePrice)) {
                    setValidation({ ...validation, discount: true });
                  } else {
                    setValidation({ ...validation, discount: false });
                  }
                }}
                value={serviceDiscount}
                onChange={(e) => setServiceDiscount(e.target.value)}
                disabled={!isPriceValid}
              ></input>
              {validation.discount && (
                <Tooltip title="Discount should be less than Price" placement="top">
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px] hidden"
                    color="error"
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <div className="appointment-form-btn">
          <button type="submit">
            {serviceData ? "Update Service" : "Add Service"}
          </button>
        </div>
      </form>
    </>
  );
}

export default ServiceForm;
