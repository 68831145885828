import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import AuthContext from "../../context/Auth";
import toast from "react-hot-toast";
import "../css/customer.css";
import ErrorIcon from "@mui/icons-material/Error";

const AddCustomer = (props) => {
  var customerData = props?.customerData;
  const setCustomerData = props.setCustomerData;

  const { authTokens } = useContext(AuthContext);
  const [chairsData, setChairsData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const date =
    props?.date ||
    customerData?.date ||
    new Date()
      .toLocaleString("en-CA", { timeZone: "Asia/Kolkata" })
      .slice(0, 10);
  const [customer, setCustomer] = useState(customerData?.customer_name || "");
  const [contactNumber, setContactNumber] = useState(
    customerData?.customer_phone || ""
  );
  const [service, setService] = useState(customerData?.therapy || "");
  const [duration, setDuration] = useState(customerData?.duration || "");
  const [timeIn, setTimeIn] = useState(customerData?.time_in || "");
  const [paymentMode, setPaymentMode] = useState(
    customerData?.payment_mode || ""
  );
  const [chair, setChair] = useState(customerData?.chair || "");
  const [membership, setMembership] = useState(customerData?.membership || "");
  const [staff, setStaff] = useState(customerData?.staff || "");
  const [amountPaid, setAmountPaid] = useState(customerData?.amount_paid || "");

  const [field_type, setType] = useState("text");

  useEffect(() => {
    fetch("https://trakky.in:8000/salonvendor/chairs/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.access_token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data) {
          setChairsData(data);
        }
      });
  }, [authTokens, customerData]);

  useEffect(() => {
    fetch("https://trakky.in:8000/salonvendor/staff/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.access_token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data) {
          setStaffData(data);
        }
      });
  }, [authTokens, customerData]);

  useEffect(() => {
    fetch("https://trakky.in:8000/salonvendor/service/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.access_token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data) {
          setServiceData(data);
        }
      });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (contactNumber.length !== 10) {
      alert("Contact number should be of 10 digits");
      return;
    }

    let data = {
      date: date,
      customer_name: customer,
      customer_phone: contactNumber,
      service: service,
      duration: duration,
      time_in: timeIn,
      payment_mode: paymentMode,
      chair: chair,

      staff: staff,
      amount_paid: amountPaid,
    };
    if (membership) {
      data.membership = membership;
    }

    try {
      const response = await fetch(
        `https://trakky.in:8000/salonvendor/customerappointment/${
          customerData?.id ?? ""
        }${customerData ? "/" : ""}`,
        {
          method: !customerData ? "POST" : "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      if (result) {
        if (customerData) {
          setCustomerData(result);
        } else {
          toast.success("Customer Added Successfully");
          // Reload the Page
          // window.location.reload();
        }
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="customer-page-container bg-[#f0ecff]">
      <form className="Customer-page-form" onSubmit={handleSubmit}>
        <div className="customer-form-left">
          <div className="customer-fillup">
            <div className="left-col1">
              <div className="cust-input-field">
                <input
                  type="text"
                  placeholder="Customer Name"
                  value={customer}
                  required
                  onChange={(e) => {
                    setCustomer(e.target.value);
                  }}
                />
              </div>
              <div className="cust-input-field">
                <input
                  type="tel"
                  placeholder="Customer Number"
                  value={contactNumber}
                  required
                  onChange={(e) => {
                    setContactNumber((val) => {
                      if (e.target.value.length > 10) {
                        return val;
                      } else if (e.target.value.length < 10) {
                        return e.target.value;
                      } else {
                        return e.target.value;
                      }
                    });
                  }}
                />
                {contactNumber.length !== 10 && contactNumber.length !== 0 && (
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px] hidden"
                    color="error"
                  />
                )}
              </div>
              <div className="cust-input-field">
                <select
                  required
                  // value={service || "Service-Name"}
                  onChange={(e) => setService(e.target.value)}
                >
                  <option value="" selected disabled hidden>
                    Service Name
                  </option>
                  {serviceData?.map((service, index) => {
                    return (
                      <option value={service.id} key={index}>
                        {service.service_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="cust-input-field">
                <select
                  // value={duration || "Select-duration"}
                  onChange={(e) => setDuration(e.target.value)}
                  required
                >
                  <option value="" selected disabled hidden>
                    Select Duration
                  </option>
                  <option value="00:30:00">30 min</option>
                  <option value="00:45:00">45 min</option>
                  <option value="00:60:00">60 min</option>
                  <option value="01:30:00">90 min</option>
                  <option value="02:00:00">120 min</option>
                </select>
              </div>

              <div className="cust-input-field custom  ">
                <select
                  // value={paymentMode || "payment-mode"}
                  onChange={(e) => setPaymentMode(e.target.value)}
                  required
                >
                  <option value="" selected disabled hidden >
                    Payment Mode
                  </option>
                  <option value="UPI">UPI</option>
                  <option value="Net Banking">Net Banking</option>
                  <option value="Debit Card">Debit Card</option>
                  <option value="Credit Card">Credit Card</option>
                  <option value="Cash">Cash</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="right-col2">
              <div className="cust-input-field">
                <input
                  placeholder="Time in"
                  type={field_type}
                  onFocus={() => setType("time")}
                  onBlur={() => setType("text")}
                  id="timeIn"
                  value={timeIn}
                  required
                  onChange={(e) => setTimeIn(e.target.value)}
                />
              </div>

              <div className="cust-input-field">
                <select
                  // value={chair || "Service-Chair"}
                  required
                  onChange={(e) => setChair(e.target.value)}
                  // onChange={(e) => {
                  //   const selectedOptions = Array.from(e.target.selectedOptions);
                  //   setFacilities(selectedOptions.map((option) => option.value));
                  // }}
                >
                  <option value="" selected disabled hidden >
                    Select Chair
                  </option>
                  {chairsData?.map((chair, index) => {
                    return (
                      <option key={index} value={chair.id}>
                        {chair.chairname}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* <div className="cust-input-field">
                <input
                  type="number"
                  placeholder="Membership Number"
                  value={membership}
                  onChange={(e) => setMembership(e.target.value)}
                />
                <div className="cust-inp-icon">
                  <AddIcon />
                </div> 
              </div>*/}
              <div className="cust-input-field">
                <select
                  // value={staff || "staff-Name"}
                  onChange={(e) => setStaff(e.target.value)}
                  required
                >
                  <option value="" selected disabled hidden>
                    Staff Name
                  </option>
                  {staffData?.map((staff, index) => {
                    return (
                      <option key={index} value={staff.id}>
                        {staff.staffname}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="cust-input-field">
                <input
                  type="tel"
                  placeholder="Amount Paid"
                  value={amountPaid}
                  required
                  onChange={(e) => {
                    setAmountPaid(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="customer-form-btn">
            <button type="submit">
              Book Now
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCustomer;
