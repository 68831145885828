import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import AuthContext from "../../context/Auth";
import toast from "react-hot-toast";
import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip, Collapse } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {  useNavigate } from "react-router-dom";

function AppointmentForm(props) {
  const navigate=useNavigate()
  var appointmentData = props?.appointmentData;
  const setAppointmentData = props.setAppointmentData;

  const { authTokens } = useContext(AuthContext);
  const [chairsData, setChairsData] = useState([]);

  const [staffData, setStaffData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [serviceType, setServiceType] = useState(
    appointmentData?.servicetype || ""
  );
  const date =
    props?.date ||
    appointmentData?.date ||
    new Date()
      .toLocaleString("en-CA", { timeZone: "Asia/Kolkata" })
      .slice(0, 10);
  const [customer, setCustomer] = useState(
    appointmentData?.customer_name || ""
  );
  const [contactNumber, setContactNumber] = useState(
    appointmentData?.customer_phone || ""
  );
  const [email, setEmail] = useState(appointmentData?.customer_email || "");
  const [gender, setGender] = useState(appointmentData?.customer_gender || "");
  const [service, setService] = useState(appointmentData?.therapy || "");
  const [duration, setDuration] = useState(appointmentData?.duration || "");
  const [timeIn, setTimeIn] = useState(appointmentData?.time_in || "");
  const [paymentMode, setPaymentMode] = useState(
    appointmentData?.payment_mode || ""
  );
  const [chair, setChair] = useState(appointmentData?.chair || "");
  const [membership, setMembership] = useState(
    appointmentData?.membership || ""
  );
  const [membershipDetails, setMembershipDetails] = useState(null);

  const [staff, setStaff] = useState(appointmentData?.staff || "");
  const [amountPaid, setAmountPaid] = useState(
    appointmentData?.amount_paid || ""
  );

  const [totalAmount, setTotalAmount] = useState(null);
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [managersData, setManagersData] = useState([]);
  const [selectedManager, setSelectedManager] = useState(
    appointmentData?.manager || ""
  );

  const [field_type, setType] = useState("text");
  const [showMembershipDetails, setShowMembershipDetails] = useState(false);
  const [validation, setValidation] = useState({
    customer_name: false,
    customer_phone: false,
    customer_email: false,
    service: false,
    duration: false,
    time_in: false,
    payment_mode: false,
    chair: false,
    staff: false,
    gender: false,
    amount_paid: false,
    manager_name: false,
  });

  const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

  useEffect(() => {
    fetch("https://trakky.in:8000/salonvendor/chairs/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.access_token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data) {
          setChairsData(data);
        }
      });
  }, [authTokens, appointmentData]);

  useEffect(() => {
    fetch("https://trakky.in:8000/salonvendor/staff/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.access_token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data) {
          setStaffData(data);
        }
      });
  }, [authTokens, appointmentData]);

  useEffect(() => {
    fetch("https://trakky.in:8000/salonvendor/service/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.access_token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data) {
          setServiceData(data);
        }
      });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const hasError = Object.values(validation).some((item) => item === true);
    if (hasError) {
      return;
    }

    let data = {
      date: date,
      customer_name: customer,
      customer_email: email,
      customer_gender: gender,
      customer_phone: contactNumber,
      duration: duration,
      time_in: timeIn,
      payment_mode: paymentMode,
      chair: parseInt(chair),
      staff: parseInt(staff),
      manager: parseInt(selectedManager),
      amount_paid: amountPaid,
    };
    if (membershipDetails) {
      data.membership = membershipDetails?.id;
    }
    if (serviceType === "service") {
      data.service = parseInt(service);
    } else if (serviceType === "offer") {
      data.offer = parseInt(service);
    }

    try {
      const response = await fetch(
        `https://trakky.in:8000/salonvendor/appointments/${
          appointmentData?.id ?? ""
        }${appointmentData ? "/" : ""}`,
        {
          method: !appointmentData ? "POST" : "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      if (result) {
        if (appointmentData) {
          setAppointmentData(result);
        } else {
          toast.success("Appointment Booked Successfully");
          setCustomer("");
          setEmail("");
          setGender("");
          setContactNumber("");
          setService("");
          setDuration("");
          setTimeIn("");
          setPaymentMode("");
          setChair("");
          setStaff("");
          setAmountPaid("");
          setMembership("");
          setTotalAmount("");
          setSelectedOffer(null);
          setSelectedManager(""); 
          setMembershipDetails(null);
          navigate("/salon/appointment/appointment_card")


        }
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  // function to get offers
  const getOffers = async () => {
    try {
      const response = await fetch(
        "https://trakky.in:8000/salonvendor/offers/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        setOffers(data);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function to get managers
  const getManagers = async () => {
    try {
      const response = await fetch(
        "https://trakky.in:8000/salonvendor/manager/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setManagersData(data);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function to check wherether customer has membership or not with mobile number
  const checkMembershipWithMobileNo = async (mobileNumber) => {
    if (mobileNumber.length !== 10) {
      return;
    }
    try {
      const response = await fetch(
        `https://trakky.in:8000/salonvendor/customer-membership/?phone=${mobileNumber}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setMembershipDetails(data[0]);
        setCustomer(data[0].customer_name);
        setMembership(data[0].id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function to check wherether customer has membership or not with membership number
  const checkMembershipWithMembershipNo = async (membershipNumber) => {
    if (membershipNumber.length !== 4) {
      return;
    }
    try {
      const response = await fetch(
        `https://trakky.in:8000/salonvendor/customer-membership/?code=${membershipNumber}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        setMembershipDetails(data[0]);
        setCustomer(data[0]?.customer_name);
        setContactNumber(data[0]?.customer_phone);
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  //  duration standardization

  function convertToMinutesFormateOne(duration) {
    const [hours, minutes, seconds] = duration.split(":").map(Number);

    const totalMinutes = hours * 60 + minutes;

    return totalMinutes;
  }
  function convertToMinutesformatetwo(duration) {
    let totalMinutes = 0;

    const parts = duration.split(" ");

    for (let i = 0; i < parts.length; i++) {
      const value = parseInt(parts[i]);

      if (!isNaN(value)) {
        if (parts[i + 1] === "hour" || parts[i + 1] === "hours") {
          totalMinutes += value * 60;
        } else if (
          parts[i + 1] === "min" ||
          parts[i + 1] === "minutes" ||
          parts[i + 1] === "minute"
        ) {
          totalMinutes += value;
        }
      }
    }

    return totalMinutes;
  }

  // function to get total amount of service
  const calculateTotalAmount = () => {
    let totalAmount = 0;

    if (serviceType === "service") {
      let service_item = serviceData?.find(
        (item) => item.id === parseInt(service)
      );
      let durationminut = convertToMinutesFormateOne(duration);
      let durationminut2 = convertToMinutesformatetwo(
        service_item?.service_time
      );

      console.log(durationminut, durationminut2);
      totalAmount =
        parseInt(service_item?.price) * (durationminut / durationminut2);
    } else if (serviceType === "offer") {
      let offer_item = offers?.find((item) => item.id === parseInt(service));
      let durationMin = convertToMinutesFormateOne(duration);
      let durationMin2 = convertToMinutesFormateOne(offer_item?.duration);
      totalAmount = parseInt(offer_item?.price) * (durationMin / durationMin2);
    }

    setTotalAmount(Math.round(totalAmount));
  };

  useEffect(() => {
    if (service && duration) {
      calculateTotalAmount();
    }
  }, [service, duration, serviceType]);



  useEffect(() => {
    getOffers();
    getManagers();
  }, []);

  return (
    <div className="appointment-page-container bg-[#f0ecff]">
      <div className="appointment-room-container">
        {chairsData.length > 0 && (
          <div className="appointment-scroll-room-div">
            <div className="appointment-room-a">
              {chairsData?.map((chair, index) => {
                return (
                  <div className="a-room-item" key={index}>
                    <h4>{chair.chairname}</h4>
                    <p style={!chair.is_occupied ? { color: "#04D000" } : {}}>
                      {chair.is_occupied ? "Busy" : "Free"}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="appointment-room-container">
        {offers.length > 0 && (
          <div className="appointment-scroll-room-div">
            <div className="appointment-room-a">
              {offers?.map((offer, index) => {
                return (
                  offer?.enabled && (
                    <div className="a-room-item" key={index}>
                      <h4>{offer.offername}</h4>
                      <p>{offer.price}</p>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="Appointment-page-form">
        <form className="appointment-form-left" onSubmit={handleSubmit}>
          <div className="appointment-fillup">
            <div className="left-col1">
              <div className="appoint-input-field">
                <input
                  type="text"
                  placeholder="Customer Name"
                  value={customer}
                  required
                  style={{
                    border: `${
                      validation.customer_name ? "1.5px solid red" : ""
                    }`,
                  }}
                  onBlur={(e) => {
                    if (customer === "") {
                      setValidation((val) => {
                        return { ...val, customer_name: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, customer_name: false };
                      });
                    }
                  }}
                  onChange={(e) => {
                    setCustomer(e.target.value);
                  }}
                />
                {validation.customer_name && (
                  <Tooltip title="Customer Name is required">
                    <ErrorIcon
                      className="error-icon absolute right-[5px] bottom-[10px] hidden"
                      color="error"
                    />
                  </Tooltip>
                )}
              </div>
              <div className="appoint-input-field">
                <input
                  type="email"
                  placeholder="Customer Email"
                  style={{
                    border: `${
                      validation.customer_email ? "1.5px solid red" : ""
                    }`,
                  }}
                  onBlur={() => {
                    if (email === "" || !emailRegex.test(email)) {
                      setValidation((val) => {
                        return { ...val, customer_email: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, customer_email: false };
                      });
                    }
                  }}
                  value={email}
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                {validation.customer_email && (
                  <Tooltip
                    title={
                      email === "" ? "Customer email is required" : "Invalid"
                    }
                  >
                    <ErrorIcon
                      className="error-icon absolute right-[5px] bottom-[10px] hidden"
                      color="error"
                    />
                  </Tooltip>
                )}
              </div>
              <div className="appoint-input-field relative">
                <Tooltip title="Customer Number" placement="top" arrow>
                  <input
                    style={{
                      border: `${
                        validation.customer_phone ? "1.5px solid red" : ""
                      }`,
                    }}
                    type="number"
                    required
                    placeholder="Customer Number"
                    value={contactNumber}
                    onBlur={(e) => {
                      if (contactNumber === "" || contactNumber.length < 10) {
                        setValidation((val) => {
                          return { ...val, customer_phone: true };
                        });
                      } else {
                        setValidation((val) => {
                          return { ...val, customer_phone: false };
                        });
                      }
                    }}
                    onChange={(e) => {
                      setContactNumber((val) => {
                        if (e.target.value.length > 10) {
                          return val;
                        } else if (e.target.value.length < 10) {
                          return e.target.value;
                        } else {
                          return e.target.value;
                        }
                      });
                      checkMembershipWithMobileNo(e.target.value);
                    }}
                  />
                </Tooltip>
                {validation.customer_phone && (
                  <Tooltip title="Atleast 10 digits are required">
                    <ErrorIcon
                      className="error-icon absolute right-[5px] bottom-[10px] hidden"
                      color="error"
                    />
                  </Tooltip>
                )}
              </div>
              <div className="appoint-input-field">
                <Tooltip title="Service Name" placement="top" arrow>
                  <select
                    required
                    style={{
                      border: `${validation.service ? "1.5px solid red" : ""}`,
                    }}
                    value={service || "Service-Name"}
                    onBlur={(e) => {
                      if (service === "") {
                        setValidation((val) => {
                          return { ...val, service: true };
                        });
                      } else {
                        setValidation((val) => {
                          return { ...val, service: false };
                        });
                      }
                    }}
                    onChange={(e) => {
                      setService(e.target.value);
                      setServiceType(
                        e.target.selectedOptions[0].attributes.servicetype.value
                      );
                    }}
                  >
                    <option value="Service-Name" selected disabled>
                      Service Name
                    </option>
                    {offers.length > 0 && (
                      <optgroup label="offer name">
                        {offers?.map((offer) => {
                          return (
                            offer?.enabled && (
                              <option
                                value={offer.id}
                                key={offer.id}
                                servicetype={"offer"}
                              >
                                {offer.offername}
                              </option>
                            )
                          );
                        })}
                      </optgroup>
                    )}
                    <optgroup label="service name">
                      {serviceData?.map((service) => {
                        return (
                          <option
                            value={service.id}
                            key={service.id}
                            servicetype={"service"}
                          >
                            {service.service_name}
                          </option>
                        );
                      })}
                    </optgroup>
                  </select>
                </Tooltip>
              </div>
              <div className="appoint-input-field">
                <select
                  // value={duration || "Select-duration"}
                  onChange={(e) => setDuration(e.target.value)}
                  style={{
                    border: `${validation.duration ? "1.5px solid red" : ""}`,
                  }}
                  onBlur={(e) => {
                    if (duration === "") {
                      setValidation((val) => {
                        return { ...val, duration: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, duration: false };
                      });
                    }
                  }}
                  value={duration}
                  required
                >
                  <option value="" selected disabled >
                    Select Duration
                  </option>
                  <option value="00:30:00">30 min</option>
                  <option value="00:45:00">45 min</option>
                  <option value="00:60:00">60 min</option>
                  <option value="01:30:00">90 min</option>
                  <option value="02:00:00">120 min</option>
                </select>
              </div>
              <div className="appoint-input-field">
                <Tooltip title="Manager Name" placement="top" arrow>
                  <select
                    value={selectedManager || "Select Manager"}
                    onBlur={(e) => {
                      if (selectedManager === "") {
                        setValidation((val) => {
                          return { ...val, manager_name: true };
                        });
                      } else {
                        setValidation((val) => {
                          return { ...val, manager_name: false };
                        });
                      }
                    }}
                    onChange={(e) => setSelectedManager(e.target.value)}
                    required
                    style={{
                      border: `${
                        validation.manager_name ? "1.5px solid red" : ""
                      }`,
                      ...(selectedManager === "" ? { color: "#9CA3B7" } : {}),
                    }}
                  >
                    <option value="Select Manager" selected disabled>
                      Select Manager
                    </option>
                    {managersData?.map((manager) => {
                      return (
                        <option value={manager.id} key={manager.id}>
                          {manager.managername}
                        </option>
                      );
                    })}
                  </select>
                </Tooltip>
              </div>
              <div className="appoint-input-field">
                <Tooltip title="Total Amount" placement="top" arrow>
                  <input
                    type="number"
                    placeholder="Total Amount"
                    required
                    value={totalAmount}
                    readOnly
                  />
                </Tooltip>
              </div>
            </div>
            <div className="right-col2">
              <div className="appoint-input-field">
                <input
                  placeholder="Time in"
                  type={field_type}
                  required
                  style={{
                    border: `${validation.time_in ? "1.5px solid red" : ""}`,
                  }}
                  onFocus={() => setType("time")}
                  onBlur={() => {
                    setType("text");
                    if (timeIn === "") {
                      setValidation((val) => {
                        return { ...val, time_in: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, time_in: false };
                      });
                    }
                  }}
                  id="timeIn"
                  value={timeIn}
                  onChange={(e) => setTimeIn(e.target.value)}
                />
                {validation.time_in && (
                  <Tooltip title="Time in is required">
                    <ErrorIcon
                      className="error-icon absolute right-[5px] bottom-[10px] hidden"
                      color="error"
                    />
                  </Tooltip>
                )}
              </div>
              <div className="appoint-input-field">
                <select
                  required
                  onChange={(e) => setGender(e.target.value)}
                  style={{
                    border: `${validation.gender ? "1.5px solid red" : ""}`,
                  }}
                  value={gender}
                  onBlur={() => {
                    if (gender === "") {
                      setValidation((val) => {
                        return { ...val, gender: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, gender: false };
                      });
                    }
                  }}
                >
                  <option value="" selected disabled >
                    Select Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div className="appoint-input-field">
                <Tooltip title="Membership Number" placement="top" arrow>
                  <input
                    type="text"
                    placeholder="Membership Number"
                    value={membership}
                    onChange={(e) => {
                      setMembership(e.target.value);
                      checkMembershipWithMembershipNo(e.target.value);
                    }}
                  />
                  <div className="appoint-inp-icon">
                    <AddIcon />
                  </div>
                </Tooltip>
              </div>

              <div className="appoint-input-field">
                <select
                  // value={chair || "Service-Chair"}
                  style={{
                    border: `${validation.chair ? "1.5px solid red" : ""}`,
                  }}
                  onBlur={(e) => {
                    if (chair === "") {
                      setValidation((val) => {
                        return { ...val, chair: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, chair: false };
                      });
                    }
                  }}
                  required
                  onChange={(e) => setChair(e.target.value)}
                  // onChange={(e) => {
                  //   const selectedOptions = Array.from(e.target.selectedOptions);
                  //   setFacilities(selectedOptions.map((option) => option.value));
                  // }}
                >
                  <option value="" selected disabled hidden>
                    Select Chair
                  </option>
                  {chairsData?.map((chair, index) => {
                    return (
                      <option
                        key={index}
                        value={chair.id}
                        disabled={chair.is_occupied || false}
                      >
                        {chair.chairname}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* <div className="appoint-input-field">
                <input
                  type="number"
                  placeholder="Membership Number"
                  min={0}
                  value={membership}
                  onChange={(e) => setMembership(e.target.value)}
                  onWheel={(e) => e.target.blur()}
                />
                <div className="appoint-inp-icon">
                  <AddIcon />
                </div>
              </div> */}
              <div className="appoint-input-field">
                <select
                  // value={staff || "staff-Name"}
                  onChange={(e) => setStaff(e.target.value)}
                  style={{
                    border: `${validation.staff ? "1.5px solid red" : ""}`,
                  }}
                  onBlur={(e) => {
                    if (staff === "") {
                      setValidation((val) => {
                        return { ...val, staff: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, staff: false };
                      });
                    }
                  }}
                  value={staff}
                  required
                >
                  <option value="" selected disabled>
                    Staff Name
                  </option>
                  {staffData?.map((staff, index) => {
                    return (
                      <option
                        key={index}
                        value={staff.id}
                        disabled={staff.is_busy || false}
                      >
                        {staff.staffname}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="appoint-input-field">
                <select
                  // value={paymentMode || "payment-mode"}
                  onChange={(e) => setPaymentMode(e.target.value)}
                  style={{
                    border: `${
                      validation.payment_mode ? "1.5px solid red" : ""
                    }`,
                  }}
                  onBlur={(e) => {
                    if (paymentMode === "") {
                      setValidation((val) => {
                        return { ...val, payment_mode: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, payment_mode: false };
                      });
                    }
                  }}
                  value={paymentMode}
                  required
                >
                  <option value="" selected disabled >
                    Payment Mode
                  </option>
                  <option value="UPI">UPI</option>
                  <option value="Net Banking">Net Banking</option>
                  <option value="Debit Card">Debit Card</option>
                  <option value="Credit Card">Credit Card</option>
                  <option value="Cash">Cash</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="appoint-input-field">
                <input
                  type="number"
                  placeholder="Amount Paid"
                  min={0}
                  style={{
                    border: `${
                      validation.amount_paid ? "1.5px solid red" : ""
                    }`,
                  }}
                  onBlur={(e) => {
                    if (amountPaid === "") {
                      setValidation((val) => {
                        return { ...val, amount_paid: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, amount_paid: false };
                      });
                    }
                  }}
                  value={amountPaid}
                  required
                  onChange={(e) => {
                    setAmountPaid(e.target.value);
                  }}
                />
                {validation.amount_paid && (
                  <Tooltip title="Amount Paid is required">
                    <ErrorIcon
                      className="error-icon absolute right-[5px] bottom-[10px] hidden"
                      color="error"
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          <div className="appointment-form-btn">
            <button type="submit">Book Now</button>
          </div>
        </form>
        <div className="appointment-form-right">
          <div className="staff-available-data">
            <h4>Available Staff</h4>
            <div className="staff-data">
              <table>
                <tbody>
                  {staffData?.map((staff, index) => {
                    return (
                      <tr key={index}>
                        <td>{staff.staffname}</td>
                        <td style={!staff.is_busy ? { color: "#04D000" } : {}}>
                          {staff.is_busy ? "Busy" : "Free"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="staff-available-data">
            <div
              className="membership-header "
              onClick={() => setShowMembershipDetails(!showMembershipDetails)}
            >
              <h4 className="membership-title">
                Membership{" "}
                {membershipDetails && " - " + membershipDetails.package_name}
              </h4>
              <KeyboardArrowUpIcon
                sx={{
                  transition: "all 0.2s ease-in-out",
                  transform: `${showMembershipDetails ? "" : "rotate(180deg)"}`,
                }}
              />
            </div>
            <Collapse in={showMembershipDetails}>
              {membershipDetails ? (
                <div className="md-details-container">
                  <table className="md-table">
                    <tr className="md-remaining-services">
                      <td className="md-first-row">Remaining Services </td>
                      <td className="md-value md-first-row">
                        {membershipDetails.num_services_allowed}
                      </td>
                    </tr>
                    <tr className="md-valid-till">
                      <td className="md-last-row">Valid Till </td>
                      <td className="md-value md-last-row">
                        {membershipDetails.valid_till}
                      </td>
                    </tr>
                  </table>
                  <div className="md-services-wrapper">
                    {membershipDetails?.services?.map((service) => {
                      return (
                        <div className="md-service">{service.service_name}</div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="md-details-container">
                  <h4 className="md-no-membership">No Membership</h4>
                </div>
              )}
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppointmentForm;
