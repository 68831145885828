import React, { useContext, useState, useEffect } from "react";
import "../css/settings.css";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AuthContext from "../../context/Auth";

const SettingDetails = () => {
  const { authTokens } = useContext(AuthContext);
  // manager

  const [managers, setManagers] = useState([]);
  const [addManager, setAddManager] = useState("");

  // room

  const [rooms, setRooms] = useState([]);
  const [addRoom, setAddRoom] = useState("");

  // room function

  const fetchRooms = () => {
    fetch(`https://trakky.in:8000/spavendor/rooms/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setRooms(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchRooms();
  }, [authTokens]);

  const handleRoomAdd = (e) => {
    e.preventDefault();

    if (addRoom !== "") {
      fetch(`https://trakky.in:8000/spavendor/rooms/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({
          roomname: addRoom,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          fetchRooms();
          setAddRoom("");
        })
        .catch((err) => console.log(err));
    }
  };

  const handleRoomDelete = (id) => {
    fetch(`https://trakky.in:8000/spavendor/rooms/${id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    })
      .then((res) => {
        if (res.status === 204) {
          fetchRooms();
        }
      })

      .catch((err) => console.log(err));
  };


  // manager function

  const handleManagerAdd = (e) => {
    e.preventDefault();

    fetch(`https://trakky.in:8000/spavendor/manager/` , {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({
        managername: addManager,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        fetchManager();
        setAddManager("");
      })
      .catch((err) => console.log(err));
  };

  const fetchManager = () => {
    fetch(`https://trakky.in:8000/spavendor/manager/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setManagers(data);
      })
      .catch((err) => console.log(err));
  };

  const handleManagerDelete = (id) => {
    fetch(`https://trakky.in:8000/spavendor/manager/${id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    })
      .then((res) => {
        if (res.status === 204) {
          fetchManager();
        }
      })

      .catch((err) => console.log(err)
      );

  }

  useEffect(() => {
    fetchManager();
  }, [authTokens]);


  return (
    <>
      <div className="sd-main-container">
        <div className="sd-manager-container">
          <div className="sd-manager-input">
            <div className="sd-manager-inner-input">
              <form
                onSubmit={(e) => {
                  handleManagerAdd(e);
                }}
              >
                <input
                  type="text"
                  name="managername"
                  value={addManager}
                  onChange={(e) => setAddManager(e.target.value)}
                  placeholder="Add Manager Name"
                  id="managername"
                />
                <button type="submit" className="sd-add-icon-inp">
                  <AddRoundedIcon />
                </button>
              </form>
            </div>
          </div>
          <div className="sd-manager-list">
          {managers &&
              managers.map((manager, index) => {
                return (
                  <>
                    <div key={manager.id}>{manager.managername}
                    <span onClick={()=>{
                      handleManagerDelete(manager.id)
                    }}><DeleteRoundedIcon className="sd-delete-icon" /></span>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        <div className="sd-room-container">
          <div className="sd-room-input" id="sd-room-inp-field">
            <div className="sd-room-inner-input">
              <form
                onSubmit={(e) => {
                  handleRoomAdd(e);
                }}
              >
                <input
                  type="text"
                  name="room"
                  value={addRoom}
                  placeholder="Add Room"
                  onChange={(e) => setAddRoom(e.target.value)}
                  id="room"
                />
                <button type="submit" className="sd-add-icon-inp">
                  <AddRoundedIcon />
                </button>
              </form>
            </div>
          </div>
          <div className="sd-room-list">
            {rooms &&
              rooms.map((room, index) => {
                return (
                  <>
                    <div key={room.id}>{room.roomname}
                    <span onClick={()=>{
                      handleRoomDelete(room.id)
                    }}><DeleteRoundedIcon className="sd-delete-icon" /></span>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingDetails;
