import { createContext, useState, useEffect, useCallback } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  let [user, setUser] = useState(() =>
    localStorage.getItem("spaVendorAuthTokens")
      ? jwtDecode(localStorage.getItem("spaVendorAuthTokens"))
      : null
  );

  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("spaVendorAuthTokens")
      ? JSON.parse(localStorage.getItem("spaVendorAuthTokens"))
      : null
  );
  let [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  let loginUser = async (e) => {
    e.preventDefault();
    const response =  await axios.post(
      "https://trakky.in:8000/api/token/",
      {
        username: e.target.username.value,
        password: e.target.password.value,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 401) {
      
      return response;
    }
    // let data = await response.json();

    if (response.status === 200 ) {
      localStorage.setItem("spaVendorAuthTokens", JSON.stringify(response.data));
      setAuthTokens(response?.data);
      setUser(jwtDecode(response?.data.access));
      setIsAuthenticated(true);

      navigate("/spa/", { replace: true });
    } else {
      alert("Something went wrong while logging in the user!");
    }
    return response;
  };

  let logoutUser = useCallback(() => {
    // e.preventDefault()
    localStorage.removeItem("spaVendorAuthTokens");
    setAuthTokens(null);
    setUser(null);
    navigate("/spa/signin", { replace: true });
    setIsAuthenticated(false);
  }, [navigate]);

  const updateToken = useCallback(async () => {

    try
    {
        if (authTokens) {

    
    const response = await fetch(
      "https://trakky.in:8000/api/token/refresh/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refresh: authTokens?.refresh }),
      }
    );

    const data = await response.json();
    if (response.status === 200) {
      setAuthTokens({ ...authTokens, access: data.access });
      setUser(jwtDecode(data.access));
      setIsAuthenticated(true);
      localStorage.setItem(
        "spaVendorAuthTokens",
        JSON.stringify({ ...authTokens, access: data.access })
      );
    } else {
      logoutUser();
    }}
  }
  catch(error){
    console.log(error)
  }

    if (loading) {
      setLoading(false);
    }
  }, [authTokens, loading, logoutUser]);

  let contextData = {
    user: user,
    authTokens: authTokens,
    loginUser: loginUser,
    logoutUser: logoutUser,
    isAuthenticated: isAuthenticated,
    
    // vendor: vendor
  };

  useEffect(()=>{

    if(loading)
    {
        updateToken()
    }
    else{

    const REFRESH_INTERVAL = 1000 * 60 * 50 // 60 minutes
    let interval = setInterval(()=>{
        if(authTokens){
            updateToken()
        }
    }, REFRESH_INTERVAL)
    return () => clearInterval(interval)
}

},[authTokens, loading , updateToken])

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
