import React, { useEffect, useState, useContext, useRef } from "react";
import "../css/Appointment.css";
import ExtendModal from "./ExtendModal";
import GeneralModal from "../generalModal/GeneralModal";
import CancelModal from "./CancelModal";
import ReviewModal from "./ReviewModal";
import AuthContext from "../../context/Auth";

const AppointmentListCard = ({ appointment , isDateInRange }) => {
  const { authTokens } = useContext(AuthContext);

  const [appointmentData, setAppointmentData] = useState(appointment);
  const [showExtendModal, setShowExtendModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);

  const [isReviewed, setIsReviewed] = useState(appointmentData?.is_reviewed);
  const [isCompleted, setIsCompleted] = useState(appointmentData?.is_completed);
  const [isCancelled, setIsCancelled] = useState(appointmentData?.is_cancelled);
  const [isRunning, setIsRunning] = useState(appointmentData?.is_running);
  const [timerValue, setTimerValue] = useState("00:00:00");
  const [isBlinkung, setIsBlinking] = useState(false);

  const formatTime = (time) => {
    const hours = Math.floor(time / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return `${hours}:${minutes}:${seconds}`;
  };

  const timeStringToMs = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return ((hours * 60 + minutes) * 60 + seconds) * 1000;
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const handleStartTimer = () => {
    let currentTime = getCurrentTime();

    try {
      fetch(
        `https://trakky.in:8000/salonvendor/appointments/${appointmentData?.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
          body: JSON.stringify({
            time_in: currentTime,
            is_running: true,
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setAppointmentData(data);
          setIsRunning(data?.is_running);
          setIsCompleted(data?.is_completed);
          setIsCancelled(data?.is_cancelled);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleEndTimer = () => {
    try {
      fetch(
        `https://trakky.in:8000/salonvendor/appointments/${appointmentData?.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access_token}`,
          },
          body: JSON.stringify({
            is_running: false,
            is_completed: true,
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setAppointmentData(data);
          setIsRunning(data?.is_running);
          setIsCompleted(data?.is_completed);
          setIsCancelled(data?.is_cancelled);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const startTimer = () => {
    if (isRunning) {
      const intervalId = setInterval(() => {
        let currentTime = getCurrentTime();
        let startTime = appointmentData?.time_in;
        let duration = appointmentData?.duration;

        const timeDiff =
          timeStringToMs(currentTime) - timeStringToMs(startTime);
        const finalTime = timeStringToMs(duration) - timeDiff;

        if (finalTime <= 0) {
          setTimerValue("00:00:00");
          setIsBlinking(true);
        } else {
          setTimerValue(formatTime(finalTime));
        }
      }, 1000);

      return intervalId;
    } else {
      setTimerValue("00:00:00");
    }
  };

  const stopTimer = (intervalId) => {
    clearInterval(intervalId);
  };

  useEffect(() => {
    const intervalId = startTimer();

    return () => {
      stopTimer(intervalId);
    };
  }, [isRunning , appointmentData]);

  return (
    <>
      <div
        className={`alc-main-card ${
          isBlinkung && !isCompleted && !isReviewed
            ? "appointment-card-animation"
            : ""
        } `}
        style={isRunning ? { outlineColor: "green" } : 
          isCancelled ? { outlineColor: "red"} : isCompleted ? { outlineColor: "blue" , outlineWidth : "2px"} : {}
        }
      >
        <div className="alc-main-card-header">
          <div className="alc-main-card-header-title">
            <span>{appointmentData?.customer_name}</span>
            <button
              style={isReviewed || isCancelled || !isDateInRange ? { display: "none" } : {}}
              onClick={() => {
                setShowExtendModal(true);
              }}
            >
              Extend Service
            </button>
          </div>
          <div className="alc-main-card-header-services">
            <span className="alc-card-dfont">Service:</span>
            <span className="alc-card-lfont">
              {appointmentData?.service_offer_name}
            </span>
          </div>
        </div>
        <div className="alc-main-card-time-details">
          <div>
            <span className="alc-card-dfont">Start Time: </span>
            <span className="alc-card-lfont">{appointmentData?.time_in}</span>
          </div>
          <div>
            <span className="alc-card-dfont">End Time: </span>
            <span className="alc-card-lfont">
              {formatTime(
                timeStringToMs(appointmentData?.duration) +
                  timeStringToMs(appointmentData?.time_in)
              )
              }

            </span>
          </div>
        </div>
        <div className="alc-main-card-staff-details">
          <div>
            <span className="alc-card-dfont">Staff: </span>
            <span className="alc-card-lfont">
              {appointmentData?.staff_name}
            </span>
          </div>
          <div>
            <span className="alc-card-dfont">Room: </span>
            <span className="alc-card-lfont">{appointmentData?.chair_name}</span>
          </div>
        </div>
        <div className="alc-main-card-customer-details">
          <div>
            <span className="alc-card-dfont">Customer Type: </span>
            <span className="alc-card-lfont">{appointmentData?.customer_type}</span>
          </div>
          <div>
            <span className="alc-card-dfont">Paid Amount: </span>
            <span className="alc-card-lfont">
              ₹ {appointmentData?.amount_paid}
            </span>
          </div>
          <div className="timer-outer">
            <div>
              <span className="alc-card-dfont">Payment Status: </span>
              <span className="alc-card-lfont">{appointmentData?.payment_status}</span>
            </div>
            <div className="alc-card-timer">{timerValue}</div>
          </div>
        </div>
        {!isCompleted && !isReviewed && !isCancelled ? (
          <div className="alc-main-card-footer-btn">
            <button
              className={`alc-card-timer-btn ${
                isRunning ? "invert-inp-timer-btn" : ""
              }`}
              onClick={() => {
                !isRunning ? handleStartTimer() : handleEndTimer();
              }}
            >
              {!isRunning ? "Start" : "End"}
            </button>
            <button className="alc-card-cancle-btn" style={{cursor : isRunning ? 'not-allowed' : 'auto'}} onClick={()=>{
              if(!isRunning){
                setShowCancelModal(true)
              }}
              }>Cancel</button>
            <button
              className={`alc-card-review-btn ${
                isRunning ? "invert-review-btn" : ""
              }`}
            >
              Review / Tip
            </button>
          </div>
        ) : isCompleted && !isReviewed && !isCancelled ? (
          <div className="alc-main-card-footer-btn">
            <button
              className="alc-card-thank-btn-mini"
            >
              Thank You
            </button>
            <button className={`alc-card-review-btn ${
                !isRunning ? "invert-review-btn" : ""
              }`} onClick={()=>{setShowReviewModal(true)}}>Review / Tip</button>
          </div>
        ) : (
          (isReviewed || isCancelled) && (
            <div className="alc-main-card-footer-btn">
              <button className="alc-card-thank-btn">{ isReviewed ? "Thank You" : "cancelled" }</button>
            </div>
          )
        )}
      </div>
      <GeneralModal
        open={showExtendModal}
        handleClose={() => setShowExtendModal(false)}
      >
        <ExtendModal appointment={appointmentData} setAppointmentData={setAppointmentData} setShowExtendModal={setShowExtendModal}/>
      </GeneralModal>
      <GeneralModal
        open={showCancelModal}
        handleClose={() => setShowCancelModal(false)}
      >
        <CancelModal id={appointmentData?.id} customer={appointmentData?.customer_name} service={appointmentData?.service_offer_name} setShowCancelModal={setShowCancelModal} setIsCancelled={setIsCancelled} />
      </GeneralModal>
      <GeneralModal
        open={showReviewModal}
        handleClose={() => setShowReviewModal(false)}
      >
        <ReviewModal id={appointmentData?.id} customer={appointmentData?.customer_name} service={appointmentData?.service_offer_name} setIsReviewed={setIsReviewed} setShowReviewModal={setShowReviewModal}/>
      </GeneralModal>

    </>
  );
};

export default AppointmentListCard;
