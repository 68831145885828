import React, { useContext, useState } from "react";
import AuthContext from "../../context/Auth";
import { Tooltip } from "@mui/material";
import "../css/allModal.css";
import toast from 'react-hot-toast';
import ErrorIcon from "@mui/icons-material/Error";


const ExtendModal = (props) => {
  const {
    customer_name,
    customer_phone,
    amount_paid,
    time_in,
    duration,
    manager_name,
    membership_code,
    payment_mode,
    room_name,
    staff_name,
    service_offer_name,
  } = props.appointment;

  const { authTokens } = useContext(AuthContext);

  const [extendedDuration, setExtendedDuration] = useState("");
  const [extededPrice, setExtendedPrice] = useState("");

  const [validation, setValidation] = useState({
    extendedDuration: false,
    extededPrice: false,
  });

  const [field_type, setType] = useState("text");

  // function to convert duration in minutes
  const timeStringToMs = (timeString) => {
    var timeParts = timeString.split(":");
    var timeInMs =
      Number(timeParts[0]) * 60 * 60 * 1000 +
      Number(timeParts[1]) * 60 * 1000 +
      Number(timeParts[2]) * 1000;

    // convert ms to minutes
    var minutes = Math.floor(timeInMs / 60000);
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    if (hours === 0) {
      return `${minutes} minutes`;
    } else {
      return `${hours} hours ${minutes} minutes`;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const hasError = Object.values(validation).some((v) => v === true);
    if (hasError) {
      return;
    }
    const data = {
      appointment: props.appointment.id,
      extended_duration: extendedDuration,
      extended_amount: parseFloat(extededPrice),
    }

    const response = await fetch(
      "https://trakky.in:8000/spavendor/extend-appointment/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access}`,
        },
        body: JSON.stringify(data),
      }
    );
    if(response.ok){
      const data = await response.json();
      toast.success("Appointment Extended Successfully");
      props.setAppointmentData(data);
      props.setShowExtendModal(false)
    }
    else{
      console.log("error");
      toast.error("Something Went Wrong");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="extend-form-container">
      {/* <div className="extend-form-header">
        <h2>Extend Appointment</h2>
      </div> */}
      <div className="appointment-fillup">
        <div className="left-col1">
          <div className="appoint-input-field">
            <Tooltip title="Customer Name" placement="top" arrow>
              <input
                type="text"
                required
                placeholder="Customer Name"
                value={customer_name}
                readOnly
              />
            </Tooltip>
          </div>
          <div className="appoint-input-field relative">
            <Tooltip title="Customer Number" placement="top" arrow>
              <input
                type="number"
                required
                placeholder="Customer Number"
                value={customer_phone}
                readOnly
              />
            </Tooltip>
          </div>
          <div className="appoint-input-field">
            <Tooltip title="Service Name" placement="top" arrow>
              <input
                type="text"
                required
                placeholder="Service Name"
                value={service_offer_name}
                readOnly
              />
            </Tooltip>
          </div>
          <div className="appoint-input-field">
            <Tooltip title="Service Duration" placement="top" arrow>
              <input
                type="text"
                required
                placeholder="Service Duration"
                value={timeStringToMs(duration)}
                readOnly
              />
            </Tooltip>
          </div>
          <div className="appoint-input-field">
            <Tooltip title="Paymment Mode" placement="top" arrow>
              <input
                type="text"
                required
                placeholder="Paymment Mode"
                value={payment_mode}
                readOnly
              />
            </Tooltip>
          </div>
        </div>
        <div className="right-col2">
          <div className="appoint-input-field">
            <Tooltip title="Time In" placement="top" arrow>
              <input
                placeholder="Time in"
                required
                type={field_type}
                id="timeIn"
                value={time_in}
                readOnly
              />
            </Tooltip>
          </div>
          <div className="appoint-input-field">
            <Tooltip title="Room" placement="top" arrow>
              <input
                type="text"
                required
                placeholder="Room Name"
                value={room_name}
                readOnly
              />
            </Tooltip>
          </div>
          <div className="appoint-input-field">
            <Tooltip title="Membership Number" placement="top" arrow>
              <input
                type="text"
                placeholder="Membership Number"
                value={membership_code || "No Membership"}
                readOnly
              />
            </Tooltip>
          </div>
          <div className="appoint-input-field">
            <Tooltip title="Staff Name" placement="top" arrow>
              <input
                type="text"
                required
                placeholder="Staff Name"
                value={staff_name}
                readOnly
              />
            </Tooltip>
          </div>
          <div className="appoint-input-field">
            <Tooltip title="Amount Paid" placement="top" arrow>
              <input
                type="text"
                placeholder="Amount Paid"
                required
                value={amount_paid + " INR"}
                readOnly
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="appoint-input-field last-field">
        <Tooltip title="Manager Name" placement="top" arrow>
          <input
            type="text"
            required
            placeholder="Manager Name"
            value={manager_name}
            readOnly
          />
        </Tooltip>
      </div>
      <div className="appointment-fillup mt-[25px] last-field">
        <div className="left-col1">
          <div className="appoint-input-field">
            <Tooltip title="Extended Service Duration" placement="top" arrow>
              <select
                value={extendedDuration || "Select-duration"}
                style={{
                  border: `${
                    validation.extendedDuration ? "1.5px solid red" : ""
                  }`,
                  ...(duration === ""
                    ? { color: "#9CA3B7" }
                    : { color: "black" }),
                }}
                onBlur={(e) => {
                  if (extendedDuration === "") {
                    setValidation((val) => {
                      return { ...val, extendedDuration: true };
                    });
                  } else {
                    setValidation((val) => {
                      return { ...val, extendedDuration: false };
                    });
                  }
                }}
                onChange={(e) => setExtendedDuration(e.target.value)}
                required
              >
                <option value="Select-duration" selected disabled hidden>
                  Select Extended Duration
                </option>
                <option value="00:30:00">30 min</option>
                <option value="00:45:00">45 min</option>
                <option value="01:00:00">60 min</option>
                <option value="01:30:00">90 min</option>
                <option value="02:00:00">120 min</option>
              </select>
            </Tooltip>
          </div>
        </div>
        <div className="right-col2">
          <div className="appoint-input-field">
            <Tooltip title="Extended Price" placement="top" arrow>
              <input
                type="number"
                placeholder="Extend Price"
                required
                style={{
                  border: `${validation.extededPrice ? "1.5px solid red" : ""}`,
                }}
                value={extededPrice}
                onChange={(e) => setExtendedPrice(e.target.value)}
                onBlur={(e) => {
                  if (extededPrice === "") {
                    setValidation((val) => {
                      return { ...val, extededPrice: true };
                    });
                  } else {
                    setValidation((val) => {
                      return { ...val, extededPrice: false };
                    });
                  }
                }}
              />
              {validation.extededPrice && (
                <Tooltip title="Extended Price is Required" placement="top" arrow>
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px]"
                    color="error"
                  />
                </Tooltip>
              )}
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="appointment-form-btn modal-submit-btn">
        <button type="submit">Book Now</button>
      </div>
    </form>
  );
};

export default ExtendModal;
