import React, { useContext, useEffect, useState } from "react";
import "../input.css";
import "./css/header.css";

import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosArrowDown } from "react-icons/io";

import lockscreen_icon from "./svg/lockscreen_svg.svg";
import SidebarContext from "./Sidebar_context";
import AuthContext from "../context/Auth";
import { IconButton } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import GeneralModal from "./generalModal/GeneralModal";
import QrCode from "./QrCode";

const Header = ({ ownerName, salonName }) => {
  const { logoutUser } = useContext(AuthContext);
  const [show, setshow] = useState(false);
  const [showQRModal , setShowQRModal] = useState(false);
  const { SidebarStatus, SetSidebar } = useContext(SidebarContext);

  return (
    <>
      <div className="header-container">
        <div className="header-mobile-menu-btn">
          <RxHamburgerMenu
            className=" w-[2rem] h-[2rem] active:w-[1.9rem] "
            onClick={() => {
              SetSidebar(!SidebarStatus);
            }}
          />
        </div>
        <div className="header-ownername header-left">Welcome {ownerName}</div>
        <div className="header-middle">
          <div className="header-search-container">
            <p className="text-xl font-extrabold ">{salonName}</p>
          </div>
        </div>
        <div className="header-right">
          <div className="">
            <IconButton
              onClick={() => setShowQRModal(true)}
              sx={{
                borderRadius: "10px",
               
                "&:hover": {
                  backgroundColor: "rgba(255,255,255,0.5)",
                },
              }}
            >
              <QrCodeScannerIcon sx={{ fontSize: "1.8rem" }} />
            </IconButton>
          </div>
          <div className="header-lockscreen-btn" onClick={logoutUser}>
            <img
              src={lockscreen_icon}
              className="lockscreen-icon"
              style={{ borderRadius: "50%" }}
              width="30px"
              alt="lockscreen-icon"
            />
            <span>Lock Screen</span>
          </div>
          <div className="header-profile-btn">
            <div className="header-profiledropdown">
              <span className="profile-name">{ownerName}</span>
              <IoIosArrowDown
                onClick={() => {
                  setshow(!show);
                }}
                className=" border bg-white border-1 cursor-pointer z-10  border-black  w-[2.1rem] md:w-[1rem] p-[.15rem] h-[2.1rem] md:h-[1rem] rounded-full "
              />
            </div>
            <span className="header-role">Admin</span>
            <div
              className={` absolute top-[115%]  min-w-[160px] right-0   md:left-0  md:h-[8rem] transition-opacity ease-in-out    duration-500  ${
                show ? " opacity-100  z-50  " : " opacity-0 -z-10 "
              } w-[100%] flex flex-col justify-center items-start bg-white  rounded-xl shadow-3xl 
            border border-1 border-gray-500     `}
            >
              <button className=" text-[.8rem]  font-bold  px-4 py-2">
                Profile
              </button>
              <div>
                <button
                  className=" text-[.8rem]  font-bold  px-4 py-2"
                  onClick={logoutUser}
                >
                  Logout
                </button>
              </div>

              <button
                className=" text-[.8rem]  font-bold  px-4 py-2"
                style={{
                  // Text align to the left
                  textAlign: "left",
                }}
              >
                Help: 063551 67304
              </button>
            </div>
          </div>
        </div>
      </div>
      <GeneralModal open={showQRModal} handleClose={() => setShowQRModal(false)} >
        <QrCode />
      </GeneralModal>
    </>
  );
};

export default Header;
