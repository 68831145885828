import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import id_proof from "../svg/id_proof.svg";
import AuthContext from "../../context/Auth";
import "../css/staffmanagement.css";
import { Tooltip } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import toast from "react-hot-toast";

function StaffForm() {

  const navigate = useNavigate();

  const { authTokens } = useContext(AuthContext);

  const [type, setType] = useState("text");
  const [staffName, setStaffName] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [salary, setSalary] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [idProof, setIdProof] = useState("");
  const [isPermanent, setIsPermanent] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [expertise, setExpertise] = useState("");
  const [gender, setGender] = useState("");
  const [therapies, setTherapies] = useState([]);
  const [validation, setValidation] = useState({
    contactNumber: false,
    joiningDate: false,
    staffName: false,
    gender: false,
    salary: false,
    address: false,
    paymentMode: false,
    idProof: false,
    isPermanent: false,
    amountPaid: false,
    expertise: false,
    email: false,
  });

  useEffect(() => {
    fetch("https://trakky.in:8000/salonvendor/category/", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authTokens?.access_token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setTherapies(data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const phoneRegex = /^[0-9]{10}$/;

  const handleSubmit = (e) => {
    e.preventDefault();

    const hasError = Object.values(validation).some((val) => val === true);
    if (hasError) {
      toast.error("Please fill all the fields correctly");
      return;
    }
    let data = new FormData();
    data.append("staffname", staffName);
    data.append("joining_date", joiningDate);
    data.append("ph_number", contactNumber);
    data.append("salary", salary);
    data.append("address", address);
    data.append("email", email);
    data.append("id_proof", idProof);
    data.append("is_permanent", isPermanent);
    data.append("amount_paid", amountPaid);
    data.append("expertise", expertise);
    data.append("gender", gender);

    fetch("https://trakky.in:8000/salonvendor/staff/", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authTokens?.access_token}`,
      },

      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          toast.success("Staff Registered Successfully");
          navigate("/salon/staffmanagement/stafftable");
        }
      })
      .catch((err) => toast.error("Something went wrong"));
  };

  return (
    <form className="staff-register-form" onSubmit={handleSubmit}>
      <div className="appointment-fillup">
        <div className="left-col1">
          <div className="appoint-input-field">
            <Tooltip arrow title="Staff Name" placement="top">
              <input
                type="text"
                placeholder="Staff Name"
                style={{
                  border: validation.staffName ? "1.5px solid red" : "none",
                }}
                onBlur={() => {
                  if (staffName === "") {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        staffName: true,
                      };
                    });
                  } else {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        staffName: false,
                      };
                    });
                  }
                }}
                value={staffName}
                required
                onChange={(e) => {
                  setStaffName(e.target.value);
                }}
              />
              {validation.staffName && (
                <Tooltip arrow title="Staff name is required" placement="top">
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px] hidden"
                    color="error"
                  />
                </Tooltip>
              )}
            </Tooltip>
          </div>
        </div>
        <div className="right-col2">
          <div className="appoint-input-field">
            <Tooltip arrow title="Joining Date" placement="top">
              <input
                placeholder="Joining Date"
                style={{
                  border: validation.joiningDate ? "1.5px solid red" : "none",
                }}
                type={type}
                value={joiningDate}
                required
                onFocus={() => setType("date")}
                onBlur={() => {
                  setType("text");
                  if (joiningDate === "") {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        joiningDate: true,
                      };
                    });
                  } else {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        joiningDate: false,
                      };
                    });
                  }
                }}
                onChange={(e) => setJoiningDate(e.target.value)}
                id="joiningdate"
              />
              {validation.joiningDate && (
                <Tooltip arrow title="Joining date is required" placement="top">
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px] hidden"
                    color="error"
                  />
                </Tooltip>
              )}
            </Tooltip>
          </div>
        </div>
      </div>

      <div className="appointment-fillup-address">
        <div className="appoint-input-field">
          <Tooltip arrow title="Address" placement="top">
            <input
              type="text"
              placeholder="Address"
              style={{
                border: validation.address ? "1.5px solid red" : "none",
              }}
              onBlur={() => {
                if (address === "") {
                  setValidation((prev) => {
                    return {
                      ...prev,
                      address: true,
                    };
                  });
                } else {
                  setValidation((prev) => {
                    return {
                      ...prev,
                      address: false,
                    };
                  });
                }
              }}
              value={address}
              required
              onChange={(e) => setAddress(e.target.value)}
            />
            {validation.address && (
              <Tooltip arrow title="Address is required" placement="top">
                <ErrorIcon
                  className="error-icon absolute right-[5px] bottom-[10px] hidden"
                  color="error"
                />
              </Tooltip>
            )}
          </Tooltip>
        </div>
      </div>

      <div className="appointment-fillup">
        <div className="left-col1">
          <div className="appoint-input-field relative">
            <Tooltip arrow title="Contact Number" placement="top">
              <input
                type="tel"
                placeholder="Staff Phone Number"
                style={{
                  border: validation.contactNumber ? "1.5px solid red" : "none",
                }}
                onBlur={() => {
                  if (contactNumber === "" || !phoneRegex.test(contactNumber)) {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        contactNumber: true,
                      };
                    });
                  } else {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        contactNumber: false,
                      };
                    });
                  }
                }}
                value={contactNumber}
                required
                onChange={(e) => {
                  setContactNumber((val) => {
                    if (e.target.value.length > 10) {
                      return val;
                    } else if (e.target.value.length < 10) {
                      return e.target.value;
                    } else {
                      return e.target.value;
                    }
                  });
                }}
              />
              {validation.contactNumber && (
                <Tooltip
                  arrow
                  title={
                    contactNumber === ""
                      ? "Contact number is required"
                      : "invalid"
                  }
                  placement="top"
                >
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px] hidden"
                    color="error"
                  />
                </Tooltip>
              )}
            </Tooltip>
          </div>
          <div className="appoint-input-field">
            <Tooltip arrow title="Gender" placement="top">
              <select
                // value={isPermanent || 'Status'}
                required
                style={{
                  border: validation.gender ? "1.5px solid red" : "none",
                }}
                onBlur={() => {
                  if (gender === "") {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        gender: true,
                      };
                    });
                  } else {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        gender: false,
                      };
                    });
                  }
                }}
                onChange={(e) => {
                  setGender(e.target.value);
                }}
              >
                <option value="" selected disabled hidden>
                  Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </Tooltip>
          </div>
          <div className="appoint-input-field">
            <Tooltip arrow title="Salary" placement="top">
              <input
                type="number"
                placeholder="Salary"
                value={salary}
                style={{
                  border: validation.salary ? "1.5px solid red" : "none",
                }}
                onBlur={() => {
                  if (salary === "") {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        salary: true,
                      };
                    });
                  } else {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        salary: false,
                      };
                    });
                  }
                }}
                required
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  setSalary(e.target.value);
                }}
              />
              {validation.salary && (
                <Tooltip arrow title="Salary is required" placement="top">
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px] hidden"
                    color="error"
                  />
                </Tooltip>
              )}
            </Tooltip>
          </div>
          <div className="appoint-input-field">
            <Tooltip arrow title="Email" placement="top">
              <input
                type="email"
                placeholder="Email"
                value={email}
                style={{
                  border: validation.email ? "1.5px solid red" : "none",
                }}
                onBlur={() => {
                  if (email === "" || !emailRegex.test(email)) {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        email: true,
                      };
                    });
                  } else {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        email: false,
                      };
                    });
                  }
                }}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              {validation.email && (
                <Tooltip
                  arrow
                  title={email === "" ? "Email is required" : "invalid"}
                  placement="top"
                >
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px] hidden"
                    color="error"
                  />
                </Tooltip>
              )}
            </Tooltip>
          </div>
        </div>
        <div className="right-col2">
          <div className="appoint-input-field">
            <Tooltip arrow title="Status" placement="top">
              <select
                // value={isPermanent || 'Status'}
                style={{
                  border: validation.isPermanent ? "1.5px solid red" : "none",
                }}
                onBlur={() => {
                  if (isPermanent === "") {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        isPermanent: true,
                      };
                    });
                  } else {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        isPermanent: false,
                      };
                    });
                  }
                }}
                required
                onChange={(e) => {
                  setIsPermanent(e.target.value);
                }}
              >
                <option value="" selected disabled hidden>
                  Status
                </option>
                <option value="true">Permanent</option>
                <option value="false">Temporary</option>
              </select>
            </Tooltip>
          </div>
          <div className="appoint-input-field">
            <Tooltip arrow title="Expertise" placement="top">
              <select
                // value={expertise || "Expertise"}
                style={{
                  border: validation.expertise ? "1.5px solid red" : "none",
                }}
                onBlur={() => {
                  if (expertise === "") {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        expertise: true,
                      };
                    });
                  } else {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        expertise: false,
                      };
                    });
                  }
                }}
                required
                onChange={(e) => {
                  setExpertise(e.target.value);
                }}
              >
                <option value="" selected disabled hidden>
                  Specialist
                </option>
                {therapies &&
                  therapies.map((therapy) => {
                    return <option value={therapy.id}>{therapy.name}</option>;
                  })}
              </select>
            </Tooltip>
          </div>
          <div className="appoint-input-field">
            <Tooltip arrow title="Amount Paid" placement="top">
              <input
                type="number"
                placeholder="Amount Paid"
                value={amountPaid}
                style={{
                  border: validation.amountPaid ? "1.5px solid red" : "none",
                }}
                onBlur={() => {
                  if (amountPaid === "") {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        amountPaid: true,
                      };
                    });
                  } else {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        amountPaid: false,
                      };
                    });
                  }
                }}
                required
                onWheel={(e) => e.target.blur()}
                onChange={(e) => setAmountPaid(e.target.value)}
              />
              {validation.amountPaid && (
                <Tooltip arrow title="Amount paid is required" placement="top">
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px] hidden"
                    color="error"
                  />
                </Tooltip>
              )}
            </Tooltip>
          </div>
          <div className="appoint-input-field">
            <Tooltip arrow title="Id Proof" placement="top">
              <input
                type="file"
                required
                style={{
                  border: validation.idProof ? "1.5px solid red" : "none",
                }}
                onBlur={() => {
                  if (!idProof) {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        idProof: true, // Set to true if no file is selected (validation error).
                      };
                    });
                  } else {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        idProof: false, // Set to false if a file is selected (no validation error).
                      };
                    });
                  }
                }}
                placeholder="Upload Id proof"
                onChange={(e) => setIdProof(e.target.files[0])}
              />
            </Tooltip>
            <img src={id_proof} alt="id proof" className="appoint-inp-icon" />
          </div>
        </div>
      </div>
      <div className="appointment-form-btn">
        <button type="submit">Register Staff</button>
      </div>
    </form>
  );
}

export default StaffForm;
