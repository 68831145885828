import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import AuthContext from "../../context/Auth";
import "../css/customer.css";
import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip } from "@mui/material";
import toast from 'react-hot-toast';

const AddCustomer = (props) => {
  var customerData = props?.customerData;
  const setCustomerData = props.setCustomerData;

  const { authTokens } = useContext(AuthContext);
  const [validation, setValidation] = useState({
    customer_name: false,
    customer_phone: false,
    service: false,
    duration: false,
    time_in: false,
    payment_mode: false,
    room: false,
    staff: false,
    amount_paid: false,
  });
  const [roomsData, setRoomsData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const date =
    props?.date ||
    customerData?.date ||
    new Date()
      .toLocaleString("en-CA", { timeZone: "Asia/Kolkata" })
      .slice(0, 10);
  const [customer, setCustomer] = useState(customerData?.customer_name || "");
  const [contactNumber, setContactNumber] = useState(
    customerData?.customer_phone || ""
  );
  const [service, setService] = useState(customerData?.therapy || "");
  const [duration, setDuration] = useState(customerData?.duration || "");
  const [timeIn, setTimeIn] = useState(customerData?.time_in || "");
  const [paymentMode, setPaymentMode] = useState(
    customerData?.payment_mode || ""
  );
  const [room, setRoom] = useState(customerData?.room || "");
  const [membership, setMembership] = useState(customerData?.membership || "");
  const [staff, setStaff] = useState(customerData?.staff || "");
  const [amountPaid, setAmountPaid] = useState(customerData?.amount_paid || "");

  const [field_type, setType] = useState("text");

  useEffect(() => {
    fetch("https://trakky.in:8000/spavendor/rooms/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.access}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data) {
          setRoomsData(data);
        }
      });
  }, [authTokens, customerData]);

  useEffect(() => {
    fetch("https://trakky.in:8000/spavendor/staff/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.access}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data) {
          setStaffData(data);
        }
      });
  }, [authTokens, customerData]);

  useEffect(() => {
    fetch("https://trakky.in:8000/spas/service/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.access}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data) {
          setServiceData(data);
        }
      });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const hasError = Object.values(validation).some((val) => val === true);
    if (hasError) {
      return;
    }

    let data = {
      date: date,
      customer_name: customer,
      customer_phone: contactNumber,
      service: service,
      duration: duration,
      time_in: timeIn,
      payment_mode: paymentMode,
      room: room,

      staff: staff,
      amount_paid: amountPaid,
    };
    if (membership) {
      data.membership = membership;
    }

    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/customerappointment/${
          customerData?.id ?? ""
        }${customerData ? "/" : ""}`,
        {
          method: !customerData ? "POST" : "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access}`,
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      if (result) {
        if (customerData) {
          setCustomerData(result);
        } else {
          toast.success('Customer Added Successfully');
          // Reload the Page
          // window.location.reload();
        }
      }
    } catch (error) {
      toast.error('Something went wrong');
      console.log(error);
    }
  };

  return (
    <div className="customer-page-container bg-[#f0ecff]">
      <div className="Customer-page-form">
        <div className="customer-form-left">
        <form onSubmit={handleSubmit}> 
          <div className="customer-fillup">
            <div className="left-col1">
              <div className="cust-input-field">
                <input
                  style={{
                    border: `${
                      validation.customer_name ? "1.5px solid red" : ""
                    }`,
                  }}
                  type="text"
                  placeholder="Customer Name"
                  value={customer}
                  onChange={(e) => {
                    setCustomer(e.target.value);
                  }}
                  onBlur={() => {
                    if (customer === "") {
                      setValidation((val) => {
                        return { ...val, customer_name: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, customer_name: false };
                      });
                    }
                  }}
                />
                {validation.customer_name && (
                  <Tooltip title="Customer name is required">
                    <ErrorIcon
                      className="error-icon absolute right-[5px] bottom-[10px] hidden"
                      color="error"
                    />
                  </Tooltip>
                )}
              </div>
              <div className="cust-input-field">
                <input
                  style={{
                    border: `${
                      validation.customer_phone ? "1.5px solid red" : ""
                    }`,
                  }}
                  type="tel"
                  placeholder="Customer Number"
                  value={contactNumber}
                  required
                  onBlur={(e) => {
                    if (contactNumber === "" || contactNumber.length < 10) {
                      setValidation((val) => {
                        return { ...val, customer_phone: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, customer_phone: false };
                      });
                    }
                  }}
                  onChange={(e) => {
                    setContactNumber((val) => {
                      if (e.target.value.length > 10) {
                        return val;
                      } else if (e.target.value.length < 10) {
                        return e.target.value;
                      } else {
                        return e.target.value;
                      }
                    });
                  }}
                />
                {validation.customer_phone && (
                  <Tooltip title="Atleast 10 digits are required">
                    <ErrorIcon
                      className="error-icon absolute right-[5px] bottom-[10px] hidden"
                      color="error"
                    />
                  </Tooltip>
                )}
              </div>
              <div className="cust-input-field">
                <select
                  required
                  style={{
                    border: `${validation.service ? "1.5px solid red" : ""}`,
                  }}
                  value={service || "Service-Name"}
                  onChange={(e) => setService(e.target.value)}
                  onBlur={() => {
                    if (service === "") {
                      setValidation((val) => {
                        return { ...val, service: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, service: false };
                      });
                    }
                  }}
                >
                  <option value="Service-Name" disabled>
                    Service Name
                  </option>
                  {serviceData?.map((service, index) => {
                    return (
                      <option value={service.id} key={index}>
                        {service.service_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="cust-input-field">
                <select
                  value={duration || "Select-duration"}
                  onChange={(e) => setDuration(e.target.value)}
                  style={{
                    border: `${validation.duration ? "1.5px solid red" : ""}`,
                  }}
                  required
                  onBlur={() => {
                    if (duration === "") {
                      setValidation((val) => {
                        return { ...val, duration: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, duration: false };
                      });
                    }
                  }}
                >
                  <option value="Select-duration" disabled>
                    Select Duration
                  </option>
                  <option value="00:30:00">30 min</option>
                  <option value="00:45:00">45 min</option>
                  <option value="00:60:00">60 min</option>
                  <option value="01:30:00">90 min</option>
                  <option value="02:00:00">120 min</option>
                </select>
              </div>
              <div className="cust-input-field">
                <select
                  value={paymentMode || "payment-mode"}
                  style={{
                    border: `${
                      validation.payment_mode ? "1.5px solid red" : ""
                    }`,
                  }}
                  onChange={(e) => setPaymentMode(e.target.value)}
                  required
                  onBlur={() => {
                    if (paymentMode === "") {
                      setValidation((val) => {
                        return { ...val, payment_mode: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, payment_mode: false };
                      });
                    }
                  }}
                >
                  <option value="payment-mode" disabled>
                    Payment Mode
                  </option>
                  <option value="UPI">UPI</option>
                  <option value="Net Banking">Net Banking</option>
                  <option value="Debit Card">Debit Card</option>
                  <option value="Credit Card">Credit Card</option>
                  <option value="Cash">Cash</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="right-col2">
              <div className="cust-input-field">
                <input
                  placeholder="Time in"
                  type={field_type}
                  style={{
                    border: `${validation.time_in ? "1.5px solid red" : ""}`,
                  }}
                  onFocus={() => setType("time")}
                  onBlur={() => {
                    setType("text");
                    if (timeIn === "") {
                      setValidation((val) => {
                        return { ...val, time_in: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, time_in: false };
                      });
                    }
                  }}
                  id="timeIn"
                  value={timeIn}
                  onChange={(e) => setTimeIn(e.target.value)}
                />
                {validation.time_in && (
                  <Tooltip title="Time in is required">
                    <ErrorIcon
                      className="error-icon absolute right-[5px] bottom-[10px] hidden"
                      color="error"
                    />
                  </Tooltip>
                )}
              </div>
              <div className="cust-input-field">
                <select
                  value={room || "Service-Room"}
                  style={{
                    border: `${validation.room ? "1.5px solid red" : ""}`,
                  }}
                  required
                  onChange={(e) => setRoom(e.target.value)}
                  // onChange={(e) => {
                  //   const selectedOptions = Array.from(e.target.selectedOptions);
                  //   // console.log(selectedOptions.map(option => option.value));
                  //   setFacilities(selectedOptions.map((option) => option.value));
                  // }}
                  onBlur={() => {
                    if (room === "") {
                      setValidation((val) => {
                        return { ...val, room: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, room: false };
                      });
                    }
                  }}
                >
                  <option value="Service-Room" disabled>
                    Select Room
                  </option>
                  {roomsData?.map((room, index) => {
                    return (
                      <option key={index} value={room.id}>
                        {room.roomname}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="cust-input-field">
                <input
                  type="number"
                  placeholder="Membership Number"
                  value={membership}
                  onChange={(e) => setMembership(e.target.value)}
                  style={{
                    border: `${validation.membership ? "1.5px solid red" : ""}`,
                  }}
                  required
                  onBlur={() => {
                    if (membership === "") {
                      setValidation((val) => {
                        return { ...val, membership: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, membership: false };
                      });
                    }
                  }}
                />
                {validation.membership && (
                  <Tooltip title="Membership number is required">
                    <ErrorIcon
                      className="error-icon absolute right-[5px] bottom-[10px] hidden"
                      color="error"
                    />
                  </Tooltip>
                )}
                <div className="cust-inp-icon">
                  <AddIcon />
                </div>
              </div>
              <div className="cust-input-field">
                <select
                  value={staff || "staff-Name"}
                  onChange={(e) => setStaff(e.target.value)}
                  required
                  style={{
                    border: `${validation.staff ? "1.5px solid red" : ""}`,
                  }}
                  onBlur={() => {
                    if (staff === "") {
                      setValidation((val) => {
                        return { ...val, staff: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, staff: false };
                      });
                    }
                  }}
                >
                  <option value="staff-Name" disabled>
                    Staff Name
                  </option>
                  {staffData?.map((staff, index) => {
                    return (
                      <option key={index} value={staff.id}>
                        {staff.staffname}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="cust-input-field">
                <input
                  type="tel"
                  placeholder="Amount Paid"
                  required
                  value={amountPaid}
                  style={{
                    border: `${
                      validation.amount_paid ? "1.5px solid red" : ""
                    }`,
                  }}
                  onChange={(e) => {
                    setAmountPaid(e.target.value);
                  }}
                  onBlur={() => {
                    if (amountPaid === "") {
                      setValidation((val) => {
                        return { ...val, amount_paid: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, amount_paid: false };
                      });
                    }
                  }}
                />
                {validation.amount_paid && (
                  <Tooltip title="Amount paid is required">
                    <ErrorIcon
                      className="error-icon absolute right-[5px] bottom-[10px] hidden"
                      color="error"
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          <div className="customer-form-btn">
            <button type="submit">
              Book Now
            </button>
          </div>
          </form>
        </div>
   
      </div>{" "}
    </div>
  );
};

export default AddCustomer;
