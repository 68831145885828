import React, { useContext, useState, useEffect } from "react";
import "../css/settings.css";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AuthContext from "../../context/Auth";

const SettingDetails = () => {
  const { authTokens } = useContext(AuthContext);
  // manager

  const [managers, setManagers] = useState([]);
  const [addManager, setAddManager] = useState("");

  // chair

  const [chairs, setchairs] = useState([]);
  const [addchair, setAddchair] = useState("");

  // chair function

  const fetchchairs = () => {
    fetch(`https://trakky.in:8000/salonvendor/chairs/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access_token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setchairs(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchchairs();
  }, [authTokens]);

  const handlechairAdd = (e) => {
    e.preventDefault();

    if (addchair !== "") {
      fetch(`https://trakky.in:8000/salonvendor/chairs/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access_token}`,
        },
        body: JSON.stringify({
          chairname: addchair,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          fetchchairs();
          setAddchair("");
        })
        .catch((err) => console.log(err));
    }
  };

  const handlechairDelete = (id) => {
    fetch(`https://trakky.in:8000/salonvendor/chairs/${id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access_token}`,
      },
    })
      .then((res) => {
        if (res.status === 204) {
          fetchchairs();
        }
      })

      .catch((err) => console.log(err));
  };


  // manager function

  const handleManagerAdd = (e) => {
    e.preventDefault();

    fetch(`https://trakky.in:8000/salonvendor/manager/` , {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access_token}`,
      },
      body: JSON.stringify({
        managername: addManager,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        fetchManager();
        setAddManager("");
      })
      .catch((err) => console.log(err));
  };

  const fetchManager = () => {
    fetch(`https://trakky.in:8000/salonvendor/manager/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access_token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setManagers(data);
      })
      .catch((err) => console.log(err));
  };

  const handleManagerDelete = (id) => {
    fetch(`https://trakky.in:8000/salonvendor/manager/${id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access_token}`,
      },
    })
      .then((res) => {
        if (res.status === 204) {
          fetchManager();
        }
      })

      .catch((err) => console.log(err)
      );

  }

  useEffect(() => {
    fetchManager();
  }, [authTokens]);


  return (
    <>
      <div className="sd-main-container">
        <div className="sd-manager-container">
          <div className="sd-manager-input">
            <div className="sd-manager-inner-input">
              <form
                onSubmit={(e) => {
                  handleManagerAdd(e);
                }}
              >
                <input
                  type="text"
                  name="managername"
                  value={addManager}
                  onChange={(e) => setAddManager(e.target.value)}
                  placeholder="Add Manager Name"
                  id="managername"
                />
                <button type="submit" className="sd-add-icon-inp">
                  <AddRoundedIcon />
                </button>
              </form>
            </div>
          </div>
          <div className="sd-manager-list">
          {managers &&
              managers.map((manager, index) => {
                return (
                  <>
                    <div key={manager.id}>{manager.managername}
                    <span onClick={()=>{
                      handleManagerDelete(manager.id)
                    }}><DeleteRoundedIcon className="sd-delete-icon" /></span>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        <div className="sd-room-container">
          <div className="sd-room-input" id="sd-room-inp-field">
            <div className="sd-room-inner-input">
              <form
                onSubmit={(e) => {
                  handlechairAdd(e);
                }}
              >
                <input
                  type="text"
                  name="chair"
                  value={addchair}
                  placeholder="Add chair"
                  onChange={(e) => setAddchair(e.target.value)}
                  id="chair"
                />
                <button type="submit" className="sd-add-icon-inp">
                  <AddRoundedIcon />
                </button>
              </form>
            </div>
          </div>
          <div className="sd-room-list">
            {chairs &&
              chairs.map((chair, index) => {
                return (
                  <>
                    <div key={chair.id}>{chair.chairname}
                    <span onClick={()=>{
                      handlechairDelete(chair.id)
                    }}><DeleteRoundedIcon className="sd-delete-icon" /></span>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingDetails;
