import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/Auth";
import "../css/membership.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import { IconButton } from "@mui/material";
import toast from "react-hot-toast";
import { Tooltip } from "@mui/material";


const MembershipType = () => {
  const { authTokens } = useContext(AuthContext);

  const [validation, setValidation] = useState({
    membershipName: false,
    validity: false,
    totalPrice: false,
    membershipPrice: false,
    remarks: false,
    allServices: false,
    selectedServices: false,
  });
  const [membershipName, setMembershipName] = useState("");
  const [validity, setValidity] = useState("");
  const [totalPrice, setTotalPrice] = useState(null);
  const [membershipPrice, setMembershipPrice] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [allServices, setAllServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([
    {
      id: null,
      noOfService: "",
    },
  ]);
  const [gender,setgender]=useState("")

  const handleAddService = () => {
    if (
      selectedServices[selectedServices?.length - 1]?.id !== null &&
      selectedServices[selectedServices?.length - 1]?.noOfService !== ""
    ) {
      setSelectedServices([...selectedServices, { id: null, noOfService: "" }]);
    }
  };

  const handleServiceDelete = (index) => {
    if (selectedServices.length === 1) {
      setSelectedServices([
        {
          id: null,
          noOfService: "",
        },
      ]);
    } else {
      let temp = [...selectedServices];
      temp.splice(index, 1);
      setSelectedServices(temp);
    }
    let removedPrice = selectedServices.reduce((acc, curr, i) => {
      if (i !== index) {
        return (
          acc +
          curr.noOfService *
            allServices.find((item) => item.id == curr.id)?.price
        );
      } else {
        return acc;
      }
    }, 0);
    setTotalPrice(removedPrice);
  };

  // Function to calculate total price
  const calculateTotalPrice = () => {
    let addedPrice = selectedServices.reduce((acc, curr) => {
      return (
        acc +
        curr.noOfService * allServices.find((item) => item.id == curr.id)?.price
      );
    }, 0);
    setTotalPrice(addedPrice);
  };

  // Function to handle service change
  const handleServiceChange = (e, index) => {
    let temp = [...selectedServices];
    temp[index].id = e.target.value;
    setSelectedServices(temp);

    calculateTotalPrice();
  };

  // Function to handle no of service change
  const handleNoOfServiceChange = (e, index) => {
    let temp = [...selectedServices];
    temp[index].noOfService = e.target.value;
    setSelectedServices(temp);

    calculateTotalPrice();
  };

  // Function to get all available services
  const getAllServices = async () => {
    const response = await fetch("https://trakky.in:8000/salonvendor/service/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.access_token}`,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      setAllServices(data);
    } else {
      console.log("Error in fetching services");
    }
  };

  // Function to handle no of service API
  const handleNoofServiceAPI = async (
    membership_type,
    services,
    number_of_services
  ) => {
    const data = {
      membership_type: membership_type,
      services: services,
      number_of_services: number_of_services,
    };

    const response = await fetch(
      "https://trakky.in:8000/salonvendor/membership-type-service/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access_token}`,
        },
        body: JSON.stringify(data),
      }
    );

    if (response.status === 201) {
      return true;
    } else {
      return false;
    }
  };

  // Funtion to submit the form
  const handleSubmit = async () => {
    const hasError = Object.values(validation).some((item) => item === true);
    if (hasError) {
      return;
    }
    const data = {
      name: membershipName,
      membership_price: membershipPrice,
      validity: validity,
      services_allowed: selectedServices.map((service) => service.id),
      total_price: totalPrice,
      remarks: remarks,
      gender:gender
    };

    const response = await fetch(
      "https://trakky.in:8000/salonvendor/membership-type/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access_token}`,
        },
        body: JSON.stringify(data),
      }
    );

    if (response.status === 201) {
      const responseData = await response.json();
      const noOfService = selectedServices.map(
        (service) => service.noOfService
      );
      const success = handleNoofServiceAPI(
        responseData.id,
        data.services_allowed,
        noOfService
      );

      if (success) {
        toast.success("Membership Type Added Successfully");
        setRemarks("");
        setMembershipName("");
        setValidity("");
        setTotalPrice(null);
        setMembershipPrice(null);
        setAllServices([]);
        setSelectedServices([
          {
            id: null,
            noOfService: "",
          },
        ]);
        getAllServices();
      } else {
        toast.error("Error in Adding Membership Type");
        console.log("Error");
      }
    } else {
      toast.error("Error in Adding Membership Type");
      console.log("error");
    }
  };

  useEffect(() => {
    getAllServices();
  }, []);

  return (
    <>
      <div className="ms-register-form">
        <div className="appointment-fillup" id="memtype-field">
          <div className="left-col1">
            <div className="appoint-input-field">
              <input
                type="text"
                required
                style={{
                  border: validation.membershipName ? "1.5px solid red" : "",
                }}
                onBlur={() => {
                  if (membershipName === "") {
                    setValidation({
                      ...validation,
                      membershipName: true,
                    });
                  } else {
                    setValidation({
                      ...validation,
                      membershipName: false,
                    });
                  }
                }}
                placeholder="Membership Name"
                value={membershipName}
                onChange={(e) => {
                  setMembershipName(e.target.value);
                }}
              />
              {validation.membershipName && (
                <Tooltip title="Membership Name is required">
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px]"
                    color="error"
                  />
                </Tooltip>
              )}
            </div>
          </div>
          <div className="right-col2">
            <div className="appoint-input-field">
              <input
                type="text"
                required
                style={{
                  border: validation.validity ? "1.5px solid red" : "",
                }}
                onBlur={() => {
                  if (validity === "") {
                    setValidation({
                      ...validation,
                      validity: true,
                    });
                  } else {
                    setValidation({
                      ...validation,
                      validity: false,
                    });
                  }
                }}
                placeholder="Validity (Duration)"
                value={validity}
                onChange={(e) => {
                  setValidity(e.target.value);
                }}
              />
              {validation.validity && (
                <Tooltip title="Validity is required">
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px]"
                    color="error"
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        {selectedServices &&
          selectedServices.map((service, index) => {
            return (
              <div
                className="appointment-fillup"
                id="memtype-field"
                key={service.id}
              >
                <div className="left-col1">
                  <div className="appoint-input-field" id="special-mbs-field">
                    <select
                      required
                      value={service.id || undefined}
                      // onChange={(e) => {
                      //   let temp = [...selectedServices];
                      //   temp[index].id = e.target.value;
                      //   setSelectedServices(temp);
                      // }}
                      style={
                        selectedServices[index].id === null
                          ? { color: "#9CA6BE" }
                          : { color: "black" }
                      }
                      onChange={(e) => handleServiceChange(e, index)}
                    >
                      <option value="payment-mode" selected disabled>
                        Service Allowed
                      </option>
                      {allServices &&
                        allServices.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.service_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="right-col2">
                  <div className="relative appoint-input-field">
                    <input
                      type="number"
                      required
                      style={{
                        border: validation.selectedServices
                          ? "1.5px solid red"
                          : "",
                      }}
                      onBlur={() => {
                        if (service.noOfService === "") {
                          setValidation({
                            ...validation,
                            selectedServices: true,
                          });
                        } else {
                          setValidation({
                            ...validation,
                            selectedServices: false,
                          });
                        }
                      }}
                      placeholder="No. Of Services"
                      value={service.noOfService}
                      // onChange={(e) => {
                      //   let temp = [...selectedServices];
                      //   temp[index].noOfService = e.target.value;
                      //   setSelectedServices(temp);
                      // }}
                      onChange={(e) => handleNoOfServiceChange(e, index)}
                    />
                    {validation.selectedServices && (
                      <Tooltip title="No. of Services is required">
                        <ErrorIcon
                          className="error-icon absolute right-[5px] bottom-[10px]"
                          color="error"
                        />
                      </Tooltip>
                    )}
                    {
                      <div className="m-t-delete-icon">
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            handleServiceDelete(index);
                          }}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </div>
                    }
                  </div>
                </div>
              </div>
            );
          })}

        <div className="appointment-fillup" id="memtype-field">
          <div className="left-col1">
            <div className="appoint-input-field">
              <input
                type="number"
                placeholder="Total Price"
                disabled
                value={totalPrice || ""}
              />
            </div>
          </div>
          <div className="right-col2">
            <div className="appoint-input-field">
              <input
                type="number"
                required
                style={{
                  border: validation.membershipPrice ? "1.5px solid red" : "",
                }}
                onBlur={() => {
                  if (membershipPrice === null) {
                    setValidation({
                      ...validation,
                      membershipPrice: true,
                    });
                  } else {
                    setValidation({
                      ...validation,
                      membershipPrice: false,
                    });
                  }
                }}
                placeholder="Membership Price"
                value={membershipPrice || ""}
                onChange={(e) => {
                  setMembershipPrice(e.target.value);
                }}
              />
              {validation.membershipPrice && (
                <Tooltip title="Membership Price is required">
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px]"
                    color="error"
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <div className="appoint-input-field" >
              <Tooltip title="Gender" placement="top" arrow>
              <select 
                required
                onBlur={() => {
                  
                }
                }
                onChange={(e) => {
                  setgender(e.target.value);
                }}
                style={
                  {
                    border: validation.selectedManager ? "1.5px solid red" : "",
                    color: gender === "" ? "#9CA6BE" : "black",
                  }
                }
              >
                <option value="" selected disabled hidden>
                  Select Gender
                </option>
               
                    <option  value={"M"}>
                    Male
                    </option>
                    <option  value={"F"}>
                    Female
                    </option>
               
              </select></Tooltip>
            </div>
        <div className="appointment-fillup" id="memtype-field">
          <div className="appoint-input-field" style={{ width: "100%" ,marginTop:"25px"}}>
            <input
              type="text"
              placeholder="Remarks"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </div>
        </div>

        <div className="add-membership-btn">
          <Button
            variant="contained"
            sx={{ backgroundColor: "#5573d0" }}
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => {
              handleAddService();
            }}
          >
            Add
          </Button>
        </div>
        <div className="appointment-form-btn">
          <button type="submit" onClick={handleSubmit}>
            Create Membership Type
          </button>
        </div>
      
      </div>
    </>
  );
};

export default MembershipType;
