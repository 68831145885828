import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AuthContext from "../../context/Auth";
import { Tooltip, Collapse } from "@mui/material";
import toast from "react-hot-toast";
import ErrorIcon from "@mui/icons-material/Error";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

function AppointmentForm(props) {

  var appointmentData = props?.appointmentData;
  const setAppointmentData = props.setAppointmentData;
  const navigate = useNavigate();
  const { authTokens } = useContext(AuthContext);
  const [roomsData, setRoomsData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [managersData, setManagersData] = useState([]);
  const [validation, setValidation] = useState({
    customer_name: false,
    customer_phone: false,
    service: false,
    duration: false,
    time_in: false,
    payment_mode: false,
    room: false,
    staff: false,
    amount_paid: false,
    manager_name: false,
  });
  const [serviceData, setServiceData] = useState([]);
  const date =
    props?.date ||
    appointmentData?.date ||
    new Date()
      .toLocaleString("en-CA", { timeZone: "Asia/Kolkata" })
      .slice(0, 10);
  const [customer, setCustomer] = useState(
    appointmentData?.customer_name || ""
  );
  const [contactNumber, setContactNumber] = useState(
    appointmentData?.customer_phone || ""
  );
  const [service, setService] = useState(appointmentData?.therapy || "");
  const [serviceType, setServiceType] = useState(
    appointmentData?.servicetype || ""
  );
  const [duration, setDuration] = useState(appointmentData?.duration || "");
  const [timeIn, setTimeIn] = useState(appointmentData?.time_in || "");
  const [selectedManager, setSelectedManager] = useState(
    appointmentData?.manager || ""
  );
  const [paymentMode, setPaymentMode] = useState(
    appointmentData?.payment_mode || ""
  );
  const [room, setRoom] = useState(appointmentData?.room || "");
  const [membership, setMembership] = useState(
    appointmentData?.membership || ""
  );
  const [membershipDetails, setMembershipDetails] = useState(null);
  const [staff, setStaff] = useState(appointmentData?.staff || "");
  const [amountPaid, setAmountPaid] = useState(
    appointmentData?.amount_paid || ""
  );
  const [totalAmount, setTotalAmount] = useState(null);
  const [offers, setOffers] = useState([]);
  const [showMembershipDetails, setShowMembershipDetails] = useState(false);

  // function to get rooms
  const getRooms = async () => {
    try {
      const response = await fetch(
        "https://trakky.in:8000/spavendor/rooms/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setRoomsData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function to get staff
  const getStaff = async () => {
    try {
      const response = await fetch(
        "https://trakky.in:8000/spavendor/staff/?exit=false", 
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setStaffData(data);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function to get services
  const getServices = async () => {
    try {
      const response = await fetch("https://trakky.in:8000/spas/service/", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access}`,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        setServiceData(data);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function to get offers
  const getOffers = async () => {
    try {
      const response = await fetch(
        "https://trakky.in:8000/spavendor/offers/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access}`,
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        setOffers(data);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function to get managers
  const getManagers = async () => {
    try {
      const response = await fetch(
        "https://trakky.in:8000/spavendor/manager/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setManagersData(data);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function to handle submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    const hasError = Object.values(validation).some((item) => item === true);
    if (hasError) {
      return;
    }

    let data = {
      date: date,
      customer_name: customer,
      customer_phone: contactNumber,
      duration: duration,
      time_in: dayjs(timeIn).format("HH:mm"),
      payment_mode: paymentMode,
      room: room,
      staff: staff,
      amount_paid: amountPaid,
      manager: selectedManager,
      appointment_amount: totalAmount,
    };
    if (membership) {
      data.membership = membershipDetails?.id;
    }
    if (serviceType === "service") {
      data.service = service;
    } else if (serviceType === "offer") {
      data.offer = service;
    }

    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/appointments/${
          appointmentData?.id ?? ""
        }${appointmentData ? "/" : ""}`,
        {
          method: !appointmentData ? "POST" : "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access}`,
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      if (result) {
        if (appointmentData) {
          setAppointmentData(result);
        } else {
          toast.success("Appointment Booked Successfully");
          navigate("/spa/appointment/appointment_card");
          // Reload the Page
          // window.location.reload();
        }
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  // function to check wherether customer has membership or not with mobile number
  const checkMembershipWithMobileNo = async (mobileNumber) => {
    if (mobileNumber.length !== 10) {
      return;
    }
    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/customer-membership/?phone=${mobileNumber}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setMembershipDetails(data[0]);
        setCustomer(data[0].customer_name);
        setMembership(data[0].membership_code);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function to check wherether customer has membership or not with membership number
  const checkMembershipWithMembershipNo = async (membershipNumber) => {
    if (membershipNumber.length !== 4) {
      return;
    }
    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/customer-membership/?code=${membershipNumber}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access}`,
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        setMembershipDetails(data[0]);
        setCustomer(data[0]?.customer_name);
        setContactNumber(data[0]?.customer_phone);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function convertToMinutesFormateOne(duration) {
    const [hours, minutes, seconds] = duration.split(":").map(Number);

    const totalMinutes = hours * 60 + minutes;

    return totalMinutes;
  }

  function convertToMinutesformatetwo(duration) {
    let totalMinutes = 0;

    const parts = duration?.split(" ");

    for (let i = 0; i < parts?.length; i++) {
      const value = parseInt(parts[i]);

      if (!isNaN(value)) {
        if (parts[i + 1] === "hour" || parts[i + 1] === "hours") {
          totalMinutes += value * 60;
        } else if (
          parts[i + 1] === "min" ||
          parts[i + 1] === "minutes" ||
          parts[i + 1] === "minute"
        ) {
          totalMinutes += value;
        }
      }
    }

    return totalMinutes;
  }


  const calculateTotalAmount = () => {
    let totalAmount = appointmentData?.total_amount;


    if (serviceType === "service") {
      let service_item = serviceData?.find(
        (item) => item.id === parseInt(service)
      );
      let durationminut = convertToMinutesFormateOne(duration);
      let durationminut2 = convertToMinutesformatetwo(
        service_item?.service_time
      );
      totalAmount =
        parseInt(service_item?.price) * (durationminut / durationminut2);
    } else if (serviceType === "offer") {
      let offer_item = offers?.find((item) => item.id === parseInt(service));
      let durationMin = convertToMinutesFormateOne(duration);
      let durationMin2 = convertToMinutesFormateOne(offer_item?.duration);
      totalAmount = parseInt(offer_item?.price) * (durationMin / durationMin2);
    }

    setTotalAmount(Math.round(totalAmount));
  };

  useEffect(() => {
    if (service && duration) {
      calculateTotalAmount();
    }
  }, [service, duration, serviceType , serviceData , offers]);


  useEffect(() => {
    getRooms();
    getStaff();
    getServices();
    getOffers();
    getManagers();
  }, []);

  useEffect(() => {
    // Convert time in to date object
    console.log("Time : ", timeIn , dayjs(timeIn).format("HH:mm"));
  }, [timeIn]);

  return (
    <div className="appointment-page-container bg-[#f0ecff]">
      <div className="appointment-room-container">
        {roomsData.length > 0 && (
          <div className="appointment-scroll-room-div">
            <div className="appointment-room-a">
              {roomsData?.map((room, index) => {
                return (
                  <div className="a-room-item" key={index}>
                    <h4>{room.roomname}</h4>
                    <p style={!room.is_occupied ? { color: "#04D000" } : {}}>
                      {room.is_occupied ? "Busy" : "Free"}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="appointment-room-container">
        {offers.length > 0 && (
          <div className="appointment-scroll-room-div">
            <div className="appointment-room-a">
              {offers?.map((offer, index) => {
                return (
                  offer?.enabled && (
                    <div className="a-room-item" key={index}>
                      <h4>{offer.offername}</h4>
                      <p>{offer.price}</p>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        )}
      </div>

      <div className="Appointment-page-form">
        <div className="appointment-form-left">
          <form onSubmit={handleSubmit}>
            <div className="appointment-fillup">
              <div className="left-col1">
                <div className="appoint-input-field">
                  <Tooltip title="Customer Name" placement="top" arrow>
                    <input
                      type="text"
                      required
                      style={{
                        border: `${
                          validation.customer_name ? "1.5px solid red" : ""
                        }`,
                      }}
                      placeholder="Customer Name"
                      value={customer}
                      onBlur={(e) => {
                        if (customer === "") {
                          setValidation((val) => {
                            return { ...val, customer_name: true };
                          });
                        } else {
                          setValidation((val) => {
                            return { ...val, customer_name: false };
                          });
                        }
                      }}
                      onChange={(e) => {
                        setCustomer(e.target.value);
                      }}
                    />
                  </Tooltip>
                  {validation.customer_name && (
                    <Tooltip title="Customer Name is required">
                      <ErrorIcon
                        className="error-icon absolute right-[5px] bottom-[10px] hidden"
                        color="error"
                      />
                    </Tooltip>
                  )}
                </div>
                <div className="appoint-input-field relative">
                  <Tooltip title="Customer Number" placement="top" arrow>
                    <input
                      style={{
                        border: `${
                          validation.customer_phone ? "1.5px solid red" : ""
                        }`,
                      }}
                      type="number"
                      required
                      placeholder="Customer Number"
                      value={contactNumber}
                      onBlur={(e) => {
                        if (contactNumber === "" || contactNumber.length < 10) {
                          setValidation((val) => {
                            return { ...val, customer_phone: true };
                          });
                        } else {
                          setValidation((val) => {
                            return { ...val, customer_phone: false };
                          });
                        }
                      }}
                      onChange={(e) => {
                        setContactNumber((val) => {
                          if (e.target.value.length > 10) {
                            return val;
                          } else if (e.target.value.length < 10) {
                            return e.target.value;
                          } else {
                            return e.target.value;
                          }
                        });
                        checkMembershipWithMobileNo(e.target.value);
                      }}
                    />
                  </Tooltip>
                  {validation.customer_phone && (
                    <Tooltip title="Atleast 10 digits are required">
                      <ErrorIcon
                        className="error-icon absolute right-[5px] bottom-[10px] hidden"
                        color="error"
                      />
                    </Tooltip>
                  )}
                </div>
                <div className="appoint-input-field">
                  <Tooltip title="Service Name" placement="top" arrow>
                    <select
                      required
                      style={{
                        border: `${
                          validation.service ? "1.5px solid red" : ""
                        }`,
                      }}
                      value={service || "Service-Name"}
                      onBlur={(e) => {
                        if (service === "") {
                          setValidation((val) => {
                            return { ...val, service: true };
                          });
                        } else {
                          setValidation((val) => {
                            return { ...val, service: false };
                          });
                        }
                      }}
                      onChange={(e) => {
                        setService(e.target.value);
                        setServiceType(
                          e.target.selectedOptions[0].attributes.servicetype
                            .value
                        );
                      }}
                    >
                      <option value="Service-Name" selected hidden disabled>
                        Service Name
                      </option>
                      {offers.length > 0 && (
                        <optgroup label="offer name">
                          {offers?.map((offer) => {
                            return (
                              offer?.enabled && (
                                <option
                                  value={offer.id}
                                  key={offer.id}
                                  servicetype={"offer"}
                                >
                                  {offer.offername}
                                </option>
                              )
                            );
                          })}
                        </optgroup>
                      )}
                      <optgroup label="service name">
                        {serviceData?.map((service) => {
                          return (
                            <option
                              value={service.id}
                              key={service.id}
                              servicetype={"service"}
                            >
                              {service.service_name}
                            </option>
                          );
                        })}
                      </optgroup>
                    </select>
                  </Tooltip>
                </div>
                <div className="appoint-input-field">
                  <Tooltip title="Service Duration" placement="top" arrow>
                    <select
                      value={duration || "Select-duration"}
                      style={{
                        border: `${
                          validation.duration ? "1.5px solid red" : ""
                        }`,
                        ...(duration === ""
                          ? { color: "#9CA3B7" }
                          : { color: "black" }),
                      }}
                      onBlur={(e) => {
                        if (duration === "") {
                          setValidation((val) => {
                            return { ...val, duration: true };
                          });
                        } else {
                          setValidation((val) => {
                            return { ...val, duration: false };
                          });
                        }
                      }}
                      onChange={(e) => setDuration(e.target.value)}
                      required
                    >
                      <option value="Select-duration" selected disabled>
                        Select Duration
                      </option>
                      <option value="00:30:00">30 min</option>
                      <option value="00:45:00">45 min</option>
                      <option value="00:60:00">60 min</option>
                      <option value="01:30:00">90 min</option>
                      <option value="02:00:00">120 min</option>
                    </select>
                  </Tooltip>
                </div>
                <div className="appoint-input-field">
                  <Tooltip title="Total Amount" placement="top" arrow>
                    <input
                      type="number"
                      placeholder="Total Amount"
                      required
                      value={totalAmount}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => setTotalAmount(e.target.value)}
                    />
                  </Tooltip>
                </div>
                <div className="appoint-input-field">
                  <Tooltip title="Paymment Mode" placement="top" arrow>
                    <select
                      value={paymentMode || "payment-mode"}
                      style={{
                        border: `${
                          validation.payment_mode ? "1.5px solid red" : ""
                        }`,
                        ...(paymentMode === ""
                          ? { color: "#9CA3B7" }
                          : { color: "black" }),
                      }}
                      onBlur={(e) => {
                        if (paymentMode === "") {
                          setValidation((val) => {
                            return { ...val, payment_mode: true };
                          });
                        } else {
                          setValidation((val) => {
                            return { ...val, payment_mode: false };
                          });
                        }
                      }}
                      onChange={(e) => setPaymentMode(e.target.value)}
                      required
                    >
                      <option value="payment-mode" selected disabled>
                        Payment Mode
                      </option>
                      <option value="UPI">UPI</option>
                      <option value="Net Banking">Net Banking</option>
                      <option value="Debit Card">Debit Card</option>
                      <option value="Credit Card">Credit Card</option>
                      <option value="Cash">Cash</option>
                      <option value="Other">Other</option>
                    </select>
                  </Tooltip>
                </div>
              </div>
              <div className="right-col2">
                <Tooltip title="Time In" placement="top" arrow>
                <div
                  className="appoint-input-field"
                  onBlur={(e) => {
                    if (timeIn === "") {
                      setValidation((val) => {
                        return { ...val, time_in: true };
                      });
                    } else {
                      setValidation((val) => {
                        return { ...val, time_in: false };
                      });
                    }
                  }}
                  style={{
                    outline: `${
                      validation.time_in ? "1.5px solid red" : ""
                    }`,
                    borderRadius: "5px",
                  }}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        autoFocus={false}
                        value={timeIn}
                        onChange={(newValue) => setTimeIn(newValue)}
                        className="custom-time-picker"
                        sx={{
                          width: "100%",
                          height: "45px",
                          overflow: "hidden",
                          borderRadius: "5px",
                          borderColor: "transparent",
                          cursor: "pointer",
                          borderRadius: "5px",
                          "& .MuiInputBase-root": {
                            height: "45px",
                            overflow: "hidden",
                            borderRadius: "5px",
                            cursor: "pointer",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      />
                    </LocalizationProvider>
                    {/* <input type="time" onChange={(e) => setTimeIn(e.target.value)} /> */}
                  {validation.time_in && (
                    <Tooltip title="Time in is required">
                      <ErrorIcon
                        className="error-icon absolute right-[35px] bottom-[11px] hidden"
                        color="error"
                      />
                    </Tooltip>
                  )}
                </div>
                </Tooltip>
                <div className="appoint-input-field">
                  <Tooltip title="Room" placement="top" arrow>
                    <select
                      value={room || "Service-Room"}
                      style={{
                        border: `${validation.room ? "1.5px solid red" : ""}`,
                        ...(room === ""
                          ? { color: "#9CA3B7" }
                          : { color: "black" }),
                      }}
                      onBlur={(e) => {
                        if (room === "") {
                          setValidation((val) => {
                            return { ...val, room: true };
                          });
                        } else {
                          setValidation((val) => {
                            return { ...val, room: false };
                          });
                        }
                      }}
                      required
                      onChange={(e) => setRoom(e.target.value)}
                    >
                      <option value="Service-Room" selected disabled>
                        Select Room
                      </option>
                      {roomsData?.map((room) => {
                        return (
                          <option
                            value={room.id}
                            key={room.id}
                            disabled={room.is_occupied || false}
                          >
                            {room.roomname}
                          </option>
                        );
                      })}
                    </select>
                  </Tooltip>
                </div>
                <div className="appoint-input-field">
                  <Tooltip title="Membership Number" placement="top" arrow>
                    <input
                      type="text"
                      placeholder="Membership Number"
                      value={membership}
                      onChange={(e) => {
                        setMembership(e.target.value);
                        checkMembershipWithMembershipNo(e.target.value);
                      }}
                    />
                    <div className="appoint-inp-icon">
                      <AddIcon />
                    </div>
                  </Tooltip>
                </div>
                <div className="appoint-input-field">
                  <Tooltip title="Staff Name" placement="top" arrow>
                    <select
                      value={staff || "staff-Name"}
                      style={{
                        border: `${validation.staff ? "1.5px solid red" : ""}`,
                      }}
                      onBlur={(e) => {
                        if (staff === "") {
                          setValidation((val) => {
                            return { ...val, staff: true };
                          });
                        } else {
                          setValidation((val) => {
                            return { ...val, staff: false };
                          });
                        }
                      }}
                      onChange={(e) => setStaff(e.target.value)}
                      required
                      // style={staff === "" ? { color: "#9CA3B7" } : {color : 'black'}}
                    >
                      <option value="staff-Name" selected disabled>
                        Staff Name
                      </option>
                      {staffData?.map((staff) => {
                        return (
                          <option
                            value={staff.id}
                            disabled={staff.is_busy || false}
                            key={staff.id}
                          >
                            {staff.staffname}
                          </option>
                        );
                      })}
                    </select>
                  </Tooltip>
                </div>
                <div className="appoint-input-field">
                  <Tooltip title="Amount Paid" placement="top" arrow>
                    <input
                      type="number"
                      placeholder="Amount Paid"
                      required
                      value={amountPaid}
                      onWheel={(e) => e.target.blur()}
                      style={{
                        border: `${
                          validation.amount_paid ? "1.5px solid red" : ""
                        }`,
                      }}
                      onBlur={(e) => {
                        if (
                          amountPaid === "" ||
                          parseFloat(amountPaid) > parseFloat(totalAmount)
                        ) {
                          setValidation((val) => ({
                            ...val,
                            amount_paid: true,
                          }));
                        } else {
                          setValidation((val) => ({
                            ...val,
                            amount_paid: false,
                          }));
                        }
                      }}
                      onChange={(e) => {
                        setAmountPaid(e.target.value);
                      }}
                    />
                  </Tooltip>
                  {validation.amount_paid && (
                    <Tooltip
                      title="Amount Paid is required and cannot exceed Total Amount"
                      placement="top"
                    >
                      <ErrorIcon
                        className="error-icon absolute right-[5px] bottom-[10px] hidden"
                        color="error"
                      />
                    </Tooltip>
                  )}
                </div>
                <div className="appoint-input-field">
                  <Tooltip title="Manager Name" placement="top" arrow>
                    <select
                      value={selectedManager || "Select Manager"}
                      onBlur={(e) => {
                        if (selectedManager === "") {
                          setValidation((val) => {
                            return { ...val, manager_name: true };
                          });
                        } else {
                          setValidation((val) => {
                            return { ...val, manager_name: false };
                          });
                        }
                      }}
                      onChange={(e) => setSelectedManager(e.target.value)}
                      required
                      style={{
                        border: `${
                          validation.manager_name ? "1.5px solid red" : ""
                        }`,
                        ...(selectedManager === "" ? { color: "#9CA3B7" } : {}),
                      }}
                    >
                      <option value="Select Manager" selected disabled>
                        Select Manager
                      </option>
                      {managersData?.map((manager) => {
                        return (
                          <option value={manager.id} key={manager.id}>
                            {manager.managername}
                          </option>
                        );
                      })}
                    </select>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="appointment-form-btn">
              <button type="submit">Book Now</button>
            </div>
          </form>
        </div>
        <div className="appointment-form-right">
          <div className="staff-available-data">
            <h4>Available Staff</h4>
            <div className="staff-data">
              <table>
                {staffData?.map((staff) => {
                  return (
                    <tr key={staff.id}>
                      <td>{staff.staffname}</td>
                      <td style={!staff.is_busy ? { color: "#04D000" } : {}}>
                        {staff.is_busy ? "Busy" : "Free"}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
          <div className="staff-available-data">
            <div
              className="membership-header "
              onClick={() => setShowMembershipDetails(!showMembershipDetails)}
            >
              <h4 className="membership-title">
                Membership{" "}
                {membershipDetails && " - " + membershipDetails.package_name}
              </h4>
              <KeyboardArrowUpIcon
                sx={{
                  transition: "all 0.2s ease-in-out",
                  transform: `${showMembershipDetails ? "" : "rotate(180deg)"}`,
                }}
              />
            </div>
            <Collapse in={showMembershipDetails}>
              {membershipDetails ? (
                <div className="md-details-container">
                  <table className="md-table">
                    <tr className="md-remaining-services">
                      <td className="md-first-row">Remaining Services </td>
                      <td className="md-value md-first-row">
                        {membershipDetails.num_services_allowed}
                      </td>
                    </tr>
                    <tr className="md-valid-till">
                      <td className="md-last-row">Valid Till </td>
                      <td className="md-value md-last-row">
                        {membershipDetails.duration === 999 ? "Lifetime" : membershipDetails.valid_till}
                      </td>
                    </tr>
                  </table>
                  <div className="md-services-wrapper">
                    {membershipDetails?.services?.map((service) => {
                      return (
                        <div className="md-service">{service.service_name}</div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="md-details-container">
                  <h4 className="md-no-membership">No Membership</h4>
                </div>
              )}
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppointmentForm;
