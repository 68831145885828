import React, { useContext, useEffect, useState } from "react";
import id_proof from "../svg/id_proof.svg";
import AuthContext from "../../context/Auth";
import "../css/staffmanagement.css";
import { Tooltip } from "@mui/material";
import toast from 'react-hot-toast';

import ErrorIcon from "@mui/icons-material/Error";

function StaffForm() {
  const { authTokens } = useContext(AuthContext);

  const [type, setType] = useState("text");
  const [validation, setValidation] = useState({
    contactNumber: false,
    joiningDate: false,
    staffName: false,
    salary: false,
    address: false,
    paymentMode: false,
    idProof: false,
    availableTillDate: false,
    isPermanent: false,
    amountPaid: false,
    expertise: false,
  });
  const [availableTillDateType, setAvailableTillDateType] = useState("text");
  const [staffName, setStaffName] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [salary, setSalary] = useState("");
  const [address, setAddress] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [idProof, setIdProof] = useState("");
  const [availableTillDate, setAvailabileTillDate] = useState("");
  const [isPermanent, setIsPermanent] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [expertise, setExpertise] = useState("");

  const [therapies, setTherapies] = useState([]);
  useEffect(() => {
    fetch("https://trakky.in:8000/spas/therapy/")
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setTherapies(data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const hasError = Object.values(validation).some((val) => val === true);
    if (hasError) {
      return;
    }
    let data = new FormData();
    data.append("staffname", staffName);
    data.append("joining_date", joiningDate);
    data.append("ph_number", contactNumber);
    data.append("salary", salary);
    data.append("address", address);
    data.append("payment_mode", paymentMode);
    data.append("id_proof", idProof);
    data.append("exit_date", availableTillDate);
    data.append("is_permanent", isPermanent);
    data.append("amount_paid", amountPaid);
    data.append("expertise", expertise);
    fetch("https://trakky.in:8000/spavendor/staff/", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authTokens?.access}`,
      },

      body: data,
    })
      .then((res) => {
        if (res.ok) {
          toast.success('Staff Added Successfully');
          return res.json();
        } else {
          toast.error('Error in Adding Staff');
        }
      })
  };

  return (
    <div className="staff-register-form">
      <form onSubmit={handleSubmit}>
        <div className="appointment-fillup">
          <div className="left-col1">
            <div className="appoint-input-field">
              <input
                type="text"
                required
                placeholder="Staff Name"
                value={staffName}
                style={{
                  border: `${validation.staffName ? "1.5px solid red" : ""}`,
                }}
                onBlur={() => {
                  if (staffName === "") {
                    setValidation((val) => {
                      return { ...val, staffName: true };
                    });
                  } else {
                    setValidation((val) => {
                      return { ...val, staffName: false };
                    });
                  }
                }}
                onChange={(e) => {
                  setStaffName(e.target.value);
                }}
              />
              {validation.staffName && (
                <Tooltip title="Staff Name is required" arrow>
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px]"
                    color="error"
                  />
                </Tooltip>
              )}
            </div>
          </div>
          <div className="right-col2">
            <div className="appoint-input-field">
              <input
                placeholder="Joining Date"
                type={type}
                required
                value={joiningDate}
                style={{
                  border: `${validation.joiningDate ? "1.5px solid red" : ""}`,
                }}
                onFocus={() => setType("date")}
                onBlur={() => {
                  if (joiningDate === "") {
                    setType("text");
                    setValidation((val) => {
                      return { ...val, joiningDate: true };
                    });
                  } else {
                    setType("text");
                    setValidation((val) => {
                      return { ...val, joiningDate: false };
                    });
                  }
                }}
                onChange={(e) => setJoiningDate(e.target.value)}
                id="joiningdate"
              />
              {validation.joiningDate && (
                <Tooltip title="Joining Date is required" arrow>
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px]"
                    color="error"
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </div>

        <div className="appointment-fillup-address">
          <div className="appoint-input-field">
            <input
              type="text"
              placeholder="Address"
              required
              value={address}
              style={{
                border: `${validation.address ? "1.5px solid red" : ""}`,
              }}
              onBlur={() => {
                if (address === "") {
                  setValidation((val) => {
                    return { ...val, address: true };
                  });
                } else {
                  setValidation((val) => {
                    return { ...val, address: false };
                  });
                }
              }}
              onChange={(e) => setAddress(e.target.value)}
            />
            {validation.address && (
              <Tooltip title="Address is required" arrow>
                <ErrorIcon
                  className="error-icon absolute right-[5px] bottom-[10px]"
                  color="error"
                />
              </Tooltip>
            )}
          </div>
        </div>
        <div className="appointment-fillup-phone pb-[20px]">
        <div className="appoint-input-field relative ">
              <input
                type="number"
                placeholder="Staff Phone Number"
                required
                value={contactNumber}
                style={{
                  border: `${
                    validation.contactNumber ? "1.5px solid red" : ""
                  }`,
                }}
                onBlur={() => {
                  if (contactNumber === "" || contactNumber.length !== 10) {
                    setValidation((val) => {
                      return { ...val, contactNumber: true };
                    });
                  } else {
                    setValidation((val) => {
                      return { ...val, contactNumber: false };
                    });
                  }
                }}
                onChange={(e) => {
                  setContactNumber((val) => {
                    if (e.target.value.length > 10) {
                      return val;
                    } else if (e.target.value.length < 10) {
                      return e.target.value;
                    } else {
                      return e.target.value;
                    }
                  });
                }}
              />
              {validation.contactNumber && (
                <Tooltip title="Atleast 10 digits are required" arrow>
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px]"
                    color="error"
                  />
                </Tooltip>
              )}
            </div>
        </div>

        <div className="appointment-fillup">
          <div className="left-col1">
          
            <div className="appoint-input-field">
              <input
                type="number"
                required
                style={{
                  border: `${validation.salary ? "1.5px solid red" : ""}`,
                }}
                placeholder="Salary"
                value={salary}
                onBlur={() => {
                  if (salary === "") {
                    setValidation((val) => {
                      return { ...val, salary: true };
                    });
                  } else {
                    setValidation((val) => {
                      return { ...val, salary: false };
                    });
                  }
                }}
                onChange={(e) => {
                  setSalary(e.target.value);
                }}
              />
              {validation.salary && (
                <Tooltip title="Salary is required" arrow>
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px]"
                    color="error"
                  />
                </Tooltip>
              )}
            </div>
            <div className="appoint-input-field">
              <select
                required
                value={paymentMode || "payment-mode"}
                style={{
                  border: `${validation.paymentMode ? "1.5px solid red" : ""}`,
                }}
                onBlur={() => {
                  if (paymentMode === "") {
                    setValidation((val) => {
                      return { ...val, paymentMode: true };
                    });
                  } else {
                    setValidation((val) => {
                      return { ...val, paymentMode: false };
                    });
                  }
                }}
                onChange={(e) => {
                  setPaymentMode(e.target.value);
                }}
              >
                <option value="payment-mode" selected disabled>
                  Payment Mode
                </option>
                <option value="UPI">UPI</option>
                <option value="Net Banking">Net Banking</option>
                <option value="Debit Card">Debit Card</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="appoint-input-field">
              <input
                type="file"
                required
                style={{
                  border: `${validation.idProof ? "1.5px solid red" : ""}`,
                }}
                onBlur={() => {
                  if (!idProof) {
                    setValidation((val) => {
                      return { ...val, idProof: true };
                    });
                  } else {
                    setValidation((val) => {
                      return { ...val, idProof: false };
                    });
                  }
                }}
                onChange={(e) => setIdProof(e.target.files[0])}
              />
              <img src={id_proof} alt="id proof" className="appoint-inp-icon" />
            </div>
          </div>
          <div className="right-col2">
            {/* <div className="appoint-input-field">
              <input
                placeholder="Leaving Date"
                
                style={{
                  border: `${
                    validation.availableTillDate ? "1.5px solid red" : ""
                  }`,
                }
                }
                type='date'
                value={availableTillDate}
               
                onChange={(e) => setAvailabileTillDate(e.target.value)}
                id="joiningdate"
              />
              {validation.availableTillDate && (
                <Tooltip title="Availablity Time is required" arrow>
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px]"
                    color="error"
                  />
                </Tooltip>
              )}
            </div> */}
            <div className="appoint-input-field">
              <select
                value={isPermanent || "Status"}
                required
                style={{
                  border: `${validation.isPermanent ? "1.5px solid red" : ""}`,
                }}
                onBlur={() => {
                  if (isPermanent === "") {
                    setValidation((val) => {
                      return { ...val, isPermanent: true };
                    });
                  } else {
                    setValidation((val) => {
                      return { ...val, isPermanent: false };
                    });
                  }
                }}
                onChange={(e) => {
                  setIsPermanent(e.target.value);
                }}
              >
                <option value="Status" selected disabled>
                  Status
                </option>
                <option value="true">Permanent</option>
                <option value="false">Temporary</option>
              </select>
            </div>

            <div className="appoint-input-field">
              <input
                type="number"
                required
                style={{
                  border: `${validation.amountPaid ? "1.5px solid red" : ""}`,
                }}
                onBlur={() => {
                  if (amountPaid === "") {
                    setValidation((val) => {
                      return { ...val, amountPaid: true };
                    });
                  } else {
                    setValidation((val) => {
                      return { ...val, amountPaid: false };
                    });
                  }
                }}
                placeholder="Amount Paid"
                value={amountPaid}
                onChange={(e) => setAmountPaid(e.target.value)}
              />
              {validation.amountPaid && (
                <Tooltip title="Amount Paid is required" arrow>
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px]"
                    color="error"
                  />
                </Tooltip>
              )}
            </div>

            <div className="appoint-input-field">
              <select
                value={expertise || "Expertise"}
                required
                style={{
                  border: `${validation.expertise ? "1.5px solid red" : ""}`,
                }}
                onBlur={() => {
                  if (expertise === "") {
                    setValidation((val) => {
                      return { ...val, expertise: true };
                    });
                  } else {
                    setValidation((val) => {
                      return { ...val, expertise: false };
                    });
                  }
                }}
                onChange={(e) => {
                  setExpertise(e.target.value);
                }}
              >
                <option value="Expertise" selected disabled>
                  Expertise
                </option>
                {therapies.map((therapy) => {
                  return <option value={therapy.id}>{therapy.name}</option>;
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="appointment-form-btn">
          <button type="submit">
            Register Staff
          </button>
        </div>
      </form>

    </div>
  );
}

export default StaffForm;
