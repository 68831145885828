import React, { useContext, useState } from "react";
import AuthContext from "../../context/Auth";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip } from "@mui/material";

function OfferForm({ offerData, setOfferData, closeModal }) {
  const { authTokens } = useContext(AuthContext);
  const navigate = useNavigate();

  const [offerName, setOfferName] = useState(offerData?.offername || "");
  const [offerDuration, setOfferDuration] = useState(offerData?.duration || "");
  const [offerPrice, setOfferPrice] = useState(offerData?.price || "");
  const [offerDescription, setOfferDescription] = useState(
    offerData?.description || ""
  );

  const [validation, setValidation] = useState({
    offerName: false,
    offerDuration: false,
    offerPrice: false,
    offerDescription: false,
  });

  const submitHandler = (e) => {
    e.preventDefault();

    const newValidation = {
      offerName: offerName === "",
      offerDuration: offerDuration === "Select-duration",
      offerPrice: offerPrice === "",
      offerDescription: offerDescription === "",
    };

    setValidation(newValidation);

    if (Object.values(newValidation).some((item) => item)) {
      return;
    }

    let offer = {
      offername: offerName,
      duration: offerDuration,
      price: offerPrice,
      description: offerDescription,
    };

    if (!offerData) {
      fetch(`https://trakky.in:8000/spavendor/offers/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(offer),
      })
        .then((res) => {
          if (res.ok) {
            navigate("/spa/offermanagement/offertable");
            toast.success("Offer Added Successfully");
            return res.json();
          } else {
            toast.error("Something went wrong 1");
          }
        })
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong 2");
        });
    } else {
      fetch(`https://trakky.in:8000/spavendor/offers/${offerData.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(offer),
      })
        .then((res) => {
          if (res.ok) {
            toast.success("Offer Updated Successfully");
            return res.json();
          } else {
            toast.error("Something went wrong 11");
          }
        })
        .then((data) => {
          setOfferData(data);
          closeModal();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong 22");
        });
    }
  };

  return (
    <>
      <form className="ms-register-form" onSubmit={submitHandler}>
        <div className="appointment-fillup">
          <div className="left-col1">
            <div className="appoint-input-field">
              <input
                placeholder="Offer Name"
                style={{
                  border: validation.offerName ? "1.5px solid red" : "",
                }}
                required
                onBlur={() => {
                  if (offerName === "") {
                    setValidation({ ...validation, offerName: true });
                  } else {
                    setValidation({ ...validation, offerName: false });
                  }
                }}
                type="text"
                value={offerName}
                onChange={(e) => {
                  setOfferName(e.target.value);
                }}
              />
              {validation.offerName && (
                <Tooltip title="Offer Name is required" placement="top">
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px] hidden"
                    color="error"
                  />
                </Tooltip>
              )}
            </div>
          </div>
          <div className="middle-col2">
            <div className="appoint-input-field">
              <select
                value={offerDuration || "Select-duration"}
                style={{
                  border: validation.offerDuration ? "1.5px solid red" : "",
                }}
                onBlur={() => {
                  if (offerDuration === "") {
                    setValidation({ ...validation, offerDuration: true });
                  } else {
                    setValidation({ ...validation, offerDuration: false });
                  }
                }
                }
                onChange={(e) => setOfferDuration(e.target.value)}
                required
              >
                <option value="Select-duration" disabled>
                  Select Duration
                </option>
                <option value="00:30:00">30 min</option>
                <option value="00:45:00">45 min</option>
                <option value="00:60:00">60 min</option>
                <option value="01:30:00">90 min</option>
                <option value="02:00:00">120 min</option>
              </select>
            </div>
          </div>
          <div className="right-col3">
            <div className="appoint-input-field">
              <input
                placeholder="Price"
                type="number"
                style={{
                  border: validation.offerPrice ? "1.5px solid red" : "",
                }}
                required
                onBlur={() => {
                  if (offerPrice === "") {
                    setValidation({ ...validation, offerPrice: true });
                  } else {
                    setValidation({ ...validation, offerPrice: false });
                  }
                }}
                value={offerPrice}
                onChange={(e) => {
                  setOfferPrice(e.target.value);
                }}
              />
              {validation.offerPrice && (
                <Tooltip title="Price is required" placement="top">
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px] hidden"
                    color="error"
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <div className="appoint-input-field address-field" style={{ height: "200px" }}>
          <textarea
            style={{
              width: "100%",
              height: "100%",
              padding: "10px",
              resize: "none",
              border: validation.offerDescription ? "1.5px solid red" : "",
            }}
            placeholder="Description"
            required
            onBlur={() => {
              if (offerDescription === "") {
                setValidation({ ...validation, offerDescription: true });
              } else {
                setValidation({ ...validation, offerDescription: false });
              }
            }}
            value={offerDescription}
            onChange={(e) => {
              setOfferDescription(e.target.value);
            }}
          ></textarea>
          {validation.offerDescription && (
            <Tooltip title="Description is required" placement="top">
              <ErrorIcon
                className="error-icon absolute right-[5px] bottom-[10px] hidden"
                color="error"
              />
            </Tooltip>
          )}
        </div>
        <div className="appointment-form-btn">
          <button type="submit">
            {offerData ? "Update Offer" : "Add Offer"}
          </button>
        </div>
      </form>
    </>
  );
}

export default OfferForm;
