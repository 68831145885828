// Import necessary modules and assets
import React, { useState, useContext, useEffect } from 'react'; // Import React hooks and context API
import './css/login.css'; // Import CSS styles
import usernameImg from './images/username.png'; // Import images
import passwordImg from './images/password.png';
import AuthContext from '../context/Auth'; // Import authentication context
import logo from "./svg/logo_white.png"; // Import SVG logo
import trakky_logo from "../Trakky logo purple.png"; // Import Trakky logo
import toast from 'react-hot-toast'; // Import toast notifications library

// Define the Login functional component
const Login = () => {
    // Use the useContext hook to access the AuthContext
    const { loginUser, logoutUser } = useContext(AuthContext);

    // Initialize state variables for username and password
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    // Use the useEffect hook to call logoutUser whenever it changes
    useEffect(() => {
      logoutUser();
    }, [logoutUser]);

    // Define the handleSubmit function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior

        try {
            // Use toast.promise to display different messages based on the result of loginUser

             console.log(username + " " + password);

            // await toast.promise(loginUser(username, password), {
            //     loading: 'Logging in...',
            //     success: (data) => {
            //         return 'Logged in successfully!';
            //     },
            //     error: "Invalid Credentials"
            // });

            await loginUser({ phone_number: username, password: password });
            
            // You can add navigation or other actions after successful login here
        } catch (error) {
            console.error(error); // Log errors to the console
            toast.error("An unexpected error occurred."); // Display an error message using react-hot-toast
        }
    };

    // Return the JSX structure of the component
    return (
        <>
            <div className="login-container flex flex-row w-screen h-screen justify-center items-center">
                {/* Image section for displaying logos */}
                <div className='login-img w-1/2 lg:flex hidden h-full flex-col justify-center items-center'>
                    <div className='w-[80%] h-[20%] flex justify-center items-center'>
                        <img src={logo} alt="login_icon" className='w-[50%]' /> {/* Logo image */}
                    </div>
                    <div className='anim w-[80%] h-[60%] flex justify-center items-center'>
                    </div>
                </div>

                {/* Form section for user login */}
                <div className='login-form flex flex-col w-1/2 h-full justify-start lg:justify-center items-center px-[10px]'>
                    {/* Mobile view logo */}
                    <div className='login-form-img w-[80%] h-[20%] lg:hidden flex justify-center items-center'>
                        <img src={trakky_logo} alt="login_icon" className='w-[200px]' /> {/* Trakky logo */}
                    </div>

                    {/* Login form */}
                    <form action='' method='post' className='login-form flex flex-col justify-center items-center gap-4' onSubmit={handleSubmit}>
                        {/* Heading */}
                        <div className='heading-box mb-4'>
                            <h3 className='heading text-2xl'>Sign in to your account</h3>
                            <span className='login-subheading text-xs text-slate-800 opacity-50'>You can Login here for your dashboard</span>
                        </div>

                        {/* Username input field */}
                        <div className='inp-username relative w-full leading-10 border-b-[1px] border-black'>
                            <input type='text' name='username' placeholder='Phone Number / Username' className='input w-full pl-9 focus:outline-none' value={username} onChange={(e) => setUsername(e.target.value)} required />
                            <img src={usernameImg} alt='username-img' width={"18px"} height={'18px'} className='username-img absolute top-2.5 left-1.5'/> {/* Username icon */}
                        </div>

                        {/* Password input field */}
                        <div className='inp-password relative w-full leading-10 mb-1 border-b-[1px] border-black'>
                            <input type='password' name='password' placeholder='Password' className='input w-full pl-9 focus:outline-none' value={password} onChange={(e) => setPassword(e.target.value)} required />
                            <img src={passwordImg} alt='password-img' width={"18px"} height={'18px'} className='password-img absolute top-2.5 left-1.5'/> {/* Password icon */}
                        </div>

                        {/* Additional facilities like remember me checkbox and forgot password link */}
                        <div className='extra-facility flex justify-between w-full mb-5'>
                            <div className='remember-me flex justify-center items-center gap-1'>
                                <input type='checkbox' id='remember-me' className='checkbox'/> {/* Remember me checkbox */}
                                <label htmlFor='remember-me' className='remember-me-label'>Remember me</label>
                            </div>
                            <div className='forgot-password'>Forgot Password</div> {/* Forgot password link */}
                        </div>

                        {/* Sign-in button */}
                        <div className='btn w-full flex justify-center items-center'>
                            <button type='submit' className='signin-btn w-full leading-9 rounded-lg bg-green-500 text-white'>Sign in</button>
                        </div>

                        {/* Or section for social logins */}
                        <div className='or-section flex flex-row justify-center items-center gap-4'>
                            <div className='or-line'></div>
                            <div className='or'>or</div>
                            <div className='or-line'></div>
                        </div>

                        {/* Register button */}
                        <div className='btn w-full flex justify-center items-center'>
                            <button type='button' className='register-btn w-full leading-9 rounded-lg text-white bg-blue-700' onClick={() => window.location.href='https://aboutus.trakky.in/'}>Register Salon</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

// Export the Login component
export default Login;
