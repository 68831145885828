import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/Auth";
import "../css/membership.css";
import ErrorIcon from "@mui/icons-material/Error";
import toast from "react-hot-toast";
import { Tooltip } from "@mui/material";

const CustomerMembership = () => {
  const { authTokens } = useContext(AuthContext);

  // state to store data
  const [validation, setValidation] = useState({
    contactNumber: false,
    customerName: false,
    selectedMemType: false,
    selectedManager: false,
    membershipCode: false,
    notes: false,
    branchName: false,
    noOfPerson: false,
    gender: false,
  });
  const [customerName, setCustomerName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [selectedMemType, setSelectedMemType] = useState("");
  const [membershipTypes, setMembershipTypes] = useState([]);
  const [notes, setNotes] = useState("");
  const [branchName, setBranchName] = useState("");
  const [noOfPerson, setNoOfPerson] = useState("");
  const [membershipCode, setMembershipCode] = useState("");
  const [managers, setManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

  // Function to get membership types available
  const getAllMembershipsTypes = async () => {
    const response = await fetch(
      "https://trakky.in:8000/salonvendor/membership-type/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access_token}`,
        },
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      const memNames = data.map((item) => {
        return { id: item.id, name: item.name, price: item.membership_price };
      });
      setMembershipTypes(memNames);
    } else {
      console.log("Error");
    }
  };

  // function to get membership code
  const getMembershipCode = async () => {
    const response = await fetch(
      "https://trakky.in:8000/salonvendor/membershipcodegenerator/"
    );
    if (response.status === 200) {
      const data = await response.json();
      setMembershipCode(data.membership_code);
    } else {
      console.log("Error");
    }
  };

  // function to get managers
  const getManagers = async () => {
    const response = await fetch(
      "https://trakky.in:8000/salonvendor/manager/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access_token}`,
        },
      }
    );
    if (response.status === 200) {
      const data = await response.json();
      const managerNames = data.map((item) => {
        return { id: item.id, name: item.managername };
      });
      setManagers(managerNames);
    } else {
      console.log("Error");
    }
  };

  // function to handle submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    const hasError = Object.values(validation).some((item) => item === true);
    if (hasError) {
      return;
    }
    const data = {
      "membership_type": selectedMemType,
      "customer_name": customerName,
      "customer_phone": contactNumber,
      "num_person_allowed": noOfPerson,
      "remarks": notes,
      "manager": selectedManager,
      "amount_paid":  amountPaid,
      "membership_code": membershipCode,
      "customer_gender":gender,
      "customer_email": email,
    };
    const response = await fetch(
      "https://trakky.in:8000/salonvendor/customer-membership/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access_token}`,
        },
        body: JSON.stringify(data),
      }
    );

    if (response.status === 201) {
      const data = await response.json();
      toast.success("Membership Added Successfully");
    } else {
      toast.error("Error in Adding Membership");
      console.log("Error");
    }
  };

  // function to set total amount
  const setTotalAmountFunc = () => {
      const amount =  membershipTypes.find((item) => item.id === parseInt(selectedMemType))?.price;
      setTotalAmount(amount); 
  }

  useEffect(() => {
    setTotalAmountFunc();
  } , [selectedMemType])

  useEffect(() => {
    getAllMembershipsTypes();
    getManagers();
  }, []);

  return (
    <>
      <div className="ms-register-form">
        <form onSubmit={handleSubmit}>
        <div className="appointment-fillup">
          <div className="left-col1">
            <div className="appoint-input-field">
              <input
                style={{
                  border: validation.customerName ? "1.5px solid red" : "",
                }}
                onBlur={() => {
                  if (customerName === "") {
                    setValidation((val) => {
                      return { ...val, customerName: true };
                    });
                  } else {
                    setValidation((val) => {
                      return { ...val, customerName: false };
                    });
                  }
                }}
                type="text"
                required
                placeholder="Customer Name"
                value={customerName}
                onChange={(e) => {
                  setCustomerName(e.target.value);
                }}
              />
              {validation.customerName && (
                <Tooltip title="Customer Name is required">
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px]"
                    color="error"
                  />
                </Tooltip>
              )}
            </div>
            <div className="appoint-input-field">
              <select
                required
                onBlur={() => {
                  if (selectedMemType === "") {
                    setValidation((val) => {
                      return { ...val, selectedMemType: true };
                    });
                  } else {
                    setValidation((val) => {
                      return { ...val, selectedMemType: false };
                    });
                  }
                }
                }
                onChange={(e) => {
                  setSelectedMemType(e.target.value);
                  getMembershipCode();
                }}
                style={{
                  border: validation.selectedMemType ? "1.5px solid red" : "",
                  color: selectedMemType === "" ? "#9CA6BE" : "black",
                }}
              >
                <option value="" selected disabled hidden>
                  Type of Membership
                </option>
                {membershipTypes.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="appoint-input-field">
              <input
                type="text"
                placeholder="Notes*"
                value={notes}
                required
                style={{
                  border: validation.notes ? "1.5px solid red" : "",
                }}
                onBlur={() => {
                  if (notes === "") {
                    setValidation((val) => {
                      return { ...val, notes: true };
                    });
                  } else {
                    setValidation((val) => {
                      return { ...val, notes: false };
                    });
                  }
                }
                }
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
              />
              {validation.notes && (
                <Tooltip title="Notes is required">
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px]"
                    color="error"
                  />
                </Tooltip>
              )}
            </div>
            <div className="appoint-input-field">
              <input
                type="number"
                placeholder="Total Amount"
                value={totalAmount}
                readOnly
              />
            </div>
            <div className="appoint-input-field">
              <input
                type="text"
                required
                placeholder="Branch Name"
                value={branchName}
                style={{
                  border: validation.branchName ? "1.5px solid red" : "",
                }}
                onBlur={() => {
                  if (branchName === "") {
                    setValidation((val) => {
                      return { ...val, branchName: true };
                    });
                  } else {
                    setValidation((val) => {
                      return { ...val, branchName: false };
                    });
                  }
                }
                }
                onChange={(e) => {
                  setBranchName(e.target.value);
                }}
              />
              {validation.branchName && (
                <Tooltip title="Branch Name is required">
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px]"
                    color="error"
                  />
                </Tooltip>
              )}
            </div>
            <div className="appoint-input-field">
              <Tooltip title="Email" placement="top" arrow>
                <input
                  type="email"
                  placeholder="Email Id"
                  value={email}
                  style={{
                    border: validation.email ? "1.5px solid red" : "none",
                  }}
                  onBlur={(e) => {
                    if (!emailRegex.test(e.target.value)) {
                      setValidation({ ...validation, email: true });
                    } else {
                      setValidation({ ...validation, email: false });
                    }
                  }
                  }
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                {validation.email && (
                  <Tooltip title="Invalid Email" placement="top" arrow>
                    <ErrorIcon
                        className="error-icon absolute right-[5px] bottom-[10px] hidden"
                        color="error"
                      />
                  </Tooltip>
                )  
                }
              </Tooltip>
            </div>
          </div>
          <div className="right-col2">
            <div className="appoint-input-field">
              <input
                type="number"
                required
                style={{
                  border: validation.noOfPerson ? "1.5px solid red" : "",
                }}
                onBlur={() => {
                  if (noOfPerson === "") {
                    setValidation((val) => {
                      return { ...val, noOfPerson: true };
                    });
                  } else {
                    setValidation((val) => {
                      return { ...val, noOfPerson: false };
                    });
                  }
                }
                }
                placeholder="No. Person Allowed"
                value={noOfPerson}
                onChange={(e) => {
                  setNoOfPerson(e.target.value);
                }}
              />
              {validation.noOfPerson && (
                <Tooltip title="No. Person Allowed is required">
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px]"
                    color="error"
                  />
                </Tooltip>
              )}
            </div>
            <div className="relative appoint-input-field">
              <input
                type="number"
                required
                style={{
                  border: validation.contactNumber ? "1.5px solid red" : "",
                }}
                onBlur={() => {
                  if (contactNumber === "" || contactNumber.length !== 10) {
                    setValidation((val) => {
                      return { ...val, contactNumber: true };
                    });
                  } else {
                    setValidation((val) => {
                      return { ...val, contactNumber: false };
                    });
                  }
                }
                }
                placeholder="Mobile Number"
                value={contactNumber}
                onChange={(e) => {
                  setContactNumber((val) => {
                    if (e.target.value.length > 10) {
                      return val;
                    } else if (e.target.value.length < 10) {
                      return e.target.value;
                    } else {
                      return e.target.value;
                    }
                  });
                }}
              />
              {validation.contactNumber && (
                <Tooltip title="Atleast 10 digits are required">
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px]"
                    color="error"
                  />
                </Tooltip>
              )}
            </div>
            <div className="appoint-input-field">
              <input
                type="text"
                placeholder="Membership Code"
                value={membershipCode}
                style={{ color: "black" }}
                readOnly
              />
            </div>
            <div className="appoint-input-field">
              <input
                type="number"
                placeholder="Amount Paid"
                value={amountPaid}
                onChange={(e) => {
                  setAmountPaid(e.target.value);
                }}
              />
            </div>
            <div className="appoint-input-field">
              <Tooltip title="Gender" placement="top" arrow>
              <select
                required
                onBlur={() => {
                  if (gender === ""){
                    setValidation((val) => {
                      return {
                        ...val,
                        gender: true,
                      }
                  }
                  );
                  }else{
                    setValidation((val) => {
                      return {
                        ...val,
                        gender: false,
                      }
                  }
                  );
                }  
                }
                }
                onChange={(e) => {
                  setGender(e.target.value);
                }}
                style={
                  {
                    border : validation.gender ? "1.5px solid red" : "",
                    color: gender === "" ? "#9CA6BE" : "black",
                  }
                }
              >
                <option className="" value="" selected disabled hidden>
                  Select Gender
                </option>
               
                    <option  value={"M"}>
                    Male
                    </option>
                    <option  value={"F"}>
                    Female
                    </option>
               
              </select></Tooltip>
            </div>
            <div className="appoint-input-field">
              <select
                required
                onBlur={() => {
                  if (selectedManager === "") {
                    setValidation((val) => {
                      return { ...val, selectedManager: true };
                    });
                  } else {
                    setValidation((val) => {
                      return { ...val, selectedManager: false };
                    });
                  }
                }
                }
                onChange={(e) => {
                  setSelectedManager(e.target.value);
                }}
                style={
                  {
                    border: validation.selectedManager ? "1.5px solid red" : "",
                    color: selectedManager === "" ? "#9CA6BE" : "black",
                  }
                }
              >
                <option value="" selected disabled hidden>
                  Select Manager
                </option>
                {managers.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            
           
          </div>
        
        </div>
       
        <div className="appointment-form-btn">
          <button type="submit">
            Add Membership
          </button>
        </div>
        </form>
      </div>
    </>
  );
};

export default CustomerMembership;
