import React, { cloneElement, useRef, useState } from "react";
import "./css/staffmanagement.css";
import { Link, useLocation } from "react-router-dom";
// import StaffTable from './staffmanagement/StaffTable';
import DateRange from "./dateModal/DateRange";
import GeneralModal from "./generalModal/GeneralModal";

function DailyStaffmanage(props) {
  const location = useLocation();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  // Calculate the start date of the current month
  const currentMonthStartDate = new Date(currentYear, currentMonth, 1);

  // Calculate the end date of the current month
  const currentMonthEndDate = new Date(currentYear, currentMonth + 1, 0);

  //  new date filter
  const [showDateSelectionModal, setShowDateSelectionModal] = useState(false);
  const [dateState, setDateState] = useState([
    {
      startDate: currentMonthStartDate,
      endDate: currentMonthEndDate,
      key: "selection",
    },
  ]);

  const [date, setDate] = useState(
    new Date()
      .toLocaleString("en-CA", { timeZone: "Asia/Kolkata" })
      .slice(0, 10)
  );

  return (
    <>
      <div className=" w-[100%]  h-[15%] px-[10px]  md:h-[75px] lg:h-[80px] gap-4 md:gap-0  md:m-0  my-6  lg:px-7 flex flex-col md:flex-row  items-center justify-between   ">
        <div className="bg-[#CACFFF]  h-[50%]   flex min-h-[2.3rem]  w-max  rounded-[.6rem]">
          <Link to="/spa/staffmanagement/stafftable" className="text-[#000]">
            <button
              className={`lg:text-xl px-2 h-[100%] font-[400] transition-all duration-500  ${
                location.pathname === "/spa/staffmanagement/stafftable"
                  ? " shadow-2xl h-[100%] rounded-[.6rem] bg-white "
                  : ""
              } `}
            >
              Daily Staff
            </button>
          </Link>
          <Link to="/spa/staffmanagement/staffform" className="text-[#000]">
            <button
              className={`lg:text-xl px-2 h-[100%] font-[400] transition-all duration-500   ${
                location.pathname === "/spa/staffmanagement/staffform"
                  ? " shadow-2xl rounded-[.6rem] bg-white "
                  : ""
              } `}
            >
              Add Staff
            </button>
          </Link>{" "}
          <Link to="/spa/staffmanagement/staffrecord" className="text-[#000]">
            <button
              className={`lg:text-xl px-2 h-[100%] font-[400] transition-all duration-500   ${
                location.pathname === "/spa/staffmanagement/staffrecord"
                  ? " shadow-2xl rounded-[.6rem] bg-white "
                  : ""
              } `}
              // onClick={() => {
              //   setStaffManageForm(true);
              // }}
            >
              Staff Record
            </button>
          </Link>
        </div>
        {location.pathname === "/spa/staffmanagement/stafftable" ? (
          <div className="px-4 min-h-[2rem] lg:h-[50%] relative shadow-xl text-center rounded-lg flex items-center  bg-white">
            <input
              type="date"
              className="w-[120px] outline-none"
              onChange={(e) => {
                setDate(e.target.value);
              }}
              value={date}
            />
          </div>
        ) : (
          <div
            className="px-4 min-h-[2rem] lg:h-[50%] relative shadow-xl text-center rounded-lg flex items-center  bg-white"
            onClick={() => {
              setShowDateSelectionModal(true);
            }}
          >
            <input
              type="text"
              value={`${dateState[0].startDate.getDate()}-${
                dateState[0].startDate.getMonth() + 1
              }-${dateState[0].startDate.getFullYear()}`}
              style={{ width: "95px", cursor: "auto" }}
              readOnly
            />
            <span style={{ padding: "0 10px" }}> ~ </span>
            <input
              type="text"
              value={`${dateState[0]?.endDate?.getDate()}-${
                dateState[0]?.endDate?.getMonth() + 1
              }-${dateState[0]?.endDate?.getFullYear()}`}
              style={{ width: "95px", cursor: "auto" }}
              readOnly
            />
          </div>
        )}
      </div>
      <div className="mx-auto w-[95%] h-[3%] bg-[#B8B8B8] max-h-[.1rem]"></div>
      {location.pathname === "/spa/staffmanagement/stafftable"
        ? cloneElement(props.children, {
            date: date,
          })
        : dateState[0]?.startDate === null
        ? cloneElement(props.children, {
            startDate: currentMonthStartDate.toISOString().slice(0, 10),
            endDate: currentMonthEndDate.toISOString().slice(0, 10),
          })
        : cloneElement(props.children, {
            startDate: `${dateState[0]?.startDate?.getFullYear()}-${
              dateState[0]?.startDate?.getMonth() + 1
            }-${dateState[0]?.startDate?.getDate()}`,
            endDate: `${dateState[0]?.endDate?.getFullYear()}-${
              dateState[0]?.endDate?.getMonth() + 1
            }-${dateState[0]?.endDate?.getDate()}`,
          })}
      <GeneralModal
        open={showDateSelectionModal}
        handleClose={() => setShowDateSelectionModal(false)}
      >
        <DateRange
          dateState={dateState}
          setDateState={setDateState}
          setShowDateSelectionModal={setShowDateSelectionModal}
        />
      </GeneralModal>
    </>
  );
}

export default DailyStaffmanage;
