import React, { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../context/Auth";
import "../css/settings.css";
import { Tooltip } from "@mui/material";
import toast from "react-hot-toast";
import ErrorIcon from "@mui/icons-material/Error";
import GeneralModal from "../generalModal/GeneralModal";

const SettingForm = () => {
  const { authTokens , vendorData } = useContext(AuthContext);
  const [businessName, setBusinessName] = useState("");
  const [salonName, setsalonName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerMobile, setOwnerMobile] = useState("");
  const [salonMobile, setsalonMobile] = useState("");
  const [openingTime, setOpeningTime] = useState("");
  const [closingTime, setClosingTime] = useState("");
  const [email, setEmail] = useState("");
  const [branchCode, setBranchCode] = useState("");
  const [branchName, setBranchName] = useState("");
  const [address, setAddress] = useState("");
  const [salonId, setsalonId] = useState(null);
  const userId = useRef(null);
  const [validation, setValidation] = useState({
    email: false,
  });
  const formref = useRef(null);
  const [showExtendModal, setShowExtendModal] = useState(false);
  const [password, setPassword] = useState("");

  // qr code and preview
  const [previewUrl, setPreviewUrl] = useState("");

  const getPreview = () => {
    fetch(`https://trakky.in:8000/salonvendor/salon-link/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access_token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPreviewUrl(data?.link);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPreview();
  }, []);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  useEffect(() => {
    
        setBusinessName(vendorData?.businessname);
        setOwnerName(vendorData?.ownername);
        setOwnerMobile(vendorData?.ph_number);
        setsalonId(vendorData?.salon);
        setEmail(vendorData?.email);
        setBranchCode(vendorData?.branchcode);
        setBranchName(vendorData?.branchname);

        userId.current = vendorData?.id;

  }, [vendorData]);

  useEffect(() => {
    if (salonId !== null) {
      fetch(`https://trakky.in:8000/salons/${salonId}/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access_token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setsalonName(data.name);
          setsalonMobile(data.mobile_number);
          setOpeningTime(data.open_time);
          setClosingTime(data.close_time);
          setAddress(data.address);
        })
        .catch((err) => console.log(err));
    }
  }, [salonId, authTokens]);

  const hanleSubmit = async (e) => {
    // e.preventDefault();

    const hasError = Object.values(validation).some((v) => v === true);
    if (hasError) {
      return;
    }
    fetch(`https://trakky.in:8000/salonvendor/vendor/${userId.current}/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access_token}`,
      },
      body: JSON.stringify({
        businessname: businessName,
        ownername: ownerName,
        ph_number: ownerMobile,
        email: email,
        branchcode: branchCode,
        branchname: branchName,
        password: password,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          setPassword("");

          toast.success("Updated Successfully");
          setShowExtendModal(false);
          return res.json();
        } else if (res.status === 403) {
          setPassword("");
          toast.error("incorrect password,please try again");
        }
      })
      .then((data) => {
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });

    let data = new FormData();
    data.append("address", address);
    data.append("open_time", openingTime);
    data.append("close_time", closingTime);

    fetch(`https://trakky.in:8000/salons/${salonId}/`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${authTokens.access_token}`,
      },
      body: data,
    })
      .then((res) => {
        res.json();
        // toast.success("Updated Successfully");
      })
      .then((data) => {
        // console.log(data)
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  return (
    <div>
      <form
        ref={formref}
        className="ms-register-form ms-setting-register-form "
        onSubmit={hanleSubmit}
      >
        <div className="appointment-fillup">
          <div className="left-col1">
            <div className="appoint-input-field">
              <Tooltip title="Business Name" placement="top" arrow>
                <input
                  type="text"
                  placeholder="Business Name"
                  value={businessName}
                  readOnly
                />
              </Tooltip>
            </div>
            <div className="appoint-input-field">
              <Tooltip title="Owner Name" placement="top" arrow>
                <input
                  type="text"
                  placeholder="Owner Name"
                  value={ownerName}
                  readOnly
                />
              </Tooltip>
            </div>
            <div className="appoint-input-field">
              <Tooltip title="salon Mobile Number" placement="top" arrow>
                <input
                  type="text"
                  placeholder="salon Mobile Number"
                  value={salonMobile}
                  readOnly
                />
              </Tooltip>
            </div>
          </div>
          <div className="middle-col2">
            <div className="appoint-input-field">
              <Tooltip title="Email" placement="top" arrow>
                <input
                  type="email"
                  placeholder="Email Id"
                  value={email}
                  style={{
                    border: validation.email ? "1.5px solid red" : "none",
                  }}
                  onBlur={(e) => {
                    if (!emailRegex.test(e.target.value)) {
                      setValidation({ ...validation, email: true });
                    } else {
                      setValidation({ ...validation, email: false });
                    }
                  }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                {validation.email && (
                  <Tooltip title="Invalid Email" placement="top" arrow>
                    <ErrorIcon
                      className="error-icon absolute right-[5px] bottom-[10px] hidden"
                      color="error"
                    />
                  </Tooltip>
                )}
              </Tooltip>
            </div>
            <div className="appoint-input-field relative">
              <Tooltip title="Owner Mobile Number" placement="top" arrow>
                <input
                  type="number"
                  placeholder="Owner Mobile Number"
                  value={ownerMobile}
                  onChange={(e) => {
                    setOwnerMobile(e.target.value);
                  }}
                />
              </Tooltip>
            </div>
            <div className="appoint-input-field">
              <Tooltip title="Branch Code" placement="top" arrow>
                <input
                  type="text"
                  placeholder="Branch Code"
                  value={branchCode}
                  onChange={(e) => {
                    setBranchCode(e.target.value);
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <div className="right-col3">
            <div className="appoint-input-field">
              <Tooltip title="Opening Time" placement="top" arrow>
                <input
                  type="time"
                  placeholder="Opening Time"
                  value={openingTime}
                  onChange={(e) => {
                    setOpeningTime(e.target.value);
                  }}
                />
              </Tooltip>
            </div>
            <div className="appoint-input-field relative">
              <Tooltip title="Closing Time" placement="top" arrow>
                <input
                  type="time"
                  placeholder="Closing Time"
                  value={closingTime}
                  onChange={(e) => {
                    setClosingTime(e.target.value);
                  }}
                />
              </Tooltip>
            </div>
            <div className="appoint-input-field">
              <Tooltip title="Branch Name" placement="top" arrow>
                <input
                  type="text"
                  placeholder="Branch Name"
                  value={branchName}
                  onChange={(e) => {
                    setBranchName(e.target.value);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="appoint-input-field address-field">
          <Tooltip title="Address" placement="top" arrow>
            <input
              type="text"
              placeholder="Adress"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
          </Tooltip>
        </div>
        {previewUrl && (
          <div className="salon-profile-url">
            <div><span>Profile Url :</span> <input type="text" value={previewUrl} readOnly/> </div>
            <a href={previewUrl} target="_blank">
              Visit
            </a>
          </div>
        )}
        <div className="appointment-form-btn">
          <button
            type="button"
            onClick={() => {
              setShowExtendModal(true);
            }}
          >
            Submit / Update
          </button>
        </div>
        <GeneralModal
          open={showExtendModal}
          handleClose={() => setShowExtendModal(false)}
        >
          <div className=" flex flex-col justify-center items-center w-[100%] h-[100%] ">
            <div className="w-full  h-full flex justify-center items-center flex-col  py-10  gap-5">
              <input
                placeholder="Enter The Password"
                type="text"
                className=" w-[12rem] h-[2rem] px-1 rounded-md drop-shadow-md "
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <button
                type="submit"
                className="w-[10rem] h-[2rem] text-white bg-green-500 px-2 py-1 rounded-md drop-shadow-md"
                onClick={() => {
                  hanleSubmit(formref);
                }}
              >
                submit
              </button>
            </div>
          </div>
        </GeneralModal>
      </form>
    </div>
  );
};

export default SettingForm;
