import "./App.css";
// import "./input.css";
import { Routes, Route } from "react-router-dom";

import React, { useContext, useEffect, useState } from "react";
import Login from "./components/Login";
import Header from "./components/Header";
import Dashboard from "./components/Dashboard";
import Sidebar from "./components/Sidebar";
import Appointment from "./components/Appointment";
import DailyStaffmanage from "./components/DailyStaffmanage";
import Servicemanagement from "./components/Servicemanagement";
import AppointmentCard from "./components/appointment/AppointmentCard";
import AppointmentForm from "./components/appointment/AppointmentForm";
import StaffTable from "./components/staffmanagement/StaffTable";
import StaffForm from "./components/staffmanagement/StaffForm";
import StaffRecord from "./components/staffmanagement/StaffRecord";
import ServiceTable from "./components/servicemanagement/ServiceTable";
import ServiceForm from "./components/servicemanagement/ServiceForm";
import Offer from "./components/Offer";
import OfferForm from "./components/offer/OfferForm";
import OfferTable from "./components/offer/OfferTable";
import Membership from "./components/Membership";
import MembershipTable from "./components/membership/MembershipTable";
import MembershipForm from "./components/membership/MembershipForm";
import SidebarContext from "./components/Sidebar_context";
import StandardMembership from "./components/membership/CustomerMembership";
import CustomizedMembership from "./components/membership/MembershipType";
import Setting from "./components/setting/Setting";
import SettingForm from "./components/setting/SettingForm";
import AddCustomer from "./components/client_management/AddCustomer";
import CustomerDetails from "./components/client_management/CustomerDetails";
import Client from "./components/Client";
import Category from "./components/Category";
import CategoryForm from "./components/category/CategoryForm";
// import CategoryTable from "./components/category/CategoryTableTable";
import MasterService from "./components/servicemanagement/MasterService";
import DashboardStaffDetails from "./components/dashboard/DashboardStaffDetails";
import DashboardClientDetails from "./components/dashboard/DashboardClientDetails";
import SalesDetails from "./components/dashboard/SalesDetails";
import SettingDetails from "./components/setting/SettingDetails";
import { Toaster } from "react-hot-toast";

//Rotes...

import AuthContext from "./context/Auth";
import PrivateRoute from "./context/PrivateRoute";
import CategoryTable from "./components/category/CategoryTable";

function App() {
  const [SidebarStatus, SetSidebar] = React.useState(false);
  const { user, authTokens , vendorData } = useContext(AuthContext);

  const [ownerName, setOwnerName] = useState("");
  const [vendorLogo, setVendorLogo] = useState(null);
  const [salonName, setSalonName] = useState("");


  useEffect(() => {
    if (vendorData) {
      setOwnerName(vendorData?.ownername);
      setVendorLogo(vendorData?.logo);
      setSalonName(vendorData?.salon_name);
    }
  }, [vendorData]);

  useEffect(() => {
    document.title = `Trakky POS for salon`;
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        `Grow your business with trakky at lightning speed!`
      );
  });

  return (
    <>
      <Toaster position="bottom-right" />
      {user ? (
        <div id="main_root" className=" overflow-hidden">
          <div id="main" className="">
            <div
              id="sidebar"
              className={`${SidebarStatus ? "sidebar_open" : ""}   sm:w-[30%] `}
            >
              <SidebarContext.Provider value={{ SidebarStatus, SetSidebar }}>
                <Sidebar vendorLogo={vendorLogo} />
              </SidebarContext.Provider>
            </div>
            <div id="body_div" className="grow sm:w-[70%] ">
              <div id="header_div" className="">
                <SidebarContext.Provider value={{ SidebarStatus, SetSidebar }}>
                  <Header ownerName={ownerName} salonName={salonName} />
                </SidebarContext.Provider>
              </div>
              <div id="route_body_div" className="">
                <Routes>
                  <Route path="/salon/*" element={<Login />} />
                  <Route
                    path="/salon/"
                    element={
                      <PrivateRoute>
                        <Dashboard children={<SalesDetails />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/dashboard/staffdetails"
                    element={
                      <PrivateRoute>
                        <Dashboard children={<DashboardStaffDetails />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/dashboard/clientdetails"
                    element={
                      <PrivateRoute>
                        <Dashboard children={<DashboardClientDetails />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/appointment/appointment_card"
                    element={
                      <PrivateRoute>
                        <Appointment children={<AppointmentCard />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/appointment/appointment_form"
                    element={
                      <PrivateRoute>
                        <Appointment children={<AppointmentForm />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/staffmanagement/stafftable"
                    element={
                      <PrivateRoute>
                        <DailyStaffmanage children={<StaffTable />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/categorymanagement/categorytable"
                    element={
                      <PrivateRoute>
                        <Category children={<CategoryTable />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/categorymanagement/categoryform"
                    element={
                      <PrivateRoute>
                        <Category children={<CategoryForm />} />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/salon/staffmanagement/staffform"
                    element={
                      <PrivateRoute>
                        <DailyStaffmanage children={<StaffForm />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/staffmanagement/staffrecord"
                    element={
                      <PrivateRoute>
                        <DailyStaffmanage children={<StaffRecord />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/servicemanagement/servicetable"
                    element={
                      <PrivateRoute>
                        <Servicemanagement />
                        <ServiceTable />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/servicemanagement/serviceform"
                    element={
                      <PrivateRoute>
                        <Servicemanagement />
                        <ServiceForm />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/servicemanagement/addmasterservice"
                    element={
                      <PrivateRoute>
                        <Servicemanagement />
                        <MasterService />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/clientdetails/addcustomer"
                    element={
                      <PrivateRoute>
                        <Client children={<AddCustomer />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/clientdetails/customerdetails"
                    element={
                      <PrivateRoute>
                        <Client children={<CustomerDetails />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/offermanagement/offertable"
                    element={
                      <PrivateRoute>
                        <Offer children={<OfferTable />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/offermanagement/offerform"
                    element={
                      <PrivateRoute>
                        <Offer children={<OfferForm />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/membership/membershiptable"
                    element={
                      <PrivateRoute>
                        <Membership children={<MembershipTable />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/setting/general"
                    element={
                      <PrivateRoute>
                        <Setting children={<SettingForm />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/setting/details"
                    element={
                      <PrivateRoute>
                        <Setting children={<SettingDetails />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/membership/customermembership"
                    element={
                      <PrivateRoute>
                        <div className="flex flex-col w-[100%] h-[100%] overflow-scroll items-center  box-border ">
                          <Membership
                            children={
                              <div className=" w-[100%]   py-4 md:py-1  flex flex-wrap px-7 gap-6  items-center   ">
                                <MembershipForm />
                                <StandardMembership />
                              </div>
                            }
                          />
                        </div>
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/salon/membership/membershiptype"
                    element={
                      <PrivateRoute>
                        <div className="flex flex-col w-[100%] h-[100%] ">
                          <Membership
                            children={
                              <div className=" w-[100%]   py-4 md:py-1   flex flex-wrap px-7 gap-6  items-center   ">
                                <MembershipForm />
                                <CustomizedMembership />
                              </div>
                            }
                          />
                        </div>
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Login />
      )}
    </>
  );
}

export default App;
