import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../input.css";
import "./css/sidebar.css";

//importing svgs
import dashboard from "./svg/dashboard.svg";
import appointment from "./svg/appointment.svg";
import staffmanagement from "./svg/staffmanagement.svg";
import servicemanagement from "./svg/servicemanagement.svg";
import offersmanagement from "./svg/offersmanagement.svg";
import membership from "./svg/membership.svg";
import setting from "./svg/setting.svg";
import client from './svg/client.svg'
import category from './svg/category.svg'

//importing active svgs
import activeDashboard from "./svg/active-dashboard.svg";
import activeAppointment from "./svg/active-appointment.svg";
import activeStaffmanagement from "./svg/active-staffmanagement.svg";
import activeServicemanagement from "./svg/active-servicemanagement.svg";
import activeOffersmanagement from "./svg/active-offermanagement.svg";
import activeMembership from "./svg/active-membership.svg";
import activeSetting from "./svg/active-setting.svg";
import whiteClose from "./svg/white_close.svg";
import logo from "./svg/logo_white.png";
import Sidebar_context from "./Sidebar_context";
import activeClient from './svg/active-client.svg'
import activecategory from './svg/activecategory.svg'

const Sidebar = ({ vendorLogo }) => {
  const { SidebarStatus, SetSidebar } = React.useContext(Sidebar_context);

  return (
    <div className=" relative  w-[100%] z-40 h-[100%] text-xl  md:gap-2 overflow-y-scroll   box-border  md:text-sm lg:text-2xl   overflow-hidden flex flex-col justify-start items-center   ">
      <div className="w-[100%] flex justify-between md:justify-center   items-center px-4 py-5">
        <img
          src={vendorLogo || logo}
          alt="logo_white"
          className="  w-[10rem] md:w-[12rem] max-h-24 object-cover rounded-[5px]"
        ></img>
        <button
          onClick={() => {
            SetSidebar(!SidebarStatus);
          }}
        >
          <img
            src={whiteClose}
            className=" md:hidden  w-[1.5rem] active:w-[1.9rem] "
            alt="left arrow"
          ></img>
        </button>
      </div>
      <div className="w-[100%] h-[80%] flex flex-col justify-start items-center pl-[10px] gap-2 lg:gap-1 ">
        <Sidebaritem
          SetSidebar={SetSidebar}
          title={"Dashboard"}
          icon={dashboard}
          activeIcon={activeDashboard}
          link={"/salon/"}
          link2={"/salon/dashboard/staffdetails"}
          link3={"/salon/dashboard/clientdetails"}
        />
        <Sidebaritem
          SetSidebar={SetSidebar}
          title={"Client Details"}
          icon={client}
          activeIcon={activeClient}
          link2={"/salon/clientdetails/addcustomer"}
          link={"/salon/clientdetails/customerdetails"}
        />
        <Sidebaritem
          SetSidebar={SetSidebar}
          title={"Appointments"}
          icon={appointment}
          activeIcon={activeAppointment}
          link={"/salon/appointment/appointment_card"}
          link2={"/salon/appointment/appointment_form"}
        />
        <Sidebaritem
          title={"Staff Management"}
          SetSidebar={SetSidebar}
          icon={staffmanagement}
          activeIcon={activeStaffmanagement}
          link={"/salon/staffmanagement/stafftable"}
          link2={"/salon/staffmanagement/staffform"}
          link3={"/salon/staffmanagement/staffrecord"}
        />
        <Sidebaritem
          title={"Category Management"}
          SetSidebar={SetSidebar}
          icon={category}
          activeIcon={activecategory}
          link={"/salon/categorymanagement/categorytable"}
          link2={"/salon/categorymanagement/categoryform"}
        />
        <Sidebaritem
          title={"Service Management"}
          SetSidebar={SetSidebar}
          icon={servicemanagement}
          activeIcon={activeServicemanagement}
          link={"/salon/servicemanagement/servicetable"}
          link2={"/salon/servicemanagement/serviceform"}
          link3={"/salon/servicemanagement/addmasterservice"}
        />
        <Sidebaritem
          title={"Offers Management"}
          SetSidebar={SetSidebar}
          icon={offersmanagement}
          activeIcon={activeOffersmanagement}
          link={"/salon/offermanagement/offertable"}
          link2={"/salon/offermanagement/offerform"}
        />
        <Sidebaritem
          title={"Membership"}
          SetSidebar={SetSidebar}
          icon={membership}
          activeIcon={activeMembership}
          link={"/salon/membership/membershiptable"}
          link2={"/salon/membership/customermembership"}
          link3={"/salon/membership/membershiptype"}
        />
        <Sidebaritem
          title={"Settings"}
          SetSidebar={SetSidebar}
          icon={setting}
          activeIcon={activeSetting}
          link={"/salon/setting/general"}
          link2={"/salon/setting/details"}
        />
      </div>
    </div>
  );
};

export default Sidebar;

const Sidebaritem = (props) => {
  const location = useLocation();

  const isActive =
    location.pathname === props.link ||
    location.pathname === props.link2 ||
    location.pathname === props.link3;

  return (
    <Link
      to={props.link}
      // key={props.link}
      className={`flex  gap-2 xl:gap-3    md:py-2 xl:py-2 sidebar-item px-4 py-2  justify-start rounded-l-full  w-[100%] items-center  ${
        isActive ? "active" : ""
      }`}
      onClick={() => {
        props.SetSidebar(false);
      }}
    >
      <div className=" ">
        <img
          src={`${isActive ? props.activeIcon : props.icon}`}
          className="min-w-[1rem]"
          alt="icon"
        />
      </div>
      <span className="text-[0.9rem] md:text-[0.8rem]  max-w-[80%] lg:text-[0.9rem]  ">{props.title}</span>
    </Link>
  );
};
