
import './App.css';
import Spa from './spa/App';
import Salon from './salon/App';
import { useLocation } from 'react-router-dom';
import { AuthProvider as SpaAuthProvider } from './spa/context/Auth';
import { AuthProvider as SalonAuthProvider } from './salon/context/Auth';
function App() {

  const location = useLocation();

  return (
    <>
      {
      location.pathname.split("/")[1] ==='spa' ? <SpaAuthProvider><Spa /></SpaAuthProvider> :
      location.pathname.split("/")[1] ==='salon' ? <SalonAuthProvider><Salon /></SalonAuthProvider> : null
      
    }
    </>
  );
}

export default App;
