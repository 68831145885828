import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/Auth";
import "../css/staffmanagement.css";

function StaffTable({ date }) {
  // const date = new Date().toLocaleString("en-CA", { timeZone: "Asia/Kolkata" }).slice(0, 10)

  const isToday =
    date ===
    new Date()
      .toLocaleString("en-CA", { timeZone: "Asia/Kolkata" })
      .slice(0, 10);

  const { authTokens } = useContext(AuthContext);
  const [staffData, setStaffData] = useState([]);
  // const [date, setDate] = useState(props.date)
  const [staffDailyData, setStaffDailyData] = useState([]);
  // const [isDailyDataFetched, setIsDailyDataFetched] = useState(false)
  const handleStaffAttendance = (id, data) => {
    if (
      data.date !==
      new Date()
        .toLocaleString("en-CA", { timeZone: "Asia/Kolkata" })
        .slice(0, 10)
    ) {
      alert("You can only edit today's attendance");
      return;
    }
    if (!id && typeof data.present === "undefined") {
      data.present = false;
    }

    fetch(
      `https://trakky.in:8000/spavendor/staff/attendance/${
        id ? id + "/" : ""
      }`,
      {
        method: id ? "PATCH" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access}`,
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          if (!id) {
            if (data.non_field_errors) {
              console.log(data.non_field_errors);
            } else if (data.error) {
              console.log(data.error);
              alert(data.error);
            } else {
              setStaffDailyData([...staffDailyData, data]);
            }
          } else {
            setStaffDailyData(
              staffDailyData.map((staffDaily) =>
                staffDaily.id === data.id ? data : staffDaily
              )
            );
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const filterStaffDaily = (staffId) => {
    return staffDailyData.filter((staffDaily) => {
      return staffDaily.staff === staffId;
    })?.[0];
  };

  useEffect(() => {
    fetch("https://trakky.in:8000/spavendor/staff/?exit=false", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authTokens?.access}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setStaffData(data);
        }
      })
      .catch((err) => console.log(err));
  }, [authTokens, date]);

  useEffect(() => {
    fetch(
      `https://trakky.in:8000/spavendor/staff/attendance/?startdate=${date}&enddate=${date}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setStaffDailyData(data);
        }
      })

      .catch((err) => console.log(err));
  }, [authTokens, date]);

  const handleCommission = (value, staffId) =>
    handleStaffAttendance(filterStaffDaily(staffId)?.id ?? null, {
      commission: value,
      staff: staffId,
      date: date,
    });
  const handleAmountPaid = (value, staffId) =>
    handleStaffAttendance(filterStaffDaily(staffId)?.id ?? null, {
      amount_paid: value,
      staff: staffId,
      date: date,
    });
  const handleNumServices = (value, staffId) =>
    handleStaffAttendance(filterStaffDaily(staffId)?.id ?? null, {
      num_services: value,
      staff: staffId,
      date: date,
    });
  const handleIsPresent = (value, staffId) =>
    handleStaffAttendance(filterStaffDaily(staffId)?.id ?? null, {
      present: value === "yes" ? true : false,
      staff: staffId,
      date: date,
    });
  const handleTimeIn = (value, staffId) =>
    handleStaffAttendance(filterStaffDaily(staffId)?.id ?? null, {
      time_in: value,
      staff: staffId,
      date: date,
    });
  const handleTimeOut = (value, staffId) =>
    handleStaffAttendance(filterStaffDaily(staffId)?.id ?? null, {
      time_out: value,
      staff: staffId,
      date: date,
    });

  return (
    <div className="table-container overflow-scroll max-h-[85%] w-full py-6 px-3">
      <table className="bg-white rounded-lg min-w-[825px] m-auto " rules="all">
        <thead className="font-medium leading-8 border-b-2 ">
          <tr key={0}>
            <th>
              <span className="flex justify-center items-center my-3 px-6 border-e-2">
                Staff Name
              </span>
            </th>
            <th>
              <span className="flex justify-center items-center my-3 px-6 border-e-2">
                Present
              </span>
            </th>
            <th>
              <span className="flex justify-center items-center my-3 px-6 border-e-2">
                No. Services
              </span>
            </th>
            <th>
              <span className="flex justify-center items-center my-3 px-6 border-e-2">
                Comission
              </span>
            </th>
            <th>
              <span className="flex justify-center items-center my-3 px-6 border-e-2">
                Salary
              </span>
            </th>
            <th className="min-w-[150px]">
              <span className="flex justify-center items-center my-3  px-6 border-e-2">
                Time In
              </span>
            </th>
            <th className="min-w-[150px]">
              <span className="flex justify-center items-center my-3  px-6">
                Time Out
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {staffData.map((staff, index) => {
            return (
              <tr key={index + 1}>
                <td className="border-e-2">
                  <span className="flex justify-center items-center p-4">
                    {staff.staffname || "-"}
                  </span>
                </td>
                <td className="border-e-2">
                  <span className="flex justify-center items-center ">
                    {isToday ? (
                      <select
                        className="table-inp rounded-lg pr-3 p-1"
                        name="present"
                        id="present"
                        value={
                          filterStaffDaily(staff.id)?.present ? "yes" : "no"
                        }
                        onChange={(e) => {
                          handleIsPresent(e.target.value, staff.id);
                        }}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    ) : (
                      <span className="flex justify-center items-center ">
                        {filterStaffDaily(staff.id)?.present ? "Yes" : "No"}
                      </span>
                    )}
                  </span>
                </td>
                <td className="border-e-2">
                  <span className="flex justify-center items-center">
                    {isToday ? (
                      <input
                        className="table-inp text-center rounded-lg p-0.5 w-[40%] min-w-[40px] max-w-[100px]"
                        type="number"
                        value={filterStaffDaily(staff.id)?.num_services ?? "-"}
                        onChange={(e) => {
                          handleNumServices(e.target.value || 0, staff.id);
                        }}
                      />
                    ) : (
                      <span className="flex justify-center items-center ">
                        {filterStaffDaily(staff.id)?.num_services ?? "-"}
                      </span>
                    )}
                  </span>
                </td>
                <td className="border-e-2">
                  <span className="flex justify-center items-center">
                    {isToday ? (
                      <input
                        className="table-inp text-center rounded-lg p-0.5 w-[70%] min-w-[70px] max-w-[120px]"
                        type="number"
                        value={filterStaffDaily(staff.id)?.commission ?? "-"}
                        onChange={(e) => {
                          handleCommission(e.target.value || 0, staff.id);
                        }}
                      />
                    ) : (
                      <span className="flex justify-center items-center ">
                        {filterStaffDaily(staff.id)?.commission ?? "-"}
                      </span>
                    )}
                  </span>
                </td>
                <td className="border-e-2">
                  <span className="flex justify-center items-center">
                    {isToday ? (
                      <input
                        className="table-inp text-center rounded-lg p-0.5 w-[70%] min-w-[70px] max-w-[120px]"
                        type="number"
                        value={
                          parseFloat(filterStaffDaily(staff.id)?.amount_paid) ??
                          "-"
                        }
                        onChange={(e) => {
                          handleAmountPaid(e.target.value || 0, staff.id);
                        }}
                      />
                    ) : (
                      <span className="flex justify-center items-center ">
                        {parseFloat(filterStaffDaily(staff.id)?.amount_paid) ??
                          "-"}
                      </span>
                    )}
                  </span>
                </td>
                <td className="border-e-2">
                  <span className="flex justify-center items-center">
                    {isToday ? (
                      <input
                        className="table-inp text-center rounded-lg p-0.5"
                        type="time"
                        id="time-in"
                        value={filterStaffDaily(staff.id)?.time_in || "-"}
                        onChange={(e) => {
                          handleTimeIn(e.target.value, staff.id);
                        }}
                      />
                    ) : (
                      <span className="flex justify-center items-center ">
                        {filterStaffDaily(staff.id)?.time_in || "-"}
                      </span>
                    )}
                  </span>
                </td>
                <td>
                  <span className="flex justify-center items-center">
                    {isToday ? (
                      <input
                        className="table-inp text-center rounded-lg p-0.5"
                        type="time"
                        id="time-out"
                        value={filterStaffDaily(staff.id)?.time_out || "-"}
                        onChange={(e) => {
                          handleTimeOut(e.target.value, staff.id);
                        }}
                      />
                    ) : (
                      <span className="flex justify-center items-center ">
                        {filterStaffDaily(staff.id)?.time_out || "-"}
                      </span>
                    )}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default StaffTable;
