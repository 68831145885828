import React , {cloneElement} from "react";
import { Link, useLocation } from "react-router-dom";

const Setting = (props) => {
  const location = useLocation();

  return (
    <>
      <div className=" w-[100%]  h-[35px] px-[10px]  md:h-[75px] lg:h-[80px] gap-4 md:gap-0  md:m-0  my-6  lg:px-7 flex flex-col md:flex-row  items-center justify-between   ">
      <div className="bg-[#CACFFF]  h-[50%]   flex min-h-[2.3rem]  w-max  rounded-[.6rem]">
        <Link to="/spa/setting/general" className="text-[#000]">
          {" "}
          <button
            className={`lg:text-xl px-2 h-[100%] font-[400] transition-all duration-500  ${
              location.pathname === "/spa/setting/general"
                ? " shadow-2xl h-[100%] rounded-[.6rem] bg-white "
                : ""
            } `}
          >
            Setting
          </button>
        </Link>
        <Link to="/spa/setting/details" className="text-[#000]">
          <button
            className={`lg:text-xl px-2 h-[100%] font-[400] transition-all duration-500   ${
              location.pathname === "/spa/setting/details"
                ? " shadow-2xl rounded-[.6rem] bg-white "
                : ""
            } `}
          >
            Details
          </button>
        </Link>
      </div>
    </div>
      <div className="mx-auto w-[95%] h-[3%] bg-[#B8B8B8] max-h-[.1rem]  "></div>
      {cloneElement(props.children)}

    </>
  );
};

export default Setting;
