import React, { useEffect, useContext, useState } from "react";
import deleteSvg from "../svg/delete.svg";
import edit from "../svg/edit.svg";
import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import AuthContext from "../../context/Auth";
import GeneralModal from "../generalModal/GeneralModal";
import ServiceForm from "./ServiceForm";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 13,
    maxWidth: 320,
    backgroundColor: "rgba(0,0,0,0.85)",
  },
}));

function ServiceTable() {
  const { authTokens } = useContext(AuthContext);

  const [selectedService, setSelectedService] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const [services, setServices] = useState([]);

  // function to get all services
  const getServices = () => {
    fetch("https://trakky.in:8000/spas/service/", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authTokens?.access}`,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setServices(data);
        }
      });
  }

  useEffect(() => {
    getServices();
  }, [authTokens]);

  const handleDelete = (id) => {
    fetch(`https://trakky.in:8000/spas/service/${id}/`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authTokens?.access}`,
      },
    }).then((res) => {
      if (res.status === 204) {
        setServices(services.filter((service) => service.id !== id));
      }
    });
  };

  return (
    <>
    <div className=" h-[100%] w-[100%] relative  ">
      <div className="table-container overflow-scroll w-full py-6 px-3">
        <table
          className="bg-white rounded-lg min-w-[832px] m-auto text-center"
          rules="all"
        >
          <thead className="font-medium leading-8 border-b-2">
            <tr>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Service Name
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Price
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Discounted Price
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Duration
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Description
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6">
                  Action
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {services.map((service, index) => (
              <>
                <tr key={index}>
                  <td className="border-e-2 py-2 px-2">
                    <span>{service.service_name}</span>
                  </td>
                  <td className="border-e-2 py-2">
                    <span>{service.price}</span>
                  </td>
                  <td className="border-e-2 py-2">
                    <span>{service.discount}</span>
                  </td>
                  <td className="border-e-2 py-2">
                    <span>{service.service_time}</span>
                  </td>
                  <td className="py-2">
                    <CustomTooltip
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      arrow
                      sx={{
                        fontSize: "2rem",
                      }}
                      title={service.description}
                    >
                      <div className="px-3 w-[264px] truncate m-auto">
                        <span>{service.description}</span>
                      </div>
                    </CustomTooltip>
                  </td>
                  <td className="py-2">
                    {/* <div className='action flex justify-between min-w-max pr-3'> */}
                    <div className="action flex justify-center min-w-max pr-3">
                      <img
                        src={edit}
                        alt="edit"
                        width="22px"
                        className="mr-2"
                        onClick={() => {
                          setShowEditModal(true);
                          setSelectedService(service);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                      <img
                        src={deleteSvg}
                        alt="delete"
                        width="22px"
                        onClick={() => {
                          handleDelete(service.id);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
        
      </div>
      
    </div>
    <GeneralModal
        open={showEditModal}
        handleClose={() => setShowEditModal(false)}
      >
        <ServiceForm
          serviceData={selectedService}
          getServices={getServices}
          setServiceData={setSelectedService}
          closeModal={() => setShowEditModal(false)}
        />
      </GeneralModal>
    </>
  );
}

export default ServiceTable;
