import React, { useContext, useEffect, useState } from "react";
import searchbar_icon from "../svg/searchbar_icon.svg";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import toast from "react-hot-toast";
import AuthContext from "../../context/Auth";
import axios from "axios";
import "./loading_anim.css";
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 13,
    maxWidth: 320,
    backgroundColor: "rgba(0, 0, 0, 0.85)",
  },
}));

function MembershipTable({ startDate, endDate }) {
  const [searchbarValue, setSearchbarValue] = useState("");
  const [searchResultList, setSearchResultList] = useState([]);

  const [isFocused, setIsFocused] = useState(false);
  const [searchloadingstate, setSearchloadingstate] = useState(false);
  const { authTokens } = useContext(AuthContext);
  const [membershipData, setMembershipData] = useState([]);
  const [dataresetvisibility, setDataResetVisibility] = useState(false);
  const getSearchResult = (text) => {
    if (text.length === 0) {
      return;
    }
    setIsFocused(true);
    setSearchloadingstate(true);
    axios
      .get(
        `https://trakky.in:8000/salonvendor/customer-membership/?search=${text}`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access_token}`,
          },
        }
      )
      .then((res) => {
        if (res.length !== 0) {
          setSearchResultList(res.data);
          setSearchloadingstate(false);
        } else {
          setSearchloadingstate(false);
          setSearchResultList([]);
        }
      })
      .catch((err) => console.log(err));
  };
  // useEffect(() => {
  //   fetch("https://trakky.in:8000/salonvendor/customer-membership/", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${authTokens?.access_token}`,
  //     },
  //   })
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((data) => {
  //       if (data) {
  //         // setMembershipData(data);
  //       }
  //     });
  // }, [authTokens]);

  // Funtion to get the membership details of the customer
  const getMembershipDetails = async () => {

    let url ;

    if (startDate === null || endDate === null){
     url = `https://trakky.in:8000/salonvendor/customer-membership/`;
    }
    else{
     url = `https://trakky.in:8000/salonvendor/customer-membership/?start_date=${startDate}&end_date=${endDate}`;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.access_token}`,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      setMembershipData(data);
    }
    return response;
  };

    // function to formate date string
    const formatDate = (date) => {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = d.getMonth() + 1;
      const day = d.getDate();
  
      return `${day}-${month}-${year}`;
    };

  useEffect(() => {
    getMembershipDetails();
  }, [startDate, endDate]);

  return (
    <>
      <div className="w-full max-w-[500px] h-[3.75rem] py-3 px-7 relative"  style={{maxWidth:"min(500px , 85%)" , paddingRight:"30px"}}> 
        <input
          type="text"
          id="searchbar_membership"
          placeholder="name • phone number • membership code"
          className=" shadow-lg px-4 pr-9 w-[100%] bg-white rounded-lg h-[100%] "
          value={searchbarValue}
          onChange={(e) => {
            setSearchbarValue(e.target.value);
            getSearchResult(e.target.value);
          }}
          onBlur={() => {
            setIsFocused(false);
            setSearchbarValue("");
          }}
        />
        <img
          className="absolute top-1/2 right-[25px] py-2 px-3 -translate-y-2/4 "
          src={searchbar_icon}
          alt=" f"
          srcSet=""
        />
        {isFocused && (
          <div className="bg-white  z-40 overflow-scroll max-h-[9rem] drop-shadow-lg mt-1 rounded-2xl">
            {searchloadingstate ? (
              <div className="flex items-center justify-center  h-[3rem]">
                <div class="loader"></div>
              </div>
            ) : searchResultList.length !== 0 ? (
              searchResultList?.map((item, index) => {
                return (
                  <div
                    className="flex flex-col py-2 px-5 border-b-2 cursor-pointer border-gray-300 hover:bg-[#402DB0] hover:text-white transition-all duration-300  w-full"
                    key={index}
                    onMouseDownCapture={() => {
                      setSearchbarValue(``);
                      setIsFocused(false);
                      setMembershipData([item]);
                      setDataResetVisibility(true);
                    }}
                  >
                    <p className="text-sm ">{`${item?.customer_name} • ${item?.customer_phone} • ${item?.membership_code} `}</p>
                  </div>
                );
              })
            ) : (
              <div className="flex items-center justify-center h-[3rem]">
                <p className="font-bold ">No results found</p>
              </div>
            )}
          </div>
        )}
        {dataresetvisibility && (
          <button
            className="bg-red-500 text-white rounded-md absolute -right-10 top-[25%] drop-shadow-md px-2 py-1"
            onClick={() => {
              setDataResetVisibility(false);

              toast.promise(getMembershipDetails(), {
                loading: "loading membership data...",
                error: "Error occurred while loading data! try again",
                success: "Data fetched successfully",
              });
            }}
          >
            reset
          </button>
        )}
      </div>
      <div className="w-full px-3 py-6 overflow-scroll table-container">
        <table
          className="bg-white rounded-lg min-w-[832px] m-auto text-center"
          rules="all"
        >
          <thead className="font-medium leading-8 border-b-2">
            <tr>
              <th>
                <span className="flex items-center justify-center px-6 my-3 border-e-2">
                  MemberShip Code
                </span>
              </th>
              <th>
                <span className="flex items-center justify-center px-6 my-3 border-e-2">
                  MemberShip Type
                </span>
              </th>
              <th>
                <span className="flex items-center justify-center px-6 my-3 border-e-2">
                  Customer Name
                </span>
              </th>
              <th>
                <span className="flex items-center justify-center px-6 my-3 border-e-2">
                  Mobile Number
                </span>
              </th>
              <th>
                <span className="flex items-center justify-center px-6 my-3 border-e-2">
                  Validity
                </span>
              </th>
              <th>
                <span className="flex items-center justify-center px-6 my-3 border-e-2">
                  Total Services
                </span>
              </th>
              <th>
                <span className="flex items-center justify-center px-6 my-3 border-e-2">
                  Services Allowed
                </span>
              </th>
              <th>
                <span className="flex items-center justify-center px-6 my-3 border-e-2">
                  Services Left To Use
                </span>
              </th>
              <th>
                <span className="flex items-center justify-center px-6 my-3 ">
                  Remarks
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {membershipData?.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="px-2 py-2 border-e-2">
                    <span>{item.membership_code}</span>
                  </td>
                  <td className="py-2 border-e-2">
                    <span>{item.package_name}</span>
                  </td>
                  <td className="px-2 py-2 border-e-2">
                    <span>{item.customer_name}</span>
                  </td>
                  <td className="py-2 border-e-2">
                    <span>{item.customer_phone}</span>
                  </td>
                  <td className="py-2 border-e-2">
                    <span>{formatDate(item.valid_till)}</span>
                  </td>
                  <td className="py-2 border-e-2">
                    <div>{item.total_services}</div>
                    {/* <span>{item.total_services}</span> */}
                  </td>
                  <td className="py-2 border-e-2">
                    <CustomTooltip
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      arrow
                      sx={{
                        fontSize: "2rem",
                      }}
                      title={item?.services?.map((service, index) => {
                        return (
                          <span
                            key={index}
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {service.service_name}
                            <br />
                            {index !== item.services.length - 1 ? (
                              <>&nbsp;</>
                            ) : null}
                          </span>
                        );
                      })}
                    >
                      <div>
                        <span>{item?.services[0]?.service_name}...</span>
                      </div>{" "}
                    </CustomTooltip>
                  </td>
                  <td className="py-2 border-e-2">
                    <span>{item.num_services_allowed}</span>
                  </td>
                  <td className="py-2 border-e-2">
                    <CustomTooltip
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      arrow
                      sx={{
                        fontSize: "2rem",
                      }}
                      title={item.remarks}
                    >
                      <div className="px-3 w-[150px] truncate m-auto">
                        <span>
                        {item.remarks.length > 20
        ? item.remarks.substring(0, 20) + "..."
        : item.remarks}
                        </span>
                      </div>
                    </CustomTooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default MembershipTable;
