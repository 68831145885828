import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../context/Auth";
import { Tooltip } from "@mui/material";

import { useNavigate } from "react-router-dom";

const MasterService = () => {
  const { authTokens, user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [servicePrice, setServicePrice] = useState(null);
  const [serviceDiscount, setServiceDiscount] = useState(null);
  const [serviceData, setServiceData] = useState(null);
  const [categoryData, setCategoryData] = useState(null);
  const [category, setCategory] = useState(null);
  const [description, setDescription] = useState(null);
  const [servicetime, setServiceTime] = useState(null);
  const [servicename, setServiceName] = useState(null);
  const [masterCategory, setMasterCategory] = useState(null);
  const [categoryExists, setCategoryExists] = useState(false);
  const [gender, setGender] = useState("");
  const [categoriesid, setCategoriesid] = useState(null);


  const getData = async () => {

    let url = "https://trakky.in:8000/salons/masterservice/";

    if (gender) {
      url = url + `?gender=${gender}`;
    }

    if (masterCategory ) {
      url = url + `&categories=${masterCategory.id}`;
    }

    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.access_token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setServiceData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCetegoryData = async () => {
    let url = "https://trakky.in:8000/salons/mastercategory/";

    if (gender) {
      url = url + `?gender=${gender}`;
    }

    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.access_token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCategoryData(data);
      });
  };

  const handleServiceChange = (e) => {
    let selectedCat = serviceData.filter(
      (item) => item.id == e.target.value
    )[0];
    setDescription(selectedCat.description);
    setServiceTime(selectedCat.service_time);
    setServiceName(selectedCat.service_name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (parseFloat(servicePrice) <= parseFloat(serviceDiscount)) {
      alert("Discounted price should be less than actual price");
      return;
    } else {
      let data = {
        vendor: user.user_id,
        service_name: servicename,
        price: servicePrice,
        discount: serviceDiscount,
        gender: gender,
        categories: categoriesid,
        description: description,
        service_time: servicetime,
       
      };

      await fetch("https://trakky.in:8000/salonvendor/service/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access_token}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          navigate("/salon/servicemanagement/servicetable")

          alert("Service Added Successfully");
        })
        .catch((err) => console.log(err));
    }
  };

  const validateCategory = async (master) => {
    await fetch(
      `https://trakky.in:8000/salonvendor/category/?gender=${gender}&name=${master.name}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access_token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if(data.length === 0){
            alert("Category doesn't exist");
         
            // 
            setCategory(null);
            setCategoryExists(false);
            return false;
        }
        else{
          setCategoriesid(data[0].id)
            setCategoryExists(true);
            return true;
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCategoryChange = (e) => {

    setMasterCategory(
      categoryData.filter((item) => item.id == e.target.value)[0]
    );

    let check = validateCategory(
      categoryData.filter((item) => item.id == e.target.value)[0]
    );

    if (check) {
      setCategory(e.target.value);
      setCategoryExists(true);
    } else {
      setCategory(null);
      setCategoryExists(false);
    }
  };

  useEffect(() => {
    gender && getCetegoryData();
  }, [gender]);

  useEffect(() => {
    categoryData && categoryExists && getData();
  }, [category]);

  return (
    // <div className=" w-[100%] h-[100%] flex justify-center lg:text-xl   bg-[#F0ECFF] items-start px-8 py-4  ">
    //   <form onSubmit={handleSubmit} className=" w-[100%]  md:w-[70%] h-[100%] max-h-[40rem] items-start justify-center md:gap-4 gap-2 flex flex-col ">
    //     <div className="flex flex-col  w-[100%] h-[20%]  md:h-[15%] md:flex-row  md:justify-between  gap-2 justify-center items-center  ">
    //       <div className=" w-[100%]  md:w-[45%] shadow-lg rounded-[.6rem]  h-[50%]  md:h-[65%] bg-white   ">
    //         <select
    //           className="w-[100%] h-[100%]  px-3 rounded-[.6rem] font-medium  text-black"
    //           // value={gender}
    //           onChange={(e) => setGender(e.target.value)}
    //           required
    //         >
    //           <option value="" disabled selected hidden>
    //             Gender
    //           </option>
    //           <option value="male">Male</option>
    //           <option value="female">Female</option>
    //         </select>
    //       </div>
    //       <div className=" w-[100%]  md:w-[45%] shadow-lg rounded-[.6rem]  h-[50%]  md:h-[65%] bg-white   ">
    //         <select
    //           className="w-[100%] h-[100%]  px-3 rounded-[.6rem] font-medium  text-black"
    //           disabled={gender ? false : true}
    //           onChange={handleCategoryChange}
    //           required
    //         >
    //           <option value="" disabled selected hidden >
    //             Category
    //           </option>

    //           {categoryData &&
    //             categoryData.map((item) => {
    //               return <option value={item.id}>{item.name}</option>;
    //             })}
    //         </select>
    //       </div>
    //     </div>

    //     <div className="relative lg:block h-[3.4rem] shadow-lg w-[100%] rounded-[.6rem]  bg-white  ">
    //       <select
    //         className="w-[100%] h-[100%]  px-3 rounded-[.6rem] font-medium   text-black"
    //         disabled={categoryData && categoryExists ? false : true}
    //         onChange={handleServiceChange}
    //         required
    //       >
    //         <option value="" disabled selected hidden>
    //           Service
    //         </option>

    //         {serviceData &&
    //           categoryExists &&
    //           serviceData.map((item) => {
    //             return <option value={item.id}>{item.service_name}</option>;
    //           })}
    //       </select>
    //     </div>

    //     <div className=" w-[100%]  h-[20%] md:h-[15%]  flex  flex-col  md:flex-row justify-center gap-2  md:justify-between items-center  ">
    //       <div className=" w-[100%]  md:w-[45%] shadow-lg rounded-[.3rem]  h-[50%]  md:h-[65%]  bg-white   ">
    //         <input
    //           type="number"
    //           className=" w-[100%] h-[100%]  px-3 rounded-[.6rem] text-black    "
    //           placeholder="Price"
    //           name=""
    //           id=""
    //           required
    //           value={servicePrice === 0 ? null : servicePrice}
    //           onWheel={(e) => e.target.blur()}
    //           onChange={(e) => setServicePrice(e.target.value)}
    //         ></input>
    //       </div>
    //       <div className=" w-[100%]  md:w-[45%]  shadow-lg rounded-[.6rem] h-[50%]  md:h-[65%]  bg-white   ">
    //         <input
    //           type="number"
    //           className=" w-[100%] h-[100%]   px-3 rounded-[.6rem] text-black    "
    //           placeholder="Discounted Price"
    //           name=""
    //           id=""
    //           required
    //           value={serviceDiscount === 0 ? null : serviceDiscount}
    //           onWheel={(e) => e.target.blur()}
    //           onChange={(e) => {
    //             setServiceDiscount(e.target.value);
    //           }}
    //         ></input>
    //       </div>
    //     </div>
    //     <div className="flex w-[100%]  h-[20%] md:h-[10%] justify-center items-center  ">
    //       <button
    //         className="bg-[#04D000] rounded-[.6rem] h-[50%] w-[100%]  md:h-[100%] md:w-[50%] text-white font-[400] lg:text-2xl  "
    //       >
    //         Add Services
    //       </button>
    //     </div>
    //   </form>
    // </div>
    <>
     <form className="ms-register-form mt-0" onSubmit={handleSubmit}>
     <div className="appointment-fillup">
          <div className="left-col1">
          <div className="appoint-input-field">
              <Tooltip title="Gender" placement="top" arrow>
              <select
                required
                onBlur={() => {
                  
                }
                }
                onChange={(e) => {
                  setGender(e.target.value);
                }}
                style={
                  {
                   
                    color: gender === "" ? "#9CA6BE" : "black",
                  }
                }
              >
                <option className="" value="" selected disabled hidden>
                  Select Gender
                </option>
               
                    <option  value={"male"}>
                    Male
                    </option>
                    <option  value={"female"}>
                    Female
                    </option>
               
              </select></Tooltip>
            </div>

          </div>
          <div className="right-col2">
            <div className="appoint-input-field">
              <select
               disabled={gender ? false : true}
                         onChange={handleCategoryChange}
         
   
                required
              >
                  <option value="" disabled selected hidden >
               Category
              </option>
              {categoryData &&
                categoryData.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
               
              </select>
            </div>
        
          </div>
       
        </div>
        <div className="appointment-fillup" >
          
          <div className="appoint-input-field address-field"> 
          <select
            className="w-[100%] h-[100%]  px-3 rounded-[.6rem] font-medium   text-black"
            disabled={categoryData && categoryExists ? false : true}
            onChange={handleServiceChange}
            required
          >
            <option value="" disabled selected hidden>
              Service
            </option>

            {serviceData &&
              categoryExists &&
              serviceData.map((item) => {
                return <option value={item.id}>{item.service_name}</option>;
              })}
          </select>

          </div>
        </div>

        <div className="appointment-fillup" style={{marginTop:"25px"}} >
          <div className="left-col1">
         
            <div className="appoint-input-field">
              <input
                type="number"
                placeholder="Price"
                required
              
               
                value={servicePrice}
                onChange={(e) => setServicePrice(e.target.value)}
              ></input>
            
            </div>
          </div>
          <div className="right-col2">
       
            <div className="appoint-input-field">
              <input
                type="number"
                placeholder="Discounted Price"
             
               
                value={serviceDiscount}
                onChange={(e) => setServiceDiscount(e.target.value)}
              
              ></input>
              {/* {validation.discount && (
                <Tooltip title="Discount should be less than Price" placement="top">
                  <ErrorIcon
                    className="error-icon absolute right-[5px] bottom-[10px] hidden"
                    color="error"
                  />
                </Tooltip>
              )} */}
            </div>
          </div>
       
        </div>


        

     
        <div className="appointment-form-btn">
          <button type="submit">
            {serviceData ? "Update Service" : "Add Service"}
          </button>
        </div>
      </form>
    </>
  );
};

export default MasterService;