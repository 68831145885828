import "./App.css";
import { Routes, Route } from "react-router-dom";

import { Toaster } from "react-hot-toast";
import React, { useContext, useEffect, useState } from "react";
import Login from "./components/Login";
import Header from "./components/Header";
import Dashboard from "./components/Dashboard";
import Sidebar from "./components/Sidebar";
import Appointment from "./components/Appointment";
import DailyStaffmanage from "./components/DailyStaffmanage";
import Servicemanagement from "./components/Servicemanagement";
import AppointmentCard from "./components/appointment/AppointmentCard";
import AppointmentForm from "./components/appointment/AppointmentForm";
import StaffTable from "./components/staffmanagement/StaffTable";
import StaffForm from "./components/staffmanagement/StaffForm";
import ServiceTable from "./components/servicemanagement/ServiceTable";
import ServiceForm from "./components/servicemanagement/ServiceForm";
import Offer from "./components/Offer";
import OfferForm from "./components/offer/OfferForm";
import OfferTable from "./components/offer/OfferTable";
import Membership from "./components/Membership";
import MembershipTable from "./components/membership/MembershipTable";
import MembershipForm from "./components/membership/MembershipForm";
import SidebarContext from "./components/Sidebar_context";
import StandardMembership from "./components/membership/CustomerMembership";
import CustomizedMembership from "./components/membership/MembershipType";
import Setting from "./components/setting/Setting";
import SettingForm from "./components/setting/SettingForm";
import AddCustomer from "./components/client_management/AddCustomer";
import CustomerDetails from "./components/client_management/CustomerDetails";
import Client from "./components/Client";
import StaffRecord from "./components/staffmanagement/StaffRecord";

//Rotes...

import AuthContext from "./context/Auth";
import PrivateRoute from "./context/PrivateRoute";
import SettingDetails from "./components/setting/SettingDetails";
import SalesDetails from "./components/dashboard/SalesDetails";
import DashboardStaffDetails from "./components/dashboard/DashboardStaffDetails";
import DashboardClientDetails from "./components/dashboard/DashboardClientDetails";

function App() {
  const [SidebarStatus, SetSidebar] = React.useState(false);
  const { user, authTokens } = useContext(AuthContext);
  const [vendorLogo, setVendorLogo] = useState(null);
  const [ownerName, setOwnerName] = useState("");
  const [spaName , setSpaName] = useState("")

  const getvendorInfo = () => {
    fetch(`https://trakky.in:8000/spavendor/vendor/`,{
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        data = data[0];
        setOwnerName(data.ownername);
        setVendorLogo(data.logo);
        setSpaName(data.spa_name)
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    authTokens && getvendorInfo();
  }, [authTokens]);

  useEffect(() => {
    document.title = `Trakky POS for spa`;
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        `Grow your business with trakky at lightning speed!`
      );
  });
  return (
    <>
      <Toaster position="bottom-right" />
      {user ? (
        <div id="main_root" className="">
          <div id="main" className="  ">
            <div
              id="sidebar"
              className={`${SidebarStatus ? "sidebar_open" : ""}   sm:w-[30%] `}
            >
              <SidebarContext.Provider value={{ SidebarStatus, SetSidebar }}>
                <Sidebar vendorLogo={vendorLogo} />
              </SidebarContext.Provider>
            </div>
            <div id="body_div" className="grow sm:w-[70%] ">
              <div id="header_div" className="  ">
                <SidebarContext.Provider value={{ SidebarStatus, SetSidebar }}>
                  <Header ownerName={ownerName} spaName={spaName} />
                </SidebarContext.Provider>
              </div>
              <div id="route_body_div" className="  ">
                <Routes>
                  <Route path="/spa/*" element={<Login />} />
                  <Route
                    path="/spa/"
                    element={
                      <PrivateRoute>
                        <Dashboard children={<SalesDetails/>} />
                      </PrivateRoute>
                    }
                  />
                   <Route
                    path="/spa/dashboard/staffdetails"
                    element={
                      <PrivateRoute>
                        <Dashboard children={<DashboardStaffDetails/>} />
                      </PrivateRoute>
                    }
                  />
                   <Route
                    path="/spa/dashboard/clientdetails"
                    element={
                      <PrivateRoute>
                        <Dashboard children={<DashboardClientDetails/>} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/spa/appointment/appointment_card"
                    element={
                      <PrivateRoute>
                        <Appointment children={<AppointmentCard />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/spa/appointment/appointment_form"
                    element={
                      <PrivateRoute>
                        <Appointment children={<AppointmentForm />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/spa/staffmanagement/stafftable"
                    element={
                      <PrivateRoute>
                        <DailyStaffmanage children={<StaffTable />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/spa/staffmanagement/staffform"
                    element={
                      <PrivateRoute>
                        <DailyStaffmanage children={<StaffForm />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/spa/staffmanagement/staffrecord"
                    element={
                      <PrivateRoute>
                        <DailyStaffmanage children={<StaffRecord />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/spa/servicemanagement/servicetable"
                    element={
                      <PrivateRoute>
                        <Servicemanagement children={<ServiceTable />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/spa/servicemanagement/serviceform"
                    element={
                      <PrivateRoute>
                        <Servicemanagement children={<ServiceForm />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/spa/clientdetails/addcustomer"
                    element={
                      <PrivateRoute>
                        <Client children={<AddCustomer />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/spa/clientdetails/customerdetails"
                    element={
                      <PrivateRoute>
                        <Client children={<CustomerDetails />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/spa/offermanagement/offertable"
                    element={
                      <PrivateRoute>
                        <Offer children={<OfferTable />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/spa/offermanagement/offerform"
                    element={
                      <PrivateRoute>
                        <Offer children={<OfferForm />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/spa/membership/membershiptable"
                    element={
                      <PrivateRoute>
                        <Membership children={<MembershipTable />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/spa/setting/general"
                    element={
                      <PrivateRoute>
                        <Setting children={<SettingForm />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/spa/setting/details"
                    element={
                      <PrivateRoute>
                        <Setting children={<SettingDetails />} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/spa/membership/customermembership"
                    element={
                      <PrivateRoute>
                        <div className="flex flex-col w-[100%] h-[100%] overflow-scroll items-center  box-border ">
                          <Membership
                            children={
                              <div className=" w-[100%]  py-4 md:py-1  flex flex-wrap px-7 gap-6 items-center   ">
                                <MembershipForm />
                                <StandardMembership />
                              </div>
                            }
                          />
                        </div>
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/spa/membership/membershiptype"
                    element={
                      <PrivateRoute>
                        <div className="flex flex-col w-[100%] h-[100%] ">
                          <Membership
                            children={
                              <div className=" w-[100%]  py-4 md:py-1   flex flex-wrap px-7 gap-6  items-center   ">
                                <MembershipForm />
                                <CustomizedMembership />
                              </div>
                            }
                          />
                        </div>
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Login />
      )}
    </>
  );
}

export default App;
