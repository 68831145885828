import React, { useState, cloneElement } from "react";
import "../input.css";
import { Link, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DateRange from "./dateModal/DateRange";
import GeneralModal from "./generalModal/GeneralModal";

const Dashboard = (props) => {
  const location = useLocation();
  
    //  new date filter
   
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
  
    // Calculate the start date of the current month
    const currentMonthStartDate = new Date(currentYear, currentMonth, 1);
  
    // Calculate the end date of the current month
    const currentMonthEndDate = new Date(currentYear, currentMonth + 1, 0);
  
    //  new date filter
    const [showDateSelectionModal, setShowDateSelectionModal] = useState(false);
    const [dateState, setDateState] = useState([
      {
        startDate: currentMonthStartDate,
        endDate: currentMonthEndDate,
        key: "selection",
      },
    ]);

  return (
    <>
      <div className=" w-[100%] h-[100%] flex flex-col   items-center  ">
        <div className=" w-[100%]  h-[15%] shrink-0  px-[10px]  md:h-[80px] lg:h-[90px]  gap-4 md:gap-0  m-4 md:m-0 mx-5 lg:px-7 flex flex-col md:flex-row  items-center justify-between  ">
          <div className="bg-[#CACFFF]  h-[50%]   flex min-h-[2.3rem] w-max  rounded-[.6rem]">
            <Link to="/spa/" className="text-[#222]">
              <button
                className={`lg:text-xl px-2 h-[100%] font-[400] transition-all duration-500   ${
                  location.pathname === "/spa/"
                    ? " shadow-2xl rounded-[.6rem] bg-white "
                    : ""
                } `}
              >
                Sales Details
              </button>
            </Link>
            <Link to="/spa/dashboard/staffdetails" className="text-[#222]">
              <button
                className={`lg:text-xl px-2 h-[100%] font-[400] transition-all duration-500  ${
                  location.pathname === "/spa/dashboard/staffdetails"
                    ? " shadow-2xl h-[100%] rounded-[.6rem] bg-white "
                    : ""
                } `}
              >
                Staff Details
              </button>
            </Link>
            <Link to="/spa/dashboard/clientdetails" className="text-[#222]">
              <button
                className={`lg:text-xl px-2 h-[100%] font-[400] transition-all duration-500   ${
                  location.pathname === "/spa/dashboard/clientdetails"
                    ? " shadow-2xl rounded-[.6rem] bg-white "
                    : ""
                } `}
              >
                Client Details
              </button>
            </Link>
          </div>
          <div
            className="px-4 min-h-[2rem] lg:h-[50%] relative shadow-xl text-center rounded-lg flex items-center  bg-white"
            onClick={() => {
              setShowDateSelectionModal(true);
            }}
          >
            <input
              type="text"
              value={`${dateState[0].startDate.getDate()}-${
                dateState[0].startDate.getMonth() + 1
              }-${dateState[0].startDate.getFullYear()}`}
              style={{ width: "95px", cursor: "auto" }}
              readOnly
            />
            <span style={{ padding: "0 10px" }}> ~ </span>
            <input
              type="text"
              value={`${dateState[0]?.endDate?.getDate()}-${
                dateState[0]?.endDate?.getMonth() + 1
              }-${dateState[0]?.endDate?.getFullYear()}`}
              style={{ width: "95px", cursor: "auto" }}
              readOnly
            />
          </div>
        </div>

        <div className="flex w-[95%] h-[3%] bg-[#B8B8B8] max-h-[.1rem] shrink-0     "></div>
        {cloneElement(props.children, {
          startDate: `${dateState[0].startDate.getFullYear()}-${
            dateState[0].startDate.getMonth() + 1
          }-${dateState[0].startDate.getDate()}`,
          endDate: `${dateState[0].endDate.getFullYear()}-${
            dateState[0].endDate.getMonth() + 1
          }-${dateState[0].endDate.getDate()}`,
        })}
        <GeneralModal
          open={showDateSelectionModal}
          handleClose={() => setShowDateSelectionModal(false)}
        >
          <DateRange
            dateState={dateState}
            setDateState={setDateState}
            setShowDateSelectionModal={setShowDateSelectionModal}
          />
        </GeneralModal>
      </div>
    </>
  );
};

export default Dashboard;
