import React, { useContext, useEffect, useState } from "react";

import deleteSvg from "../svg/delete.svg";
import edit from "../svg/edit.svg";
import { Switch } from "@mui/material";

import Modal from "../UpdateModal";
import axios from "axios";

import AuthContext from "../../context/Auth";
import OfferForm from "./OfferForm";

import GeneralModal from "../generalModal/GeneralModal";
import toast from "react-hot-toast";

function OfferTable() {
  const { authTokens } = useContext(AuthContext);
  const [offerData, setOfferData] = useState([]);
  const [Data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  // function to formate duration into minutes
  const formatDuration = (duration) => {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return (hours * 60 + minutes + seconds / 60);
  };

  const handleSwitch = (e, id) => {
    fetch(`https://trakky.in:8000/spavendor/offers/${id}/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({
        enabled: e.target.checked,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `https://trakky.in:8000/spavendor/offers/${id}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      toast.promise(offerFetch(), {
        loading: "loading latest offers...",
        success: " Successfully loaded",
        error: "Something went wrong, offer not deleted",
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const offerFetch = async () => {
    const response = axios
      .get(`https://trakky.in:8000/spavendor/offers/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      })
      .then((response) => {
        setOfferData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    return response;
  };
  useEffect(() => {
    offerFetch();
  }, [authTokens]);

  return (
    <div className=" h-[100%] w-[100%] relative  ">
      <div className="table-container overflow-scroll w-full py-6 px-3">
        <table
          className="bg-white rounded-lg min-w-[832px] m-auto text-center"
          rules="all"
        >
          <thead className="font-medium leading-8 border-b-2">
            <tr key="0">
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Offer Name
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Price
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Duration
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Description
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6">
                  Action
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {offerData.map((offer, index) => {
              return (
                <>
                  <tr key={index + 1} >
                    <td className="border-e-2 py-2">
                      <span  >
                        {offer.offername}
                      </span>
                    </td>
                    <td className="border-e-2 py-2">
                      <span  >
                        {offer.price}
                      </span>
                    </td>
                    <td className="border-e-2 py-2">
                      <span  >
                        {formatDuration(offer.duration) + ' min'}
                      </span>
                    </td>
                    <td className="border-e-2 py-2">
                      <span  >
                        {offer.description}
                      </span>
                    </td>
                    <td >
                      <div className="action flex justify-between min-w-max pr-3">
                        <Switch
                          onChange={(e) => {
                            handleSwitch(e, offer.id);
                          }}
                          defaultChecked={offer.enabled}
                        />
                        <img
                          src={edit}
                          alt="edit"
                          width="22px"
                          className="mr-2"
                          onClick={() => {
                            setShowModal(true);
                            setData(offer);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                        <img
                          src={deleteSvg}
                          alt="delete"
                          width="22px"
                          onClick={() => {
                            toast.promise(handleDelete(offer.id), {
                              loading: "Deleting...",
                              success: "Deleted Successfully",
                              error: "Something went wrong, offer not deleted",
                            });
                          }}
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ padding: 0 }}></td>
                  </tr>
                </>
              );
            })}

            {/* <tr  className='border-b-2'>
                        <td><span className='flex justify-center items-center my-3 px-6 border-e-2'>sdhrtjs</span></td>
                        <td><span className='flex justify-center items-center my-3 px-6 border-e-2'>seu5</span></td>
                        <td><span className='flex justify-center items-center my-3 px-6 border-e-2'>sye5uy</span></td>
                        <td><span className='flex justify-center items-center my-3 px-6 border-e-2'>ysew4yaw4</span></td>
                        <td>
                        <div className='action flex justify-between min-w-max pr-3'>
                        <Switch defaultChecked />
                        <img src={edit} alt='edit' width='22px' className='mr-2' />
                        <img src={deleteSvg} alt='delete' width='22px' />   
                    </div>
                        </td>
                    </tr> */}
          </tbody>
        </table>
      </div>
      <GeneralModal open={showModal} handleClose={() => setShowModal(false)}>
        <OfferForm
          offerData={Data}
          setOfferData={setData}
          closeModal={() => setShowModal(false)}
        />
      </GeneralModal>
    </div>
  );
}

export default OfferTable;
