import React, { useEffect, useState , cloneElement } from "react";
import "./css/Appointment.css";
import { Link, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DateRange from "./dateModal/DateRange";
import GeneralModal from "./generalModal/GeneralModal";

const Client = (props) => {
  const location = useLocation();
  
//  new date filter
const [showDateSelectionModal, setShowDateSelectionModal] = useState(false);
const [isDatefileron, setIsDatefileron] = useState(false);
const [dateState, setDateState] = useState([
  {
    startDate: null,
    endDate: null,
    key: "selection",
  },
]);

  return (
    <>
      <div className=" w-[100%]  h-[15%] px-[10px]  md:h-[75px] lg:h-[80px] gap-4 md:gap-0  md:m-0  my-6  lg:px-7 flex flex-col md:flex-row  items-center justify-between   ">
        <div className="bg-[#CACFFF]  h-[50%]   flex min-h-[2.3rem] w-max  rounded-[.6rem]">
          <Link to="/spa/clientdetails/customerdetails" className="text-[#222]">
            <button
              className={`lg:text-xl px-2 h-[100%] font-[400] transition-all duration-500  ${
                location.pathname === "/spa/clientdetails/customerdetails"
                  ? " shadow-2xl h-[100%] rounded-[.6rem] bg-white "
                  : ""
              } `}
            >
              Customers
            </button>
          </Link>
          {/* <Link to="/spa/clientdetails/addcustomer" className="text-[#222]">
            <button
              className={`lg:text-xl px-2 h-[100%] font-[400] transition-all duration-500   ${
                location.pathname === "/spa/clientdetails/addcustomer" ? " shadow-2xl rounded-[.6rem] bg-white " : ""
              } `}
            >
              Add Customer
            </button>
          </Link> */}
        </div>
        <div
          className="px-4 min-h-[2rem] lg:h-[50%] relative shadow-xl text-center rounded-lg flex items-center  bg-white"
          onClick={() => {
            setShowDateSelectionModal(true);
          }}
        >
           {dateState[0].startDate === null ? (
            <input
              type="text"
              value={"Select Date"}
              style={{ width: "100px", cursor: "auto" ,textAlign:"center" }}
              readOnly
            />
          ) : (
            <>
              <input
                type="text"
                value={`${dateState[0].startDate.getDate()}-${
                  dateState[0].startDate.getMonth() + 1
                }-${dateState[0].startDate.getFullYear()}`}
                style={{ width: "95px", cursor: "auto" }}
                readOnly
              />
              <span style={{ padding: "0 10px" }}> ~ </span>
              <input
                type="text"
                value={`${dateState[0]?.endDate?.getDate()}-${
                  dateState[0]?.endDate?.getMonth() + 1
                }-${dateState[0]?.endDate?.getFullYear()}`}
                style={{ width: "95px", cursor: "auto" }}
                readOnly
              />
            </>
          )}
        </div>
      </div>

      <div className="mx-auto w-[95%] h-[3%] bg-[#B8B8B8] max-h-[.1rem]     "></div>

       {dateState[0]?.startDate === null
        ? cloneElement(props.children, {
            startDate: null,
            endDate: null,
          })
        : cloneElement(props.children, {
            startDate: `${dateState[0]?.startDate?.getFullYear()}-${
              dateState[0]?.startDate?.getMonth() + 1
            }-${dateState[0]?.startDate?.getDate()}`,
            endDate: `${dateState[0]?.endDate?.getFullYear()}-${
              dateState[0]?.endDate?.getMonth() + 1
            }-${dateState[0]?.endDate?.getDate()}`,
          })}


      <GeneralModal
        open={showDateSelectionModal}
        handleClose={() => setShowDateSelectionModal(false)}
      >
        <DateRange
          dateState={dateState}
          setDateState={setDateState}
          setShowDateSelectionModal={setShowDateSelectionModal}
          setIsDatefileron={setIsDatefileron}
        />
      </GeneralModal>
      
    </>
  );
};

export default Client;
