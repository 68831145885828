import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../context/Auth";
import "../../input.css";
import { toast } from "react-hot-toast";

const CategoryForm = ({ categoryData, setCategoryData ,setCloseCategoryform }) => {
  const { authTokens, logoutUser, user , vendorData } = useContext(AuthContext);
  const [categoryid, setcategoryid] = useState(categoryData?.name || "");
  const [categorycData, setCategoryCData] = useState(null);

  const [gender, setGender] = useState(categoryData?.gender || null);
  const [priority, setPriority] = useState(categoryData?.priority || null);
  const [vendor, setVendor] = useState(null);
  const [validation, setValidation] = useState({
    gender: false,
    category: false,
  });


  const getCetegoryData = async () => {
    let url = "https://trakky.in:8000/salons/mastercategory/";

    if (gender) {
      url = url + `?gender=${gender}`;
    }

    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.access_token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCategoryCData(data);
      });
  };
  useEffect(() => {
    gender && getCetegoryData();
  }, [gender]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    const hasError = Object.values(validation).some((item) => item === true);
    if (hasError) {
      toast.error("Please fill all the fields correctly");
      return;
    }
    let data = {
      name: categoryid,
      vendor: vendorData?.id,

      gender: gender,
      priority: categorycData.filter((item) => {
        if (item.name == categoryid) {
          return true;
        }
      })[0].priority,
    };

    if (categoryData) {
      try {
        await fetch(
          `https://trakky.in:8000/salonvendor/category/${categoryData.id}/`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + String(authTokens.access_token),
            },
            // body: formData,
            body: JSON.stringify(data),
          }
        ).then((result) => {
          result.json().then((resp) => {
            if (
              resp.detail === "Authentication credentials were not provided."
            ) {
              alert("You're logged out");
              logoutUser();
            } else {
              if (
                resp?.priority?.[0] ===
                "category with this priority already exists." || resp?.non_field_errors[0] === "The fields gender, vendor, priority must make a unique set."
              ) {
                alert(resp.priority || ( resp.non_field_errors + ' / category already exists'));
              } else {
                setCategoryData(resp);
                toast.success("Category updated successfully");
                setCloseCategoryform()
              }
              
            }
          });
        });
      } catch (error) {
        toast.error("Error occured");
      }
    } else {
      try {
        await fetch(`https://trakky.in:8000/salonvendor/category/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(authTokens.access_token),
          },
          // body: formData,
          body: JSON.stringify(data),
        }).then((result) => {
          result.json().then((resp) => {
            if (
              resp.detail === "Authentication credentials were not provided."
            ) {
              alert("You're logged out");
              logoutUser();
            } else {
              if (
                resp?.priority?.[0] ===
                "category with this priority already exists." || resp?.non_field_errors?.[0] === "The fields gender, vendor, priority must make a unique set."
              ) {
                alert(resp?.priority ||  ( resp.non_field_errors + ' / category already exists'));
              } else {
                toast.success("Category added successfully");
              }
            }
          });
        });
      } catch (error) {
        toast.error("Error occured");
      }
    }
  };

  return (
    <>

      <form style={{maxWidth:"700px" , width:"100%" , margin:"auto" , padding:"60px 20px"}} onSubmit={handleSubmit}>
        <div className="appointment-fillup">
          <div className="left-col1">
            <div className="appoint-input-field">
              <select 
                required 
                style={{
                  border : validation.gender ? "1.5px solid red" : ""
                }}
                onBlur={() => {
                  if (gender === null) {
                    setValidation((prev)=>{
                      return {
                        ...prev,
                        gender : true,
                    }
                    })
                  }else{
                    setValidation((prev)=>{
                      return {
                        ...prev,
                        gender : false,
                    }
                    })
                  }
                }}
                onChange={(e) => 
                  {setGender(e.target.value);
                    setcategoryid("")
                  }} 
                value={gender}
              >
                <option value="" selected disabled hidden>
                  Select Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>
          <div className="right-col2">
            <div className="appoint-input-field">
              <select
                className="w-[100%] h-[100%]  px-3 rounded-[.6rem] font-medium  text-gray-400"
                disabled={gender ? false : true}
                onChange={(e) => setcategoryid(e.target.value)}
                required
                value={categoryid}
              >
                <option value="" disabled selected >
                  Category
                </option>
                {categorycData &&
                  categorycData.map((item) => {
                    return <option value={item.name}>{item.name}</option>;
                  })}
              </select>
            </div>
          </div>
        </div>
        <div className="appointment-form-btn">
          <button type="submit">Add Category</button>
        </div>
      </form>
    </>
  );
};

export default CategoryForm;
