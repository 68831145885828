import React, { useEffect, useContext, useState } from "react";
import searchbar_icon from "../svg/searchbar_icon.svg";
import AuthContext from "../../context/Auth";

const CustomerDetails = ({ startDate, endDate , isDatefileron }) => {
  const { authTokens } = useContext(AuthContext);

  const [clientData, setClientData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [searchloadingstate, setSearchloadingstate] = useState(false);
  const [searchResultList, setSearchResultList] = useState([]);
  const [dataresetvisibility, setDataResetVisibility] = useState(false);

  const calculateTimeOut = (timeIn, duration) => {
    const [timeInHours, timeInMinutes, timeInSeconds] = timeIn
      .split(":")
      .map(Number);
    const [durationHours, durationMinutes, durationSeconds] = duration
      .split(":")
      .map(Number);

    const totalSecondsIn =
      timeInHours * 3600 + timeInMinutes * 60 + timeInSeconds;
    const totalSecondsDuration =
      durationHours * 3600 + durationMinutes * 60 + durationSeconds;
    const totalSecondsOut = totalSecondsIn + totalSecondsDuration;

    const calculatedHours = Math.floor(totalSecondsOut / 3600);
    const calculatedMinutes = Math.floor((totalSecondsOut % 3600) / 60);
    const calculatedSeconds = totalSecondsOut % 60;

    return `${calculatedHours.toString().padStart(2, "0")}:${calculatedMinutes
      .toString()
      .padStart(2, "0")}:${calculatedSeconds.toString().padStart(2, "0")}`;
  };

  // function to formate duration into minutes
  const formatDuration = (duration) => {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return hours * 60 + minutes + seconds / 60;
  }

  const getClientData = async () => {

    let url = `https://trakky.in:8000/salonvendor/appointments/`

    if(startDate !== null  && endDate !== null){
      url += `?start_date=${startDate}&end_date=${endDate}`
    }

    await fetch(
      url,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access_token}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        setClientData(data);
      });
  };

  const getClientDataBySearch = async (search) => {
    setIsFocused(true);
    setSearchloadingstate(true);

    await fetch(
      `https://trakky.in:8000/salonvendor/customer-table/?search=${searchTerm}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access_token}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.length !== 0) {
          setSearchResultList(data);
          setSearchloadingstate(false);
        } else {
          setSearchloadingstate(false);
          setSearchResultList([]);
        }
      });
  };

  const handleSearchDisplay = async (item) => {
    let allAppointments = [];

    if (item.hasOwnProperty("appointments")) {
      const appointments = item.appointments;
      allAppointments.push(...appointments);
    }

    setClientData(allAppointments);
  };

  useEffect(() => {
    if (searchTerm === "") {
      getClientData();
    } else {
      const getSearch = setTimeout(() => {
        getClientDataBySearch();
      }, 500);

      return () => clearTimeout(getSearch);
    }
  }, [searchTerm, startDate, endDate , isDatefileron]);


      // function to formate date string
      const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const day = d.getDate();
    
        return `${day}-${month}-${year}`;
      };
  

  return (
    <>
      <div className="w-full h-[3.75rem] py-3 px-7 relative" style={{maxWidth:"min(500px , 85%)" , paddingRight:"30px"}}>
        <input
          type="text"
          placeholder="Search name or number..."
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          onBlur={() => {
            setIsFocused(false);
            // setSearchTerm("");
          }}
          className=" shadow-lg px-4 w-[100%] bg-white rounded-lg h-[100%] "
        />
        <img
          className="absolute top-1/2 right-[25px] py-2 px-3 -translate-y-2/4 "
          src={searchbar_icon}
          alt=" f"
          srcSet=""
        />
        {isFocused && (
          <div className="bg-white  z-40 overflow-scroll max-h-[9rem] drop-shadow-lg mt-1 rounded-2xl">
            {searchloadingstate ? (
              <div className="flex items-center justify-center  h-[3rem]">
                <div class="loader"></div>
              </div>
            ) : searchResultList.length !== 0 ? (
              searchResultList?.map((item, index) => {
                return (
                  <div
                    className="flex flex-col py-2 px-5 border-b-2 cursor-pointer border-gray-300 hover:bg-[#402DB0] hover:text-white transition-all duration-500  w-full"
                    key={index}
                    onMouseDownCapture={async () => {
                      // setSearchTerm(``);
                      setIsFocused(false);
                      await handleSearchDisplay(item);
                      setDataResetVisibility(true);
                    }}
                  >
                    <p className="text-sm ">{`${item?.customer_name} • ${item?.customer_phone} • ${item?.customer_type} `}</p>
                  </div>
                );
              })
            ) : (
              <div className="flex items-center justify-center h-[3rem]">
                <p className="font-bold ">No results found</p>
              </div>
            )}
          </div>
        )}
        {dataresetvisibility && (
          <button
            className="bg-red-500 text-white rounded-md absolute -right-10 top-[25%] drop-shadow-md px-2 py-1"
            onClick={() => {
              setDataResetVisibility(false);
              setSearchTerm("");
            }}
          >
            reset
          </button>
        )}
      </div>
      <div className="table-container overflow-scroll w-full pt-6 px-3">
        <table
          className="bg-white rounded-lg min-w-[832px] m-auto text-center"
          rules="all"
        >
          <thead className="font-medium leading-8 border-b-2">
            <tr>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Number
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Customer Name
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Customer Email
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Gender
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-8 border-e-2">
                  Mobile Number
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  customer Type
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Service
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Duration
                </span>
              </th>
              <th className="min-w-[120px]">
                <span className="flex justify-center items-center my-3 px-6 border-e-2 ">
                 Date
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Mem No
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Chair No
                </span>
              </th>

              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Manager Name
                </span>
              </th>

              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Total Amount
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Paid Amount
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Extended Duration
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Extended Amount
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Payment Mode
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Payment Status
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Time In
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Time Out
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 ">
                  Staff
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {clientData.length !== 0 ? (
              clientData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="border-e-2 py-2 px-2">
                      <span>{item.id}</span>
                    </td>
                    <td className="border-e-2 py-2 px-2">
                      <span>{item.customer_name}</span>
                    </td>
                    <td className="border-e-2 py-2 px-2">
                      <span>{item.customer_email}</span>
                    </td>
                    <td className="border-e-2 py-2 px-2">
                      <span>{item.customer_gender}</span>
                    </td>
                    <td className="border-e-2 py-2">
                      <span>{item.customer_phone}</span>
                    </td>
                    <td className="py-2 border-e-2">
                      <span>{item.customer_type}</span>
                    </td>
                    <td className="border-e-2 py-2">
                      <span>{item.service_offer_name}</span>
                    </td>
                    <td className="border-e-2 py-2">
                      <span>{formatDuration(item.duration)+ " min"}</span>
                    </td>
                    <td className="border-e-2 py-2">
                      <span>{formatDate(item.date)}</span>
                    </td>
                    <td className="py-2 border-e-2">
                      <span>
                        {item?.membership_code ? item.membership_code : "None"}
                      </span>
                    </td>
                    <td className="py-2 border-e-2">
                      <span>{item.chair_name}</span>
                    </td>

                    <td className="py-2 border-e-2">
                      <span>{item.manager_name}</span>
                    </td>
                    <td className="py-2 border-e-2">
                      <span>{item.total_amount}</span>
                    </td>
                    <td className="py-2 border-e-2">
                      <span>{item.amount_paid}</span>
                    </td>
                    <td className="py-2 border-e-2">
                      <span>{item.extended_duration && formatDuration(item.extended_duration) + " min" || "-"}</span>
                    </td>
                    <td className="py-2 border-e-2">
                      <span>{item.extended_amount}</span>
                    </td>
                    <td className="py-2 border-e-2">
                      <span>{item.payment_mode}</span>
                    </td>
                    <td className="py-2 border-e-2">
                      <span>{item.payment_status}</span>
                    </td>
                    <td className="py-2 border-e-2">
                      <span>{item.time_in}</span>
                    </td>
                    <td className="py-2 border-e-2">
                      <span>
                        {calculateTimeOut(item.time_in, item.duration)}
                      </span>
                    </td>
                    <td className="py-2 border-e-2">
                      <span>{item.staff_name}</span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <>
              </>
            )}
          </tbody>
        </table>
       
      </div>
      {
          clientData.length === 0 && (
            <div className="flex items-center justify-center h-[3rem] bg-white py-8 ml-3">
              <p className="font-bold ">No results found</p>
            </div>
          )
        }
    </>
  );
};

export default CustomerDetails;
