import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calenderIcon from "./svg/calendar.svg";

function Servicemanagement() {
  const location = useLocation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [serviceManageForm, setServiceManageForm] = useState(
    location.pathname === "/servicemanagement/serviceform" ? true : false
  );

  return (
    <>
      <div className=" w-[100%]  h-[35px] px-[10px]  md:h-[75px] lg:h-[80px] gap-4 md:gap-0  md:m-0  my-6  lg:px-7 flex flex-col md:flex-row  items-center justify-between   ">
        <div className="bg-[#CACFFF]  h-[50%]   flex min-h-[2.3rem]  w-max  rounded-[.6rem]">
          <Link
            to="/salon/servicemanagement/servicetable"
            className="text-[#000]"
          >
            {" "}
            <button
              className={`lg:text-xl px-2 h-[100%] font-[400] transition-all duration-500  ${
                location.pathname === '/salon/servicemanagement/servicetable'
                  ? " shadow-2xl h-[100%] rounded-[.6rem] bg-white "
                  : ""
              } `}
            >
              Services
            </button>
          </Link>
          <Link
            to="/salon/servicemanagement/serviceform"
            className="text-[#000]"
          >
            <button
              className={`lg:text-xl px-2 h-[100%] font-[400] transition-all duration-500   ${
                location.pathname === '/salon/servicemanagement/serviceform' ? " shadow-2xl rounded-[.6rem] bg-white " : ""
              } `}
            >
              Add Services
            </button>
          </Link>
          <Link
            to="/salon/servicemanagement/addmasterservice"
            className="text-[#000]"
          >
            <button
              className={`lg:text-xl px-2 h-[100%] font-[400] transition-all duration-500   ${
                location.pathname === '/salon/servicemanagement/addmasterservice' ? " shadow-2xl rounded-[.6rem] bg-white " : ""
              } `}
            >
              Add Master Services
            </button>
          </Link>
        </div>
      </div>

      <div className="mx-auto w-[95%] h-[3%] bg-[#B8B8B8] max-h-[.1rem]     "></div>
    </>
  );
}

export default Servicemanagement;
