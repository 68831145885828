import React from "react";
import { Link, useLocation } from "react-router-dom";

const MembershipForm = () => {
  const location = useLocation();
  return (
    <div className=" w-[100%] h-[53px] mt-5    flex justify-start items-center ">
      <div className="bg-[#CACFFF]  h-[100%] min-h-[3rem]  flex  rounded-[.6rem]">
        <Link to="/salon/membership/customermembership" className="text-[#000]">
          <button
            className={`lg:text-xl px-2 h-[100%] font-[400] transition-all duration-500  ${
              location.pathname === "/salon/membership/customermembership"
                ? " shadow-2xl  h-[100%] rounded-[.6rem] bg-white "
                : ""
            } `}
          >
            Customer Membership
          </button>
        </Link>
        <Link to="/salon/membership/membershiptype" className="text-[#000]">
          <button
            className={`lg:text-xl px-2 h-[100%] font-[400] transition-all duration-500   ${
              location.pathname === "/salon/membership/membershiptype" ? " shadow-2xl rounded-[.6rem] bg-white " : ""
            } `}
          >
          Membership Type
          </button>
        </Link>
      </div>
    </div>
  );
};

export default MembershipForm;
