import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/Auth";
import "../css/staffmanagement.css";
import GeneralModal from "../generalModal/GeneralModal";
import StaffRatingModal from "./StaffRatingModal";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import toast from "react-hot-toast";

const DashboardStaffDetails = ({startDate , endDate}) => {
  const { authTokens } = useContext(AuthContext);
const [type,setType]=useState("text")
  const [staffData, setStaffData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);


  const handle_exit_date = async (date,id) => {
    const  response= await fetch(`https://trakky.in:8000/spavendor/staff/${id}/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({
        exit_date: date,
      }),
    });
    const data = await response.json();
    if (response.status === 200) {
    getStaffData();
    }
    return response
  

  }

  // function to get staff data
  const getStaffData = async () => {
    let url; 

    if (startDate === null || endDate === null) {
      url = `https://trakky.in:8000/spavendor/staff-monthly-detail/`;
    } else {
      url = `https://trakky.in:8000/spavendor/staff-monthly-detail/?start_date=${startDate}&end_date=${endDate}`;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    const data = await response.json();
    setStaffData(data);
  };

  // function to formate date string
  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();

    return `${day}-${month}-${year}`;
  };

  // 

  useEffect(() => {
    getStaffData();
  }, [startDate, endDate]);

  return (
    <div className="table-container overflow-scroll  max-h-[500px] w-full py-6 px-3">
      <table className="bg-white rounded-lg min-w-[825px] m-auto " rules="all">

        <thead className="font-medium leading-8 border-b-2 ">
          <tr key={0}>
            <th>
              <span className="flex justify-center items-center my-3 px-6 border-e-2">
                Staff Name
              </span>
            </th>
            <th>
              <span className="flex justify-center items-center my-3 px-6 border-e-2">
                Phone Number
              </span>
            </th>
            <th>
              <span className="flex justify-center items-center my-3 px-6 border-e-2">
                Joining Date
              </span>
            </th>
            <th>
              <span className="flex justify-center items-center my-3 px-6 border-e-2">
                Leaving Date
              </span>
            </th>
            <th>
              <span className="flex justify-center items-center my-3 px-6 border-e-2">
                Attendence
              </span>
            </th>
            <th>
              <span className="flex justify-center items-center my-3 px-6 border-e-2">
                Appointments
              </span>
            </th>
            <th>
              <span className="flex justify-center items-center my-3 px-6 border-e-2">
                Rating
              </span>
            </th>
            <th>
              <span className="flex justify-center items-center my-3  px-6 border-e-2">
                Comission
              </span>
            </th>
            <th>
              <span className="flex justify-center items-center my-3  px-6 border-e-2">
                Amount Paid
              </span>
            </th>
            <th>
              <span className="flex justify-center items-center my-3  px-6 border-e-2">
                Salary
              </span>
            </th>
            <th>
              <span className="flex justify-center items-center my-3  px-6">
                Payment Mode
              </span>
            </th>
            
          </tr>
        </thead>
        <tbody className="  ">
          {staffData?.map((item) => {
            return (
              <>
                <tr key={item.staff.id}>
                  <td className="border-e-2">
                    <span className="flex justify-center items-center p-4">
                      {item.staff.staffname}
                    </span>
                  </td>
                  <td className="border-e-2">
                    <span className="flex justify-center items-center ">
                      {item.staff.ph_number}
                    </span>
                  </td>
                  <td className="border-e-2">
                    <span className="flex justify-center items-center">
                      {formatDate(item.staff.joining_date)}
                    </span>
                  </td>
                  <td className="border-e-2">
                  { item.staff.exit_date?  <span className="flex justify-center items-center">
                      {formatDate(item.staff.exit_date)}
                    </span>
                    :<div className="flex justify-center items-center w-[10rem] relative">
                    <input
                      placeholder="select leaving date"
                      type={type}
                      name="exit_date"
                      className="max-w-[10rem]"
                      onClick={()=>{setType("date")}}
                      onChange={(e) => {toast.promise(handle_exit_date(e.target.value,item.staff.id),{
                        loading: "Saving...",
                        success: "Saved",
                        error: "Error while saving.",
                      })}
                      }
                      onBlur={()=>{setType("text")}}
                      id=""
                    />
                  </div>
                    }
                  </td>
                  <td className="border-e-2">
                    <span className="flex justify-center items-center">
                      {item.attendance_data[0]?.total_attendance || "-"}
                    </span>
                  </td>
                  <td className="border-e-2">
                    <span className="flex justify-center items-center">
                      {item?.attendance_data[0]?.num_services_total || "-"}
                    </span>
                  </td>
                  <td className="border-e-2">
                    <span className="flex justify-center items-center" >
                      {item?.average_rating || "-"}
                      {item?.average_rating !== 0 && <InfoOutlinedIcon sx={{fontSize : '16px'}} className="ml-1 cursor-pointer" onClick={item?.average_rating ? () => {setShowModal(true); setSelectedStaff(item)} : {}} />}
                    </span>
                  </td>
                  <td className="border-e-2">
                    <span className="flex justify-center items-center">
                      {item?.attendance_data[0]?.commission_total || "-"}
                    </span>
                  </td>
                  <td className="border-e-2">
                    <span className="flex justify-center items-center">
                      {item?.attendance_data[0]?.amount_paid_total || "-"}
                    </span>
                  </td>
                  <td className="border-e-2">
                    <span className="flex justify-center items-center">
                      {item.staff.salary}
                    </span>
                  </td>
                  <td className="">
                    <span className="flex justify-center items-center">
                      {item.staff.payment_mode}
                    </span>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>

      <GeneralModal open={showModal} handleClose={() => setShowModal(false)}>
        <StaffRatingModal staff={selectedStaff} />
      </GeneralModal>
    </div>
  );
};

export default DashboardStaffDetails;
