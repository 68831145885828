import React, { useEffect, useContext, useState } from "react";
import deleteSvg from "../svg/delete.svg";
import edit from "../svg/edit.svg";
import { Switch } from "@mui/material";
import AuthContext from "../../context/Auth";
import CategoryForm from "./CategoryForm";
import GeneralModal from "../generalModal/GeneralModal";

const CategoryTable = () => {
  const { authTokens } = useContext(AuthContext);

  const [updateFormOpened, setUpdateFormOpened] = useState(null);

  const [category, setCategory] = useState([]);
  const [Data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetch("https://trakky.in:8000/salonvendor/category/", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authTokens?.access_token}`,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setCategory(data);
        }
      });
  }, [authTokens]);

  const handleDelete = (id) => {
    fetch(`https://trakky.in:8000/salonvendor/category/${id}/`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authTokens?.access_token}`,
      },
    }).then((res) => {
      if (res.status === 204) {
        setCategory(category.filter((category) => category.id !== id));
      }
    });
  };

  return (
    <div className=" w-[100%] h-[100%] relative ">
      <div className="table-container overflow-scroll w-full py-6 px-3">
        <table
          className="bg-white rounded-lg min-w-[832px] m-auto text-center"
          rules="all"
        >
          <thead className="font-medium leading-8 border-b-2">
            <tr>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Priority{" "}
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Category Name
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6 border-e-2">
                  Gender
                </span>
              </th>
              <th>
                <span className="flex justify-center items-center my-3 px-6">
                  Action
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {category.length !== 0 &&
              category.map((cat, index) => (
                <>
                  <tr key={index}>
                    <td className="border-e-2 py-2 px-2">
                      <span>{cat.priority}</span>
                    </td>
                    <td className="border-e-2 py-2">
                      <span>{cat.name}</span>
                    </td>
                    <td className="border-e-2 py-2">
                      <span>{cat.gender}</span>
                    </td>

                    <td className="py-2">
                      {/* <div className='action flex justify-between min-w-max pr-3'> */}
                      <div className="action flex justify-center min-w-max pr-3">
                        <Switch defaultChecked />
                        <img
                          src={edit}
                          alt="edit"
                          width="22px"
                          className="mr-2"
                          onClick={() => {
                            setShowModal(true);
                            setData(cat);
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                        <img
                          src={deleteSvg}
                          alt="delete"
                          width="22px"
                          onClick={() => {
                            handleDelete(cat.id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  {/* {updateFormOpened === index && (
                    <tr>
                      <td style={{ padding: 0 }}>
                        <Modal closeModal={() => setUpdateFormOpened(null)}>
                          <CategoryForm
                            categoryData={cat}
                            setCategoryData={(updatedCategory) => {
                              setCategory(
                                category.map((cat) => {
                                  return cat.id === updatedCategory.id
                                    ? updatedCategory
                                    : cat;
                                })
                              );
                              setUpdateFormOpened(null);
                            }}
                          />
                        </Modal>
                      </td>
                    </tr>
                  )} */}
                </>
              ))}
          </tbody>
        </table>
      </div>
      <GeneralModal open={showModal} handleClose={() => setShowModal(false)}>
        <CategoryForm
          categoryData={Data}
          setCategoryData={setData}
          closeModal={() => setShowModal(false)}
          setCloseCategoryform={() => setShowModal(false)}
        />
      </GeneralModal>
    </div>
  );
};

export default CategoryTable;
