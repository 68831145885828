import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../context/Auth";
import { Grow } from "@mui/material";

const QrCode = () => {
  const { authTokens } = useContext(AuthContext);

  const [qrCode, setQrCode] = useState("");
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const getQrCode = () => {
    setLoading(true);
    fetch(`https://trakky.in:8000/salonvendor/qrcode/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authTokens.access_token}`,
      },
    })
      .then((res) => {
        return res.text();
      })
      .then((data) => {
        setQrCode(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if(qrCode){
        setChecked(true);
    }
    else{
        setQrCode(false);
    }
  } , [qrCode])

  useEffect(() => {
    getQrCode();
  }, []);

  return (
    <div className="qr-container flex justify-center items-center bg-white py-[20px] min-h-[auto] md:min-h-[550px] ">
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <div className="loader"></div>
        </div>
      ) : (
        <Grow
        in={checked} 
        style={{ transformOrigin: '50% 50%' , transitionDelay: '100ms' }}
        {...(checked ? { timeout: 1000 } : {})}>
            <img src={qrCode} alt="qr code" />
        </Grow>
      )}
    </div>
  );
};

export default QrCode;
