import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../context/Auth";
import AppointmentListCard from "./AppointmentListCard";

function AppointmentCard({ startDate, endDate }) {
  const { authTokens } = useContext(AuthContext);

  const [appointments, setAppointments] = useState([]);
  const [isDateInRange, setIsDateInRange] = useState(false);

  // Function to get all Appointments
  const getAppointments = async () => {
    try {
      const response = await fetch(
        `https://trakky.in:8000/spavendor/appointments/?start_date=${startDate}&end_date=${endDate}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access}`,
          },
        }
      );
      if (response.ok) {
        setAppointments([]);

        const data = await response.json();
        setAppointments(data);
      }
  }
  catch (error) {
    console.log(error);
  }
}
  
  useEffect(() => {
    let startdate = new Date(startDate);
    let enddate = new Date(endDate);
    if (
      startdate.toLocaleDateString() <= new Date().toLocaleDateString() &&
      enddate.toLocaleDateString() >= new Date().toLocaleDateString()
    ) {
      setIsDateInRange(true);
    } else {
      setIsDateInRange(false);
    }
    getAppointments();
  } , [startDate , endDate])

  return (
    <div>
      <div className="cards-container relative ">
        {appointments &&
          appointments?.map((ele) => {
            return (
              <>
                <AppointmentListCard
                  appointment={ele}
                  isDateInRange={isDateInRange}
                />
              </>
            );
          })}
      </div>
    </div>
  );
}

export default AppointmentCard;
